import React, { useEffect, useState } from "react";
import classNames from "classnames";
import axios from 'axios';
import SideBar from "../../components/sidebar/SideBar";
import Topbar from "../../components/content/Topbar";
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, Row, Table } from "reactstrap";
import { useMutation, useQuery } from "@apollo/client";
import { ALL_USERS, GET_QUICK_EXERCISE_PK, QUICK_EXERCISE, QUICK_EXERCISE_SEARCH } from "../../GraphQL/queries";
import Loading from '../../components/Loading'
import { ADD_QUICK_EXERCISE, DELETE_QUICK_EXERCISE, UPDATE_QUICK_EXERCISE, INSERT_FILENAME_SETTING } from "../../GraphQL/mutation";
import './Setting.css';
// import '../product/Product.css';
import Spinner from "../../assets/spinner.gif";
import { useAuth0 } from "@auth0/auth0-react";

let Setting = () => {

  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const [toggle, setToggle] = useState(false);
  const [edit, setEdit] = useState(false);
  const [quickExerciseData, setQuickExerciseData] = useState([]);
  const [quickExerciseDataOne, setQuickExerciseDataOne] = useState({});
  const [alert, setAlert] = useState(false);
  const [message, setMessagge] = useState("");
  const [search, setSearch] = useState("");
  const [sortValue, setSortValue] = useState("created_at");
  const [sort, setSort] = useState("created_at");
  const [sortBy, setSortBy] = useState("desc");
  const [imgUrl, setImgUrl] = useState("");
  const [hide, setHide] = useState(false);
  const [allQuickExercise, setAllQuickExercise] = useState([]);

  const [distance, setDistance] = useState();
  const [duration, setDuration] = useState();
  const [exCalories, setExCalories] = useState();
  const [calories, setCalories] = useState();
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [servingQty, setServingQty] = useState();
  const [servingUnit, setServingUnit] = useState();

  const [userInfo, setUserInfo] = useState({});

  // delete model
  // const [editDeleteModal, setEditDeleteModal] = useState(false);
  // const confirmDeleteModal = () => {
  //   setEditDeleteModal(!editDeleteModal);
  // };

  let toggleBtn = () => {
    setToggle(!toggle);
    setSearch("");
  };

  // insert image mutation
  const [insertFile, { error: insertError }] = useMutation(INSERT_FILENAME_SETTING);
  if (insertError) console.error(insertError);


  // find login user
  const { loading: loginUserLoading, error: loginUserError, data: loginUserData } = useQuery(ALL_USERS);
  if (loginUserError) console.error(loginUserError);
  useEffect(() => {
    if (localStorage.getItem('data')) {
      if (loginUserData) {
        const token = JSON.parse(localStorage.getItem('data'));
        const userEmail = token.email
        setUserInfo(loginUserData?.user?.filter(user => user.email === userEmail)[0]);
      }
    }
  }, [loginUserData]);

  useEffect(() => {
    if (quickExerciseDataOne) {
      setDistance(quickExerciseDataOne?.distance);
      setDuration(quickExerciseDataOne?.duration);
      setExCalories(quickExerciseDataOne?.exercise_calories);
      setName(quickExerciseDataOne?.name);
      setType(quickExerciseDataOne?.type);
      setServingQty(quickExerciseDataOne?.serving_quantity);
      setCalories(quickExerciseDataOne?.calories);
      setServingUnit(quickExerciseDataOne?.serving_unit);
    }
  }, [quickExerciseDataOne]);

  // graphql query (get quick exercise)
  const { loading, error, data, refetch } = useQuery(QUICK_EXERCISE, {
    variables: {
      order_by: {
        [sort.toString()]: sortBy
      }
    }
  });
  // graphql query (get quick exercise (search))
  const { loading: searchLoading, error: searchError, data: searchData, refetch: serachRefetch } = useQuery(QUICK_EXERCISE_SEARCH, {
    variables: {
      iregex: search
    }
  });
  // graphql mutation (add quick exercise)
  const [addQuickAdd, { loading: quickExerciseLoading, error: quickExerciseError }] = useMutation(ADD_QUICK_EXERCISE);
  // graphql mutation (update quick add exercise)
  const [editQuickExercise, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_QUICK_EXERCISE);
  // graphql mutation (delete quick exercise)
  // const [deleteQuickExercise, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_QUICK_EXERCISE);

  // graphql query (get quick exercise (search))
  if (searchError) console.error(searchError);

  useEffect(() => {
    if (data) {
      if (search === "") {
        setQuickExerciseData(data.quick_exercise);
        setAllQuickExercise(data.quick_exercise);
      }
    }
    if (searchData) {
      if (search !== "") {
        setQuickExerciseData(searchData.quick_exercise);
      }
    }
  }, [search, searchData, data]);

  // (get quick exercise)
  if (error) console.error(error);

  // (add quick exercise)
  const addQuickExercise = async (e) => {
    e.preventDefault();

    if (!image) {
      setAlert(true);
      setMessagge("Please Select an Image");
      return;
    }

    if (distance) {
      if (Number(distance) < 0.1 || Number(distance) > 200.0) {
        setAlert(true);
        setMessagge("Distance should be 0.1 mi to 200.0 mi");
        return;
      }
    }

    if (duration) {
      if (Number(duration) < 0.1 || Number(duration) > 400.0) {
        setAlert(true);
        setMessagge("Duration should be 0.1 mi to 200.0 mi");
        return;
      }
    }

    if (name) {
      if (allQuickExercise.find(data => data.name === name)) {
        setAlert(true);
        setMessagge("Title Already Exists");
        return;
      }
    }


    const addQuick = await addQuickAdd({
      variables: {
        distance: Number(distance),
        duration: Number(duration),
        exercise_calories: Number(exCalories),
        calories: Number(calories),
        name: name,
        type: type,
        serving_quantity: servingQty,
        serving_unit: servingUnit,
        created_by: userInfo?.id
      }
    });


    if (image?.name) {
      let bodyData = {
        file_name: image.name,
      };

      let response = await axios.post(process.env.REACT_APP_UPLOAD_ASSETS, bodyData);

      const data = new FormData();

      data.append(`Policy`, response?.data?.credentials?.fields?.Policy);
      data.append(
        `X-Amz-Algorithm`,
        response?.data?.credentials?.fields['X-Amz-Algorithm']
      );
      data.append(
        `X-Amz-Credential`,
        response?.data?.credentials?.fields?.['X-Amz-Credential']
      );
      data.append(`X-Amz-Date`, response?.data?.credentials?.fields?.['X-Amz-Date']);
      data.append(
        `X-Amz-Signature`,
        response?.data?.credentials?.fields?.['X-Amz-Signature']
      );
      data.append(`bucket`, response?.data?.credentials?.fields?.bucket);
      data.append(`key`, response?.data?.credentials?.fields?.key);
      data.append('file', image);

      if (image !== null) {
        let responseOne = await axios.post(response?.data?.credentials?.url, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });



        // call mutation for insert filename
        if (responseOne?.status >= 200 || responseOne?.status <= 299) {
          await insertFile({
            variables: {
              url: response?.data?.credentials?.final_url,
              id: addQuick?.data?.insert_quick_exercise_one?.id
            }
          });
        }

      }

    }

    if (quickExerciseError) console.error(quickExerciseError);
    toggleBtn();
    setAlert(true);
    setMessagge("Added Successfully.");
    refetch();
    serachRefetch();
    setImage("")


  };

  // (update quick add exercise)
  const updateQuickExercise = async (e) => {
    e.preventDefault();


    if (distance) {
      if (Number(distance) < 0.1 || Number(distance) > 200.0) {
        setAlert(true);
        setMessagge("Distance should be 0.1 mi to 200.0 mi");
        return;
      }
    }

    if (duration) {
      if (Number(duration) < 0.1 || Number(duration) > 400.0) {
        setAlert(true);
        setMessagge("Duration should be 0.1 min to 200.0 min");
        return;
      }
    }


    await editQuickExercise({
      variables: {
        id: quickExerciseDataOne?.id,
        distance: distance,
        duration: duration,
        exercise_calories: Number(exCalories),
        calories: Number(calories),
        name: name,
        type: type,
        serving_unit: servingUnit,
        serving_quantity: Number(servingQty),
        updated_by: userInfo?.id
      }
    });



    if (image?.name) {
      let bodyData = {
        file_name: image.name,
      };

      let response = await axios.post(process.env.REACT_APP_UPLOAD_ASSETS, bodyData);

      const data = new FormData();

      data.append(`Policy`, response?.data?.credentials?.fields?.Policy);
      data.append(
        `X-Amz-Algorithm`,
        response?.data?.credentials?.fields['X-Amz-Algorithm']
      );
      data.append(
        `X-Amz-Credential`,
        response?.data?.credentials?.fields?.['X-Amz-Credential']
      );
      data.append(`X-Amz-Date`, response?.data?.credentials?.fields?.['X-Amz-Date']);
      data.append(
        `X-Amz-Signature`,
        response?.data?.credentials?.fields?.['X-Amz-Signature']
      );
      data.append(`bucket`, response?.data?.credentials?.fields?.bucket);
      data.append(`key`, response?.data?.credentials?.fields?.key);
      data.append('file', image);

      if (image !== null) {
        let responseOne = await axios.post(response?.data?.credentials?.url, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });



        // call mutation for insert filename
        if (responseOne?.status >= 200 || responseOne?.status <= 299) {
          await insertFile({
            variables: {
              url: response?.data?.credentials?.final_url,
              id: quickExerciseDataOne?.id
            }
          });
        }

      }

    }



    if (updateError) console.error(updateError);
    toggleBtn();
    setAlert(true);
    setMessagge("Update Successfully.");
    setQuickExerciseDataOne({});
    refetch();
    serachRefetch();
  };


  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false)
      }, 3050)
    }
  }, [alert]);

  const [image, setImage] = useState(null)

  const onImageChange = (event) => {
    setImage(event.target.files[0]);
  }


  // sort table
  const tableSortingId = (e) => {
    if (search === "") {
      setSort(sortValue);
      if (sortBy === "asc") {
        setSortBy("desc");
      } else if (sortBy === "desc") {
        setSortBy("asc");
      }
    }
  };

  useEffect(() => {
    if (quickExerciseDataOne?.url) {
      setImage(quickExerciseDataOne.url);
    } else {
      setImage(null);
    }
  }, [quickExerciseDataOne]);


  // const deleteQuickData = async (e) => {
  //   await deleteQuickExercise({
  //     variables: {
  //       id: quickExerciseDataOne?.id
  //     }
  //   });


  //   if (deleteError) console.error(deleteError);
  //   confirmDeleteModal()
  //   toggleBtn();
  //   setAlert(true);
  //   setMessagge("Delete Succesfully");
  //   setQuickExerciseDataOne({});

  //   await refetch();
  //   await serachRefetch();

  // };

  useEffect(() => {
    if (image) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [image]);

  const handleOndragOver = (event) => {
    event.preventDefault();
  };

  const handleOndrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let imageFile = event.dataTransfer.files[0];
    setImage(imageFile);
  };


  return (
    <React.Fragment>
      <div className="alert-list">
        {alert &&
          <Alert color="info">{message}</Alert>
        }
      </div>
      <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
      <Container
        fluid
        style={{ margin: "0", padding: "0" }}
        className={classNames("content", { "is-open": sidebarIsOpen })}
        toggleSidebar={toggleSidebar}
      >
        <Topbar toggleSidebar={toggleSidebar} quickAddBtn={true} />

        {
          loading ?
            <h2 className="text-muted text-center mt-5">Loading.....</h2> :
            <React.Fragment>
              <React.Fragment>
                {
                  !toggle &&
                  <React.Fragment>
                    <Row>
                      <Col md="11">
                        <div className="search__header">
                          <div className="search__header__text">
                            <span>
                              Search
                            </span>
                          </div>
                          <div className="search__header__input">
                            <Form>
                              <Input
                                id="name"
                                name="name"
                                placeholder="Title"
                                type="search"
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </Form>
                          </div>
                        </div>
                      </Col>
                      <Col md="1" className="mt-3">

                        <div className="mt-1 plus__buttom">
                          <Input
                            type="checkbox"
                            style={{ display: "none" }}
                            id="setToggleBtn"
                          />
                          {
                            toggle ?
                              <label htmlFor="setToggleBtn" onClick={(e) => {
                                toggleBtn();
                                setQuickExerciseDataOne({});
                                setImage(null);
                              }} className="addProductBtn">
                                <i className="fa fa-minus"></i>
                              </label> :
                              <label htmlFor="setToggleBtn" onClick={(e) => {
                                toggleBtn();
                                setEdit(false);
                                setQuickExerciseDataOne({});
                                setImage(null);
                              }} className="addProductBtn">
                                <i className="fa fa-plus"></i>
                              </label>
                          }
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                }

                {/* toggle button */}
                {
                  toggle &&
                  <React.Fragment>
                    <Container fluid>
                      <Row>
                        <Col>
                          <Card className="top__header add_quick mt-3 mb-3">
                            <CardHeader style={{ background: "#f8f8f8" }} className="d-flex top__header__body justify-content-between align-items-center">
                              <div>
                                <span className="text-muted" style={{ fontWeight: "500", fontSize: "18px" }}>
                                  {edit ? <span className="top__header__text">Edit Quick add</span> : <span>Add Quick Add</span>}
                                </span>
                              </div>
                              <div className="mr-0">
                                <Input
                                  type="checkbox"
                                  style={{ display: "none" }}
                                  id="setToggleBtn"
                                />
                                <label htmlFor="setToggleBtn" onClick={toggleBtn} className="addProductBtn">
                                  <i className="fa fa-minus"></i>
                                </label>
                              </div>
                            </CardHeader>
                            <CardBody className="addProductText" style={{ background: "#f8f8f8", color: "#9597A6" }}>
                              {
                                edit && <h3 className="text-orange mt-3">{quickExerciseDataOne?.id}</h3>
                              }
                              <Form className="mt-3">
                                <Row>
                                  <Col lg="4">
                                    <FormGroup>
                                      <Label htmlFor="image">
                                        <p
                                          style={{
                                            display:
                                              'block',
                                          }}
                                        >
                                          Image
                                        </p>
                                        <span className={`${!hide ? 'upload__image' : 'image_shown'}`} id='image_show' onDragOver={handleOndragOver}
                                          onDrop={handleOndrop}>
                                          <input id="image" type="file" onChange={onImageChange} className="file" />
                                          {
                                            image !== null &&
                                            <React.Fragment>
                                              <img src={image.name ? URL.createObjectURL(image) : image} alt="" className="img-fluid" style={{ position: "absolute", top: "200", bottom: "0", left: "0", right: "0", height: '190px', width: "inherit", zIndex: "1", background: "#fff", padding: "10px" }} />
                                            </React.Fragment>
                                          }
                                        </span>
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <Label>Type</Label>
                                      <Input style={{ color: "#9597A6" }} type="select" className="form-control text-muted" defaultValue={quickExerciseDataOne.type} onChange={(e) => setType(e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="Play">Play</option>
                                        <option value="Walk">Walk</option>
                                        <option value="Run">Run</option>
                                        <option value="Swim">Swim</option>
                                        <option value="Autotrack">Autotrack</option>
                                        <option value="Food">Food</option>
                                        <option value="Treat">Treat</option>
                                        <option value="Medicine">Medicine</option>
                                      </Input>
                                    </FormGroup>
                                    {
                                      type === "Food" || type === "Treat" ?
                                        <React.Fragment>
                                          <FormGroup>
                                            <Label>Serving Quantity</Label>
                                            <Input style={{ color: "#9597A6" }} type="number" defaultValue={quickExerciseDataOne?.serving_quantity} onChange={(e) => setServingQty(e.target.value)} />
                                          </FormGroup>
                                        </React.Fragment> : null
                                    }
                                    {
                                      type === "Play" || type === "Swim" ?
                                        <React.Fragment>
                                          <FormGroup>
                                            <Label>Duration [min]</Label>
                                            <Input style={{ color: "#9597A6" }} type="number" defaultValue={quickExerciseDataOne?.duration} onChange={(e) => setDuration(e.target.value)} />
                                          </FormGroup>

                                        </React.Fragment> : null
                                    }
                                    {
                                      type === "Run" || type === "Walk" ?
                                        <React.Fragment>
                                          <FormGroup>
                                            <Label>Distance [mi]</Label>
                                            <Input style={{ color: "#9597A6" }} type="number" defaultValue={quickExerciseDataOne?.distance} onChange={(e) => setDistance(e.target.value)} />
                                          </FormGroup>
                                        </React.Fragment> : null
                                    }
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <Label>Title</Label>
                                      <Input style={{ color: "#9597A6" }} type="text" defaultValue={quickExerciseDataOne?.name} onChange={(e) => setName(e.target.value)} />
                                    </FormGroup>
                                    {
                                      type === "Food" || type === "Treat" ?
                                        <React.Fragment>
                                          <FormGroup>
                                            <Label>Serving Unit</Label>
                                            <Input style={{ color: "#9597A6" }} type="text" defaultValue={quickExerciseDataOne?.serving_unit} onChange={(e) => setServingUnit(e.target.value)} />
                                          </FormGroup>
                                        </React.Fragment> : null
                                    }

                                    {
                                      type === "Run" || type === "Walk" ?
                                        <React.Fragment>
                                          <FormGroup>
                                            <Label>Duration [min]</Label>
                                            <Input style={{ color: "#9597A6" }} type="number" defaultValue={quickExerciseDataOne?.duration} onChange={(e) => setDuration(e.target.value)} />
                                          </FormGroup>
                                        </React.Fragment> : null
                                    }
                                  </Col>


                                </Row>
                                {
                                  <React.Fragment>
                                    <Row>
                                      <Col lg="4">
                                        {
                                          type === "Run" || type === "Walk" || type === "Play" || type === "Swim" ?
                                            <React.Fragment>
                                              <FormGroup>
                                                <Label>Calories</Label>
                                                <Input style={{ color: "#9597A6" }} type="number" defaultValue={quickExerciseDataOne?.exercise_calories} onChange={(e) => setExCalories(e.target.value)} />
                                              </FormGroup>
                                            </React.Fragment> : null
                                        }
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                }
                                {
                                  type === "Food" || type === "Treat" ?
                                    <React.Fragment>
                                      <React.Fragment>
                                        <Row>
                                          <Col lg="4">
                                            <FormGroup>
                                              <Label>Calories</Label>
                                              <Input style={{ color: "#9597A6" }} type="number" defaultValue={quickExerciseDataOne?.calories} onChange={(e) => setCalories(e.target.value)} />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      </React.Fragment>
                                    </React.Fragment> : null
                                }


                                <div>
                                  {
                                    edit ?
                                      <React.Fragment>
                                        <Row className="mt-5 mb-3">
                                          <Col lg="6" className="">
                                            <div className="add_quick_action">
                                              <Button className="saveProductBtn bg-orange mr-3" onClick={updateQuickExercise}>Update</Button>
                                              {/* <Button className="saveProductBtn bg-orange" onClick={confirmDeleteModal}>Delete</Button> */}
                                            </div>
                                          </Col>
                                          <Col lg="6" className="d-flex justify-content-around">
                                            <div>
                                              <span className="text-orange" style={{ fontSize: "16px" }}>Last update</span>
                                              <h6 className="text-muted mt-2">{new Date(quickExerciseDataOne?.updated_at).toLocaleString()}</h6>
                                            </div>
                                            <div>
                                              <span className="text-orange" style={{ fontSize: "16px" }}>Who</span>
                                              <h6 className="text-muted mt-2">
                                                {
                                                  quickExerciseDataOne?.updated_by ?
                                                    `${quickExerciseDataOne?.updated_by_user ? `${quickExerciseDataOne.updated_by_user.first_name} ${quickExerciseDataOne.updated_by_user.last_name}` : `-`}`
                                                    :
                                                    `${quickExerciseDataOne?.created_by_user ? `${quickExerciseDataOne.created_by_user.first_name} ${quickExerciseDataOne.created_by_user.last_name}` : `-`}`
                                                }
                                              </h6>
                                            </div>
                                          </Col>
                                        </Row>
                                      </React.Fragment> :
                                      <React.Fragment>
                                        <div className="mt-5 mb-3">
                                          <Button className="saveProductBtn bg-orange px-5 py-3" onClick={addQuickExercise}>Add</Button>
                                        </div>
                                      </React.Fragment>
                                  }
                                </div>
                              </Form>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                  </React.Fragment>
                }

                {
                  !toggle &&
                  <React.Fragment>
                    <Container fluid>
                      <Row>
                        <Col>
                          <Table borderless responsive className="mt-3 table-text-left">
                            <thead className="table_head">
                              <tr>
                                <th>Image</th>
                                <th><span className="sort-by" onClick={(e) => {
                                  setSortValue("id");
                                  tableSortingId();
                                }}>Condition ID</span></th>
                                <th><span className="sort-by" onClick={(e) => {
                                  setSortValue("type");
                                  tableSortingId();
                                }}>Type</span></th>
                                <th><span className="sort-by" onClick={(e) => {
                                  setSortValue("name");
                                  tableSortingId();
                                }}>Title</span></th>
                                <th style={{ width: "120px" }}>Action</th>
                              </tr>
                            </thead>
                            <tbody className="bg-white text-left">
                              {
                                quickExerciseData && quickExerciseData.length > 0 &&
                                quickExerciseData.map((data) => {
                                  return (
                                    <tr key={data?.id}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        <img src={data?.url} width='60' height='65' alt="" />
                                      </td>
                                      <td style={{ verticalAlign: "middle" }}>{data?.id}</td>
                                      <td style={{ verticalAlign: "middle" }}>{data?.type}</td>
                                      <td style={{ verticalAlign: "middle" }}>{data?.name}</td>
                                      <td
                                        style={{ verticalAlign: "middle" }}
                                        className="action_icon"
                                      >
                                        <Button
                                          className="action_btn" onClick={(e) => {
                                            toggleBtn();
                                            setEdit(true);
                                            setQuickExerciseDataOne(data);
                                            // quickExerciseDataOne?.type === "EXERCISE" ? setType("EXERCISE") : setType(quickExerciseDataOne?.type);
                                          }}
                                        >
                                          Full Details
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <React.Fragment>
                                {
                                  !loading && !searchLoading && quickExerciseData && quickExerciseData.length < 1 &&
                                  <React.Fragment>
                                    <tr>
                                      <td colSpan="5" style={{ verticalAlign: "middle" }}>
                                        <h4 className="text-center" style={{ color: "#f5813c" }}>Not Found..</h4>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                }
                              </React.Fragment>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Container>
                  </React.Fragment>
                }

              </React.Fragment>
              {/* <React.Fragment>
                <Modal isOpen={editDeleteModal} toggle={confirmDeleteModal} className="modal-dialog-centered delete_model modal-sm">
                  <ModalBody>
                    <div className="text-center mt-3 text-orange">
                      <h3>Are you sure ?</h3>
                    </div>
                    <div className="pt-3 pl-2 pr-3" style={{ marginTop: "25px", display: "flex", justifyContent: "space-around" }}>
                      <Button className="bg-btn-orange modal_send_invite" style={{ border: "none", outline: "none" }} onClick={() => {
                        deleteQuickData();
                      }}>DELETE</Button>
                      <Button color="secondary" className="modal_cancel" onClick={() => {
                        confirmDeleteModal();
                      }}>CANCEL</Button>
                    </div>
                  </ModalBody>
                </Modal>
              </React.Fragment> */}
            </React.Fragment>
        }

      </Container>
    </React.Fragment>
  )
};

export default Setting;