import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import classNames from "classnames";
import SideBar from "../../components/sidebar/SideBar";
import Topbar from "../../components/content/Topbar";
import axios from "axios";
import { useQuery } from "@apollo/client";
import { GET_USER_DETAILS } from "../../GraphQL/queries";
import Profile from "../../assets/profile.jpg";
import { useParams } from "react-router-dom";
import DEFAULT_USER_IMAGE from '../../assets/Icon_256w_UnSelectedUser_NoImage.png';

let UserProfile = (props) => {
  const { match } = props
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const [user, setUser] = useState({});
  const [role, setRole] = useState("");
  const [filename, setFilename] = useState("");

  const userType = useParams().type;


  // graphql
  let userId = match?.params.id;
  let { loading, error, data } = useQuery(GET_USER_DETAILS, {
    variables: {
      id: userId
    }
  });
  if (error) console.error(error);

  useEffect(async () => {
    if (data) {
      setUser(data.user_by_pk);
      if (data && data?.user_by_pk?.profile_images?.length > 0) {
        const image = data?.user_by_pk?.profile_images[data?.user_by_pk?.profile_images?.length - 1].filename
        setFilename(data?.user_by_pk?.profile_images[data?.user_by_pk?.profile_images?.length - 1].filename)
        const response = await axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: [image] })
        setImageUrl(Object.values(response?.data?.urls)[0]);
      }
      if (userType === "portal") {
        if (data?.user_by_pk?.user_role_associations?.length > 0) {
          if (data?.user_by_pk?.user_role_associations.map(data => data.user_role?.name).includes("SUPER")) {
            setRole("Super");
          } else if (data?.user_by_pk?.user_role_associations.map(data => data?.user_role?.name).includes("ADMIN")) {
            setRole("Admin");
          } else {
            setRole("User");
          }
        }
      };
      if (userType === "jellie") {
        if (data?.user_by_pk?.premium === true) {
          setRole("Premium");
        } else {
          setRole("Basic");
        }
      }
    }
  }, [data, userType]);


  return (
    <React.Fragment>
      <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
      <Container
        fluid
        style={{ margin: "0", padding: "0" }}
        className={classNames("content", { "is-open": sidebarIsOpen })}
        toggleSidebar={toggleSidebar}
      >
        <Topbar toggleSidebar={toggleSidebar} />
        <React.Fragment>
          {
            loading ?
              <h2 className="text-center text-muted mt-5">Loading........</h2> :
              <React.Fragment>
                {
                  user && Object.keys(user).length > 0 ?
                    <React.Fragment>
                      <Container fluid className="mt-3">
                        <Row>
                          <Col>
                            <Card style={{ minHeight: "80vh" }}>
                              <CardBody className="user_profile">
                                <h3 className="text-muted">Details</h3>
                                <Row className="">
                                  <Col lg="3" md="6" sm="12">
                                    <img src={filename ? imageUrl : DEFAULT_USER_IMAGE} className="img-fluid mt-2" alt="" />
                                  </Col>
                                  <Col lg="9" md="6" sm="6">
                                    <Row>
                                      <Col lg="4" className="mb-3">
                                        <Label className="user_detail_title">Name</Label>
                                        <h6>
                                          {
                                            user?.first_name !== null &&
                                            <span>{user.first_name}</span>
                                          }
                                          {" "}
                                          {
                                            user?.last_name !== null &&
                                            <span>{user.last_name}</span>
                                          }
                                          {
                                            user?.first_name === null && user?.last_name === null && `-`
                                          }
                                        </h6>
                                      </Col>
                                      <Col lg="4" className="mb-3">
                                        <Label className="user_detail_title">Email</Label>
                                        <h6>{user.email}</h6>
                                      </Col>
                                      <Col lg="4" className="mb-3">
                                        <Label className="user_detail_title">Phone</Label>
                                        <h6>{user.mobile ? user.mobile : `-`}</h6>
                                      </Col>
                                    </Row>
                                    <Row style={{ marginBottom: "140px" }}>
                                      <Col lg="4" className="mb-3">
                                        <Label className="user_detail_title">Roles</Label>
                                        <h6 className="">
                                          {
                                            role
                                          }
                                        </h6>
                                      </Col>
                                      <Col lg="4" className="mb-3">
                                        <Label className="user_detail_title">Status</Label>
                                        <h6>{user.active === true ? 'Active' : "Inactive"}</h6>
                                      </Col>
                                      <Col lg="4" className="mb-3"><Label className="user_detail_title">Created On</Label>
                                        <h6>{new Date(user?.created_at).toLocaleString()}</h6></Col>
                                    </Row>
                                    {/* <Row>
                                      <Col lg="4" className="mb-3">
                                        <Label className="user_detail_title">Access</Label>
                                        <h6>
                                          {user?.user_role_associations.length < 1 ? `App` : `Admin Panel`}
                                        </h6>
                                      </Col>
                                      <Col lg="4" className="mb-3"></Col>
                                      <Col lg="4" className="mb-3"></Col>
                                    </Row> */}
                                  </Col>
                                  {/* </Col> */}
                                </Row>
                                <Row className="mt-3">
                                  <Col>
                                    <fieldset className="scheduler-border user__detail__address">
                                      <legend className="scheduler-border text-muted user__detail__address__title">Address</legend>
                                      <div className="control-group">
                                        <Row>
                                          <Col lg="3" md="6">
                                            <Label className="address_title">Country</Label>
                                            <h6 className="address_desc">{user?.country ? user.country : `-`}</h6>
                                          </Col>
                                          <Col lg="3" md="6">
                                            <Label className="address_title">State</Label>
                                            <h6 className="address_desc">{user?.state ? user.state : `-`}</h6>
                                          </Col>
                                          <Col lg="3" md="6">
                                            <Label className="address_title">City</Label>
                                            <h6 className="address_desc">{user?.city ? user.city : `-`}</h6>
                                          </Col>
                                          <Col lg="3" md="6">
                                            <Label className="address_title">Zip</Label>
                                            <h6 className="address_desc">{user?.zip ? user.zip : `-`}</h6>
                                          </Col>
                                          <Col lg="3" md="6">
                                            <Label className="address_title">Address Line 1</Label>
                                            <h6 className="address_desc">{user?.address_line_1 ? user.address_line_1 : `-`}</h6>
                                          </Col>
                                          <Col lg="3" md="6">
                                            <Label className="address_title">Address Line 2</Label>
                                            <h6 className="address_desc">{user?.address_line_2 ? user.address_line_2 : `-`}</h6>
                                          </Col>
                                        </Row>
                                      </div>
                                    </fieldset>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    </React.Fragment> :
                    <h4 className="text-center mt-5" style={{ color: "#f5813c" }}>Something went wrong...</h4>
                }
              </React.Fragment>
          }
        </React.Fragment>
      </Container>
    </React.Fragment>
  )
};

export default UserProfile;