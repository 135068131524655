import React, { useEffect, useState } from "react";
import SideBar from "../../components/sidebar/SideBar";
import Topbar from "../../components/content/Topbar";
import { Col, Container, Nav, NavItem, Row, TabContent, TabPane, Card, NavLink, CardBody, Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import classnames from "classnames";
import axios from "axios";
import { useQuery } from "@apollo/client";
import { GET_USER_COMMUNICATION, GET_ALL_USERS, GET_SEARCH_USER_COMMUNICATION, GET_ALL_USERS_FOR_COMMUNICATION_APP_USERS } from "../../GraphQL/queries";
import { useHistory } from "react-router-dom";
import './Communication.css';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { communicatoinEmailTemplateAdmin, communicationEmailTemplateUser } from "./EmailTemplate";

let Communication = () => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [StatTime, setStatTime] = useState("");
  const [EndTime, setEndTime] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [rollId, setRollId] = useState(0);
  const [appUserId, setAppUserId] = useState(0);
  const [premiumUser, setPremiumUser] = useState(false);
  const [activeBtn, setActiveBtn] = useState(false);
  const [deactiveBtn, setDeactiveBtn] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(false);
  const [registered, setRegistered] = useState(false);
  const toggleRegistered = () => {
    setRegistered(!registered);
  };
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  const toggle = tab => {
    setAppUserId(0)
    setRollId(0)
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab)
    };
  }

  // get all users
  const { loading: usersLoading, error: usersError, data: usersData, refetch: usersFetch } = useQuery(GET_ALL_USERS);


  if (usersError) console.error(usersError);

  // graphql query (get communication user)
  const { loading, error, data, refetch } = useQuery(GET_USER_COMMUNICATION, {
    variables: {
      role_id: rollId
    }
  });

  const { loading2, error2, data: searchData, refetch: searchRefetch } = useQuery(GET_SEARCH_USER_COMMUNICATION, {
    variables: {
      role_id: rollId,
      startTime: StatTime,
      endTime: EndTime
    }
  });

  const { loading: appLoading, error: appError, data: appData, refetch: appFetch } = useQuery(GET_ALL_USERS_FOR_COMMUNICATION_APP_USERS, {
    variables: {
      premium: premiumUser
    }
  })
  if (error) console.error(error);
  if (appError) console.error(appError);


  useEffect(() => {
    if (rollId !== 0) {
      if (data && currentActiveTab === '1') {
        // if (activeBtn) {
        //   setAllUsers(data.user.filter((user) => user.active === true));
        // } else if (deactiveBtn) {
        //   setAllUsers(data.user.filter((user) => user.active === false));
        // } else {
        setAllUsers(data.user);
        // }
      } else
        if (searchData && currentActiveTab === '2') {
          if (activeBtn === true) {
            setAllUsers(searchData?.user.filter((user) => user.active === true));
          } else if (deactiveBtn === true) {
            setAllUsers(searchData?.user.filter((user) => user.active === false));
          }
        }
    }
  }, [data, rollId, activeBtn, deactiveBtn, searchData]);



  useEffect(() => {
    if (StatTime && EndTime) {
      searchRefetch()
    }
  }, [StatTime, EndTime]);


  useEffect(() => {
    if (rollId < 1) {
      if (currentActiveTab === '1') {
        if (usersData) {
          if (activeBtn) {
            setAllUsers(usersData.user.filter((user) => user.active === true));
          } else if (deactiveBtn) {
            setAllUsers(usersData.user.filter((user) => user.active === false));
          } else if (Number(appUserId) !== 4 && Number(appUserId) !== 5) {
            setAllUsers(usersData?.user?.filter(user => user.user_role_associations?.length > 0));
          }
        }
      }
      if (currentActiveTab === '2') {
        setAllUsers(usersData?.user);
        if (activeBtn) {
          setAllUsers(usersData?.user.filter((user) => user.active === true));
        } else if (deactiveBtn) {
          setAllUsers(usersData?.user.filter((user) => user.active === false));
        }
      }
    }
    usersFetch();
  }, [usersData, activeBtn, deactiveBtn, rollId, currentActiveTab, appData]);

  const selectValue = async (e) => {
    await setRollId(e.target.value);

    usersFetch();

    // if (currentActiveTab === '1') {
    refetch();
    // } else {
    searchRefetch()
    appFetch();
    // }
  };

  const selectAppUser = async (e) => {
    await setAppUserId(Number(e.target.value));
    if (appUserId === 4 || appUserId === '4') {
      setPremiumUser(true);
      appFetch();
      usersFetch()
    } else if (appUserId === 5 || appUserId === '5') {
      setPremiumUser(false);
      appFetch();
      usersFetch()
    }
  };

  useEffect(() => {
    appFetch()
    if (currentActiveTab === '2') {
      // if (appData) {
      //   if (appUserId === 0 || appUserId === '0') {
      //     setAllUsers(data?.user);
      //   } else {
      //     setAllUsers(appData?.user);
      //   }
      // }
      if (usersData) {
        if (appUserId === '4' || appUserId === 4) {
          setAllUsers(usersData?.user?.filter(data => data.premium === true))
        } else if (appUserId === '5' || appUserId === 5) {
          setAllUsers(usersData?.user?.filter(data => data.premium === false))
        }
      }
    }
  }, [premiumUser, usersData, appData, appUserId, rollId, currentActiveTab, data]);

  const filterUser = allUsers?.filter((data) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email));
  const admin = { email: 'email_log@jelliepets.com' };
  filterUser?.push(admin)

  let email = {
    recipients: filterUser?.length > 0 && filterUser.map((user) => user.email),
    subject: subject,
    body: currentActiveTab === "1" ? communicatoinEmailTemplateAdmin(subject, body) : communicationEmailTemplateUser(subject, body)
  };

  const history = useHistory();

  let sendMail = async (e) => {
    e.preventDefault();
    try {
      let dataUrl = process.env.REACT_APP_EMAIL_BASE_URL;
      let response = await axios.post(dataUrl, email);
      setAlert(true);
      setMessage(response.data.success);
      setSubject("")
      setBody("")
      setStatTime("")
      setEndTime("")
    } catch (error) {
      console.error(error);
    }

  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false)
      }, 3050)
    }
  }, [alert]);


  function uploadAdapter(loader) {
    const reader = new FileReader();
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then(async (file) => {


            if (file.name) {
              let bodyData = {
                file_name: file.name,
              };

              let response = await axios.post(process.env.REACT_APP_UPLOAD_ASSETS, bodyData);

              const data = new FormData();

              data.append(`Policy`, response?.data?.credentials?.fields?.Policy);
              data.append(
                `X-Amz-Algorithm`,
                response?.data?.credentials?.fields['X-Amz-Algorithm']
              );
              data.append(
                `X-Amz-Credential`,
                response?.data?.credentials?.fields?.['X-Amz-Credential']
              );
              data.append(`X-Amz-Date`, response?.data?.credentials?.fields?.['X-Amz-Date']);
              data.append(
                `X-Amz-Signature`,
                response?.data?.credentials?.fields?.['X-Amz-Signature']
              );
              data.append(`bucket`, response?.data?.credentials?.fields?.bucket);
              data.append(`key`, response?.data?.credentials?.fields?.key);
              data.append('file', file);


              let responseOne = await axios.post(response?.data?.credentials?.url, data, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });


              resolve({ default: response?.data?.credentials?.final_url })
            }

          })
        })
      }
    }
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader)
    }
  }



  return (
    <React.Fragment>
      <div className="alert-list">
        {alert &&
          <Alert color="info">{message}</Alert>
        }
      </div>
      <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
      <Container
        fluid
        style={{ margin: "0", padding: "0" }}
        className={classnames("content", { "is-open": sidebarIsOpen })}
        toggleSidebar={toggleSidebar}
      >
        <Topbar toggleSidebar={toggleSidebar} communicationBtn={true} />

        <React.Fragment>
          <Container fluid className="mt-3">
            <div>
              <Nav tabs className="comication_tab_container">
                <NavItem className="tabs">
                  <NavLink
                    className={classnames({
                      active:
                        currentActiveTab === '1'
                    })}
                    onClick={() => { toggle('1'); }}
                  >
                    SEND TO ADMIN PORTAL USER
                  </NavLink>
                </NavItem>
                <NavItem className="tabs">
                  <NavLink
                    className={classnames({
                      active:
                        currentActiveTab === '2'
                    })}
                    onClick={() => { toggle('2') }}
                  >
                    SEND TO JELLIE APP USER
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={currentActiveTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col lg="12">
                      <Card className="mt-4" style={{ boxShadow: "none" }}>
                        <CardBody>
                          <div className="communication_btn mt-3">
                            {/* <Button className="float-left mr-2 active">ALL</Button> */}
                            <select
                              style={{ width: "290px !important" }}
                              className="filter_select form-control"
                              value={rollId}
                              onChange={selectValue}
                            >
                              <option value={0}>All</option>
                              <option value={2}>Admin</option>
                              <option value={3}>Super</option>
                            </select>
                          </div>
                          <Form className="mt-4">
                            <FormGroup>
                              <Label className="text-muted" style={{ fontSize: "18px" }}>Title</Label>
                              <Input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                              <Label className="text-muted" style={{ fontSize: "18px" }}>Mail Content</Label>
                              <CKEditor
                                className="form-control" rows="15" style={{ resize: "none" }}
                                onInit={(editor) => {
                                  editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement())
                                }}
                                editor={ClassicEditor}
                                config={{
                                  extraPlugins: [uploadPlugin],
                                  image: {
                                    resizeUnit: 'px',
                                    resize: true,
                                    toolbar: [
                                      {
                                        name: 'imageStyle:customDropdown',
                                        title: 'Custom drop-down title',
                                        items: ['imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'],
                                        defaultItem: 'imageStyle:alignLeft'
                                      },
                                      'imageTextAlternative'
                                    ]
                                  },
                                  toolbar: {
                                    items: [
                                      'heading',
                                      '|',
                                      'fontSize',
                                      'fontFamily',
                                      '|',
                                      'fontColor',
                                      'fontBackgroundColor',
                                      '|',
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strikethrough',
                                      '|',
                                      'alignment',
                                      '|',
                                      'numberedList',
                                      'bulletedList',
                                      '|',
                                      'outdent',
                                      'indent',
                                      '|',
                                      'todoList',
                                      'imageUpload',
                                      'link',
                                      'blockQuote',
                                      'insertTable',
                                      'mediaEmbed',
                                      '|',
                                      'undo',
                                      'redo',
                                      'imageInsert',
                                      '|'
                                    ]
                                  }
                                }}
                                data={body}
                                onChange={(e, editor) => setBody(editor.getData())}
                              />
                              {/* <textarea className="form-control" rows="10" style={{ resize: "none" }} onChange={(e) => setBody(e.target.value)} /> */}
                            </FormGroup>
                            <div>
                              <Button type="submit" disabled={loading && usersLoading && allUsers?.length > 0 ? true : false} className="saveProductBtn bg-orange" onClick={sendMail}>SEND MAIL</Button>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col lg="12">
                      <Card className="mt-4" style={{ boxShadow: "none" }}>
                        <CardBody>
                          <div className="communication_btn mt-3 d-flex align-items-start">
                            {/* <Button className="mr-2 active">ALL</Button> */}
                            <select
                              style={{ width: "290px !important" }}
                              className="filter_select form-control"
                              onChange={selectAppUser}
                            >
                              <option value={0}>All</option>
                              <option value={5}>Basic</option>
                              <option value={4}>Premium</option>
                            </select>
                            <Button className={`mr-2 text-orange ${activeBtn ? 'active' : ''}`} onClick={(e) => {
                              setActiveBtn(!activeBtn);
                              setDeactiveBtn(false);
                            }}>ACTIVE</Button>
                            <Button className={`mr-2 text-orange ${deactiveBtn ? 'active' : ''}`} onClick={(e) => {
                              setDeactiveBtn(!deactiveBtn);
                              setActiveBtn(false);
                            }}>DEACTIVATED</Button>
                          </div>
                          <Form className="mt-4">
                            <FormGroup className="mt-2 mb-2" check style={{ fontSize: "18px" }}>
                              <Input id="whenregister" type="checkbox" style={{ height: "18px" }} value={registered} onChange={toggleRegistered} />
                              {' '}
                              <Label htmlFor="whenregister" check className="text-muted">
                                When Registered
                              </Label>
                            </FormGroup>
                            {
                              registered &&
                              <>
                                <div className="whenregister">
                                  <Input type="datetime-local" max={new Date().toISOString().slice(0, 16)} placeholder="START DATE" style={{ width: "250px !important" }} value={StatTime} onChange={(e) => setStatTime(e.target.value)} />
                                  <Input type="datetime-local" placeholder="END DATE" max={new Date().toISOString().slice(0, 16)} style={{ width: "250px" }} value={EndTime} onChange={(e) => setEndTime(e.target.value)} />
                                </div>
                              </>
                            }
                            <FormGroup>
                              <Label className="text-muted" style={{ fontSize: "18px" }}>Title</Label>
                              <Input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                              <Label className="text-muted" style={{ fontSize: "18px" }}>Mail Content</Label>
                              <CKEditor
                                className="form-control" rows="10" style={{ resize: "none" }}
                                onInit={(editor) => {
                                  editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement())
                                }}
                                editor={ClassicEditor}
                                config={{
                                  extraPlugins: [uploadPlugin],
                                  image: {
                                    resizeUnit: 'px',
                                    resize: true,
                                    toolbar: [
                                      {
                                        name: 'imageStyle:customDropdown',
                                        title: 'Custom drop-down title',
                                        items: ['imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'],
                                        defaultItem: 'imageStyle:alignLeft'
                                      },
                                      'imageTextAlternative'
                                    ]
                                  },
                                  toolbar: {
                                    items: [
                                      'heading',
                                      '|',
                                      'fontSize',
                                      'fontFamily',
                                      '|',
                                      'fontColor',
                                      'fontBackgroundColor',
                                      '|',
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strikethrough',
                                      '|',
                                      'alignment',
                                      '|',
                                      'numberedList',
                                      'bulletedList',
                                      '|',
                                      'outdent',
                                      'indent',
                                      '|',
                                      'todoList',
                                      'imageUpload',
                                      'link',
                                      'blockQuote',
                                      'insertTable',
                                      'mediaEmbed',
                                      '|',
                                      'undo',
                                      'redo',
                                      'imageInsert',
                                      '|'
                                    ]
                                  }
                                }}
                                data={body}
                                onChange={(e, editor) => setBody(editor.getData())}
                              />
                              {/* <textarea className="form-control" onChange={(e) => setBody(e.target.value)} rows="10" style={{ resize: "none" }} /> */}
                            </FormGroup>
                            <div>
                              <Button type="submit" disabled={loading && usersLoading && allUsers?.length > 0 ? true : false} className="saveProductBtn bg-orange" onClick={sendMail}>SEND MAIL</Button>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </Container>
        </React.Fragment>


      </Container>
    </React.Fragment>
  )
};

export default Communication;