import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from 'history'
import "./App.css";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoutes";

//graphql
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';

// import SideBar from "./components/sidebar/SideBar";
// import Content from "./components/content/Content";
import Login from "./components/login/Login";
import Product from "./view/product/Product";
import ProductCatalogueDetails from "./view/product/ProductCatalogueDetails";
import Dashboard from "./view/dashboard/Dashboard";
import UserProfile from "./view/user/UserProfile";
import User from "./view/user/User";
import Order from "./view/orders/Order";
import OrderDetails from "./view/orders/OrderDetails";
import Communication from "./view/communication/Communication";
import Setting from "./view/setting/Setting";
import UserProductDetails from "./view/product/UserProductDetails";
import Accessories from "./view/accessories/Accessories";
import AccessoriesDetails from "./view/accessories/AccessoriesDetails";
import PetProfile from "./view/user/PetProfile";
import { useAuth0 } from "@auth0/auth0-react";
import Profile from "./view/profile/Profile";


// graphql
const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      alert(`Graphql error ${message}`);
      return message
    });
  }
});

const link = from([
  errorLink,
  new HttpLink({ uri: process.env.REACT_APP_NODE_URL_ONE }),
]);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('Authorization');

  return {
    headers: {
      ...headers,
      Authorization: token ? token : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});

export const history = createBrowserHistory()

let App = () => {
  // const [sidebarIsOpen, setSidebarOpen] = useState(true);
  // const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const { logout } = useAuth0();

  // const token = JSON.parse(localStorage.getItem('@@auth0spajs@@::dzXEgcZyKELadKM0rCjxcdNwmgxMMwsP::default::openid profile email'))

  // useEffect(() => {
  //   if (!token) {
  //     localStorage.removeItem('Authorization');
  //     logout({ returnTo: window.location.origin })
  //     history.push("/login/admin");
  //   }
  // }, []);

  return (
    <React.Fragment>
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <PublicRoute exact path="/login/admin" component={Login} />
            <PublicRoute exact path="/" component={Login} />
            {/* <div className="App wrapper"> */}
            {/* <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} /> */}
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/user" component={User} />
            <PrivateRoute exact path="/product" component={Product} />
            <PrivateRoute exact path="/productCatalogue/details/:id" component={ProductCatalogueDetails} />
            <PrivateRoute exact path="/userProduct/details/:id" component={UserProductDetails} />
            <PrivateRoute exact path="/user/profile/:type/:id" component={UserProfile} />
            <PrivateRoute exact path="/order" component={Order} />
            <PrivateRoute exact path="/communication" component={Communication} />
            <PrivateRoute exact path="/order/details/:id" component={OrderDetails} />
            <PrivateRoute exact path="/setting" component={Setting} />
            <PrivateRoute exact path="/accessories" component={Accessories} />
            {/* <PrivateRoute exact path="/pet-profile" component={PetProfile} /> */}
            <PrivateRoute exact path="/pet/profile/:petId" component={PetProfile} />
            <PrivateRoute exact path="/accessories/details/:id" component={AccessoriesDetails} />
            <PrivateRoute exact path="/profile" component={Profile} />
            {/* <Content
                toggleSidebar={toggleSidebar}
                sidebarIsOpen={sidebarIsOpen}
              /> */}
            <Redirect path="/" to="/login/admin" exact />
            {/* </div> */}
          </Switch>
        </Router>
      </ApolloProvider>
    </React.Fragment>
  );
};

export default App;
