import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Col, Form, Button } from "reactstrap";
import IMG from "../../assets/login-dog.png";
import logo from "../../assets/Image_256w_JELLIE_LargeImage.png";
import axios from "axios"
import Loading from '../Loading'
import { UncontrolledAlert, Input } from 'reactstrap';
import {
  verifyEmail, verifyLength, verifyMobileNumber
} from '../../helper';
import './Login.css';
import auth0 from 'auth0-js';

let Login = (props) => {
  const { history } = props
  const { loginWithRedirect, user } = useAuth0();
  const [loading, setLoading] = useState(false);
  // const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [sendedOtp, setSendedOtp] = useState(false);
  const [alert, setAlert] = useState('');
  const [message, setMessage] = useState(false);

  const token = JSON.parse(localStorage.getItem('@@auth0spajs@@::dzXEgcZyKELadKM0rCjxcdNwmgxMMwsP::default::openid profile email'))
  // const token = JSON.parse(localStorage.getItem('Authorization'))
  // const previousProps = useRef({ token }).current;
  useEffect(() => {
    if (token?.body?.id_token) {
      setLoading(true)
      localStorage.setItem('Authorization', `Bearer ${token?.body?.id_token}`)
      localStorage.setItem('data', JSON.stringify(token?.body?.decodedToken?.user))
      history.push('/dashboard')
    }

    // else {
    //   setTimeout(() => {
    //     if (!user) {
    //       loginWithRedirect()
    //       setLoading(false)
    //     }
    //   }, 1000);
    // }
  }, [user])

  return (
    <Fragment>
      {
        loading ? (
          <Loading />
        )
          : (
            <React.Fragment>
              <div className='alert-list'>
                {alert && (
                  <UncontrolledAlert color='info'>
                    {message}
                  </UncontrolledAlert>
                )}
              </div>
              <div className="login_main">
                <div className="background">
                  <Row>
                    <Col lg="6">
                      <div className="left_side"></div>
                    </Col>
                    <Col lg="6">
                      <div className="right_side"></div>
                    </Col>
                  </Row>
                </div>
                <div className="login_page">
                  <div className="login_image">
                    <img className="login_logo" src={logo} alt="" />
                    <img src={IMG} alt="" />
                  </div>

                  <div className="login_content">

                    <div style={{ width: "50%", height: "inherit", flexDirection: "column" }} className="d-flex justify-content-center align-items-center mr-5">
                      <h2 className="login_title">LOGIN</h2>
                      <Button type="submit" className="login_submit_button login_btn mt-3 ml-2" onClick={e => {
                        setLoading(true)
                        loginWithRedirect()
                      }}>LOGIN</Button>
                    </div>

                  </div>
                </div>
              </div>


              {/* <div className="login_main">
                <div className="login_page">
                  <div className="login_image">
                    <img
                      className="login_logo"
                      src="https://jellie-static-assets.s3.us-east-2.amazonaws.com/image%201.png"
                      alt=""
                    /><img
                      src="https://jellie-static-assets.s3.us-east-2.amazonaws.com/login-dog.png"
                      alt=""
                    />
                  </div>
                  <div className="login_content">
                    <h2 className="login_title">LOGIN</h2>
                    <div id="error-message" className="alert alert-danger"></div>
                    <form>
                      <div className="custom_login_input">
                        <div className="custom_login_username">
                          <svg
                            className="user-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.024"
                            height="20.024"
                            viewBox="0 0 20.024 20.024"
                          >
                            <path
                              id="iconmonstr-user-6"
                              d="M10.012,1.669A4.172,4.172,0,1,1,5.84,5.841a4.177,4.177,0,0,1,4.172-4.173Zm0-1.669a5.841,5.841,0,1,0,5.84,5.841A5.841,5.841,0,0,0,10.012,0Zm5.314,11.141a7.557,7.557,0,0,1-1.383,1.086,10.664,10.664,0,0,1,4.148,6.129H1.916a10.321,10.321,0,0,1,4.149-6.139,7.516,7.516,0,0,1-1.383-1.095A12.271,12.271,0,0,0,0,20.024H20.024A12.535,12.535,0,0,0,15.326,11.141Z"
                              fill="#fff"
                              opacity="0.43"
                            />
                          </svg>

                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter your Email"
                          />
                        </div>
                      </div>
                      <div className="custom_login_input forget_password">
                        <div className="custom_login_password">
                          <svg
                            className="lock-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14.312"
                            height="19.082"
                            viewBox="0 0 14.312 19.082"
                          >
                            <path
                              id="iconmonstr-password-10"
                              d="M8.566,13.517a.8.8,0,1,1-.8-.8A.795.795,0,0,1,8.566,13.517Zm2.385,0a.8.8,0,1,1-.8-.8A.795.795,0,0,1,10.951,13.517Zm2.385,0a.8.8,0,1,1-.8-.8A.795.795,0,0,1,13.336,13.517Zm1.59-5.566V4.771a4.771,4.771,0,1,0-9.541,0v3.18H3V19.082H17.312V7.951ZM6.976,4.771a3.18,3.18,0,1,1,6.361,0v3.18H6.976Zm8.746,12.722H4.59V9.541H15.722Z"
                              transform="translate(-3)"
                              fill="#fff"
                              opacity="0.49"
                            />
                          </svg>

                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Enter your password"
                          />
                        </div>
                      </div>
                      <div className="captcha-container form-group"></div>
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          id="btn-login"
                          className="login_submit_button login_btn btn btn-secondary"
                        >
                          Log In
                        </button>
                        <br />
                      </div>
                      <hr style={{ background: "#fff" }} />
                      <button
                        type="button"
                        id="btn-google"
                        className="btn btn-default google_login btn-block"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          style={{ fill: "#000000" }}
                        >
                          <path
                            d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032 s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2 C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"
                          ></path></svg
                        >Log In with Google
                      </button>
                    </form>
                  </div>
                </div>
              </div> */}


            </React.Fragment>
          )
      }
    </Fragment>
  )
};

export default Login;