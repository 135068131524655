import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faBell } from "@fortawesome/free-solid-svg-icons";
import Profile from "../../assets/untitled folder/e052e700f1d0739cbb9b7c07b9179ef0@2x.png";
import Bell from "../../assets/untitled folder/ic_notifications_24px@2x.png";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Label
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ALL_USERS } from "../../GraphQL/queries";
import axios from "axios";

const Topbar = ({ history, isShowButton, isShowUserButton, activeTab, setActiveTab, isShowAccessoriesButton, dashboardBtn, communicationBtn, ordersBtn, quickAddBtn }) => {

  // let history = useHistory();

  const [userInfo, setUserInfo] = useState({});
  const [userProfileImage, setUserProfileImage] = useState("");
  const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);

  // logout
  const { logout, user } = useAuth0();

  // find login user
  const { loading, error, data } = useQuery(ALL_USERS);
  if (error) console.error(error);
  useEffect(async () => {
    if (localStorage.getItem('data')) {
      if (data) {
        const token = JSON.parse(localStorage.getItem('data'));
        const userEmail = token.email;
        // setUserProfileImage(token.picture);
        setUserInfo(data?.user?.filter(user => user.email === userEmail)[0]);
        if (data?.user?.filter(user => user.email === userEmail)[0].user_role_associations?.length === 0) {
          logoutApp();
        }
      }
    }
  }, [data]);

  useEffect(async () => {
    if (data) {
      if (userInfo?.profile_images?.length > 0) {
        let fileName = userInfo?.profile_images[userInfo?.profile_images?.length - 1].filename;
        let imageResponse = await axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: [fileName] });
        setUserProfileImage(Object.values(imageResponse.data.urls)[0]);
      }
    }
  }, [userInfo]);


  let logoutApp = (e) => {
    localStorage.removeItem('Authorization');
    localStorage.removeItem('data');
    logout({ returnTo: window.location.origin })
    history.push("/login/admin");
  };

  return (
    <Navbar
      light
      className="navbar bg-white rounded"
      expand="xs"
    >
      {/* <Button color="light" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faAlignLeft} />
      </Button> */}
      <NavbarToggler onClick={toggleTopbar} />
      <Collapse isOpen={topbarIsOpen} navbar>
        {/* deshboard */}
        {
          isShowButton && (
            <React.Fragment>
              <Button className='ml-3 topbar_btn topbar__custom__button' style={{ padding: "10px 20px", fontSize: "18px", backgroundColor: `${activeTab === '1' ? '#FEF6F1' : '#fff'}` }} onClick={() => setActiveTab('1')}>
                Product Catalogue
              </Button>
              <Button className='ml-3 topbar_btn topbar__custom__button' style={{ padding: "10px 20px", fontSize: "18px", backgroundColor: `${activeTab === '2' ? '#FEF6F1' : '#fff'}` }} onClick={() => setActiveTab('2')}>
                User Products
              </Button>
            </React.Fragment>
          )
        }
        {
          isShowUserButton && (
            <React.Fragment>
              <Button className='ml-3 topbar_btn topbar__custom__button' style={{ padding: "10px 20px", fontSize: "18px", backgroundColor: `${activeTab === '1' ? '#FEF6F1' : '#fff'}` }} onClick={() => setActiveTab('1')}>
                Portal
              </Button>
              <Button className='ml-3 topbar_btn topbar__custom__button' style={{ padding: "10px 20px", fontSize: "18px", backgroundColor: `${activeTab === '2' ? '#FEF6F1' : '#fff'}` }} onClick={() => setActiveTab('2')}>
                Jellie Users
              </Button>
              <Button className='ml-3 topbar_btn topbar__custom__button' style={{ padding: "10px 20px", fontSize: "18px", backgroundColor: `${activeTab === '3' ? '#FEF6F1' : '#fff'}` }} onClick={() => setActiveTab('3')}>
                Jellie Pets
              </Button>
            </React.Fragment>
          )
        }
        {
          isShowAccessoriesButton && (
            <React.Fragment>
              <Label className="text-orange ml-3 mt-2" style={{ fontSize: "20px", fontWeight: "400" }}>
                Jellie ID Accessories
              </Label>
            </React.Fragment>
          )
        }
        {
          dashboardBtn && (
            <React.Fragment>
              <Label className="text-orange ml-3 mt-2" style={{ fontSize: "20px", fontWeight: "400" }}>
                Dashboard
              </Label>
            </React.Fragment>
          )
        }
        {
          communicationBtn && (
            <React.Fragment>
              <Label className="text-orange ml-3 mt-2" style={{ fontSize: "20px", fontWeight: "400" }}>
                Communication
              </Label>
            </React.Fragment>
          )
        }
        {
          ordersBtn && (
            <React.Fragment>
              <Label className="text-orange ml-3 mt-2" style={{ fontSize: "20px", fontWeight: "400" }}>
                ID Orders
              </Label>
            </React.Fragment>
          )
        }
        {
          quickAddBtn && (
            <React.Fragment>
              <Label className="text-orange ml-3 mt-2" style={{ fontSize: "20px", fontWeight: "400" }}>
                Quick Add
              </Label>
            </React.Fragment>
          )
        }
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink style={{ color: "#f4ab80" }}>
              <img src={Bell} className="mt-2" alt="" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={"/profile"}>
              <img
                src={userProfileImage}
                width="40"
                height="40"
                className="rounded-circle mr-2"
                alt=" "
              />
              {
                userInfo && Object.keys(userInfo).length > 0 &&
                <React.Fragment>
                  <span>{userInfo?.first_name} {userInfo?.last_name}</span>
                </React.Fragment>
              }
            </NavLink>
          </NavItem>
          <div className="dropdown dropleft mt-3">
            <i
              className="fa fa-chevron-down text-orange mx-2"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></i>
            <div
              className="dropdown-menu mt-4"
              aria-labelledby="dropdownMenuButton"
            >
              <div className="dropdown-item text-muted" onClick={logoutApp}>
                <i className="fa fa-sign-out-alt"></i> Logout
              </div>
            </div>
          </div>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Topbar;
