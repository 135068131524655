import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

let PrivateRoute = ({ component: Component, ...rest }) => {

  const isAuthenticated = localStorage.getItem('Authorization')
  const { user } = useAuth0();
  const token = JSON.parse(localStorage.getItem('@@auth0spajs@@::dzXEgcZyKELadKM0rCjxcdNwmgxMMwsP::default::openid profile email'))

  useEffect(() => {
    if (!token) {
      localStorage.removeItem('Authorization')
      localStorage.removeItem('data')
    }
  }, [token])

  return (
    <Route
      {...rest}
      render={props => (isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/login/admin' }} />)}
    />
  );
};
export default PrivateRoute;

