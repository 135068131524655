import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Table, Modal, ModalBody, Form, FormGroup, Label, Input, UncontrolledAlert, Alert } from "reactstrap";
import classNames from "classnames";
import moment from 'moment';
import SideBar from "../../components/sidebar/SideBar";
import Topbar from "../../components/content/Topbar";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ORDERS } from "../../GraphQL/queries";
import { EDIT_SHIPPING_INFO } from "../../GraphQL/mutation";
import './Orders.css'
import Flatpickr from "react-flatpickr";

let Order = () => {

  let [orders, setOrders] = useState([]);
  let [alert, setAlert] = useState(false);
  let [message, setMessage] = useState("");
  let [order, setOrder] = useState({});
  let [company, setcompany] = useState("");
  let [trackingNo, settrackingNo] = useState();
  let [shipDate, setshipDate] = useState("");
  const [sortBy, setSortBy] = useState("desc");
  const [sort, setSort] = useState("created_at");
  const [editModal, setEditModal] = useState(false);
  const editToggle = () => setEditModal(!editModal);

  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const [status, setStatus] = useState({});

  useEffect(() => {
    sort === "order_status" ? setStatus({ [sort.toString()]: { name: sortBy } }) : setStatus({ [sort.toString()]: sortBy })
  }, [sortBy, sort]);

  // graphql query (get orders)
  let { loading, error, data, refetch } = useQuery(GET_ORDERS, {
    variables: {
      order_by: status
    }
  });

  if (error) console.error(error);

  useEffect(() => {
    if (data) {
      setOrders(data.orders);
    }
  }, [data]);

  // graphql mutation (edit shipping info)

  useEffect(() => {
    if (order) {
      setcompany(order.shipping_company);
      setshipDate(order.shipping_date);
      settrackingNo(order.shipping_tracking_no);
    }
  }, [order]);

  let [editShippingInfo, { loading: editting, error: shippingError }] = useMutation(EDIT_SHIPPING_INFO);
  if (shippingError) console.error(shippingError);

  // const [updateStatus, { error: updateStatusError }] = useMutation(UPDATE_ORDER_STATUS);
  // if (updateStatusError) console.error(updateStatusError);

  let editShipping = async (e) => {
    e.preventDefault();
    await editShippingInfo({
      variables: {
        id: order.id,
        shipping_company: company,
        shipping_date: shipDate,
        shipping_tracking_no: trackingNo,
        status_id: 2
      }
    });

    // if (order?.order_status?.id) {
    //   await updateStatus({
    //     variables: {
    //       id: order?.order_status?.id,
    //       name: "Shipped"
    //     }
    //   });
    // }

    await editToggle();

    setAlert(true);
    setMessage("Edited Shipping Info");

    refetch();

  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false)
      }, 3050)
    }
  }, [alert])

  let options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' };


  // sort table
  const tableSortingId = (e) => {
    if (sortBy === "asc") {
      setSortBy("desc");
    } else if (sortBy === "desc") {
      setSortBy("asc");
    }
  };


  function datePart(date) {
    return moment(date).utc().local().format('M/DD/YYYY HH:mm A');
  }


  return (
    <React.Fragment>
      <div className="alert-list">
        {alert &&
          <Alert color="info">{message}</Alert>
        }
      </div>
      <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
      <Container
        fluid
        style={{ margin: "0", padding: "0" }}
        className={classNames("content", { "is-open": sidebarIsOpen })}
        toggleSidebar={toggleSidebar}
      >
        <Topbar toggleSidebar={toggleSidebar} ordersBtn={true} />
        <React.Fragment>
          {
            loading ?
              <h2 className="text-center text-muted mt-5">Loading.....</h2> :
              <React.Fragment>
                <Container fluid className="mt-3">
                  <Row>
                    <Col>
                      <React.Fragment>
                        <Table borderless responsive className="text-left">
                          <thead className="table_head">
                            <tr>
                              <th><span className="sort-by" onClick={(e) => {
                                setSort("id");
                                tableSortingId();
                              }}>Order ID</span></th>
                              <th><span className="sort-by" onClick={(e) => {
                                setSort("quantity");
                                tableSortingId();
                              }}>Qty</span></th>
                              <th><span className="sort-by" onClick={(e) => {
                                setSort("created_at");
                                tableSortingId();
                              }}>Order Placed</span></th>
                              <th><span className="sort-by" onClick={(e) => {
                                setSort("type");
                                tableSortingId();
                              }}>Type</span></th>
                              <th><span className="sort-by" onClick={(e) => {
                                setSort("charge_price");
                                tableSortingId();
                              }}>Price</span></th>
                              <th><span className="sort-by" onClick={(e) => {
                                setSort("order_status");
                                tableSortingId();
                              }}>Status</span></th>
                              <th style={{ width: "110px" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody className="table__body">
                            {
                              orders && orders.length > 0 &&
                              <React.Fragment>
                                {
                                  orders.map((order) => {
                                    return (
                                      <tr key={order.id}>
                                        <td>{order?.id}</td>
                                        <td>{order?.quantity}</td>
                                        <td>
                                          {new Date(order.created_at).toLocaleString("en-US", options)}
                                        </td>
                                        <td>{order?.type}</td>
                                        <td>${(order?.charge_price + order?.charge_shipping + order?.charge_taxes).toFixed(2)}</td>
                                        <td>
                                          {order?.order_status ? order.order_status.name : `-`}
                                        </td>
                                        <td className="action_icon">
                                          <Link to={`/order/details/${order.id}`}>
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                          <i className="fa fa-pen ml-3" onClick={(e) => {
                                            setOrder(order);
                                            editToggle();
                                          }}></i>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </React.Fragment>
                            }
                            <React.Fragment>
                              {
                                !loading && orders && orders.length < 1 &&
                                <React.Fragment>
                                  <tr>
                                    <td colSpan="7" style={{ verticalAlign: "middle" }}>
                                      <h4 className="text-center" style={{ color: "#f5813c" }}>Orders Not Found..</h4>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              }
                            </React.Fragment>
                          </tbody>
                        </Table>
                      </React.Fragment>
                      {
                        order && Object.keys(order).length > 0 &&
                        <React.Fragment>
                          <Modal isOpen={editModal} toggle={editToggle} className="order__modal modal-dialog-centered">
                            <ModalBody>
                              <h5 className="text-orange mb-4">Shipping Info</h5>
                              <Form onSubmit={editShipping}>
                                <Row>
                                  <Col lg="3" md='3' sm="3">
                                    <Label className="mt-2">Ship Date</Label></Col>
                                  <Col lg="9" md='9' sm="9">
                                    {/* <Input type="datetime-local"
                                      min={new Date().toISOString().slice(0, 16)}
                                      value={shipDate}
                                      onChange={(e) => {
                                        setshipDate(e.target.value);
                                      }} /> */}

                                    <Flatpickr
                                      data-enable-time
                                      options={{
                                        monthSelectorType: "dropdown",
                                        disableMobile: true,
                                        dateFormat: "m/d/Y h:i K",
                                        // minDate: (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear()
                                      }}
                                      value={datePart(shipDate)}
                                      onChange={([date]) => {
                                        const d = new Date(date)
                                        setshipDate(d);
                                      }}
                                      className="text-left flatPicker_size"
                                    />

                                  </Col>
                                </Row>
                                <Row className="mt-4">
                                  <Col lg="3" md='3' sm="3">
                                    <Label className="mt-2">Ship Co.</Label></Col>
                                  <Col lg="9" md='9' sm="9">
                                    <Input type="text" defaultValue={order?.shipping_company} onChange={(e) => {
                                      setcompany(e.target.value)
                                    }} name="ship_to_country" /></Col>
                                </Row>
                                <Row>
                                  <Col lg="3" md='3' sm="3">
                                    <Label className="mt-2">Tracking#</Label></Col>
                                  <Col lg="9" md='9' sm="9">
                                    <Input type="text" defaultValue={order?.shipping_tracking_no} onChange={(e) => {
                                      settrackingNo(e.target.value);
                                    }} /></Col>
                                </Row>
                                <div className="mt-3 modal_action">
                                  <Button className="bg-btn-orange modal_send_invite" style={{ border: "none", outline: "none" }}>SAVE</Button>{' '}
                                  <Button color="secondary" className="modal_cancel" onClick={editToggle}>CANCEL</Button>
                                </div>
                              </Form>
                            </ModalBody>
                          </Modal>
                        </React.Fragment>
                      }
                    </Col>
                  </Row>
                </Container>
              </React.Fragment>
          }
        </React.Fragment>
      </Container>
    </React.Fragment>
  )
};

export default Order;