import { gql } from "@apollo/client";

// insert image in setting
export const INSERT_FILENAME_SETTING = gql`
    mutation (
        $id: Int!
        $url: String!
    ) {
        update_quick_exercise_by_pk (
            pk_columns: {
                id: $id
            },
            _set: {
                url: $url
            }
        ) {
            id
        }
    }
`;

// insert product image
export const INSERT_FILENAME_PRODUCT = gql`
    mutation (
        $filename: String!
        $userId: Int!
        $productId: Int!
        $type: String!
        $src: String!
    ) {
        insert_pet_images_one (
            object: {
                type: $type
                filename: $filename
                created_by: $userId
                product_id: $productId
                src: $src
            }
        ) {
            id
        }
    }
`;

// update product image
export const UPDATE_PRODUCT_IMAGE = gql`
    mutation (
        $id: Int!
        $filename: String!
        $userId: Int!
        $type: String!
        $src: String!
    ) {
        update_pet_images_by_pk (pk_columns: {id: $id}, _set: {filename: $filename, created_by: $userId, type: $type, src: $src}) {
            id
        }
    }
`;


// update profile image
export const UPDATE_LOGIN_USER_PROFILE_IMAGE = gql`
    mutation (
        $src: String!
        $created_by: Int!
        $userId: Int!
        $type: String!
        $filename: String!
    ) {
        insert_pet_images_one (
            object: {
                src: $src,
                created_by: $created_by,
                profile_user_id: $userId,
                type: $type,
                filename: $filename
            }
        ) {
            id
        }
    }
`;


// update admin profile
export const UPDATE_ADMIN_NAME = gql`
    mutation (
        $id: Int!
        $first_name: String!
        $last_name: String!
    ) {
        update_user_by_pk (pk_columns: {id: $id}, _set: {first_name: $first_name, last_name: $last_name}) {
            id
            first_name
            last_name
        }
    }
`;

// create pet
export const CREATE_PET = gql`
    mutation (
        $activated: Boolean!
        $allergies: String!
        $animal_id: ID!
        $autotracking: Boolean!
        $birthdate: String!
        $breed: String!
        $coat_color: String!
        $created_by: Int!
        $deceased: Boolean!
        $eye_color: String!
        $feature_priority: String!
        $gender: String!
        $headline: String!
        $height: String!
        $length: String!
        $lost: Boolean!
        $medical_conditions: String!
        $medications: String!
        $microchipped: Boolean!
        $missing: Boolean!
        $name: String!
    ) {
        insert_pet_one(object: {
            activated: $activated
            allergies: $allergies
            animal_id: $animal_id
            autotracking: $autotracking
            birthdate: $birthdate
            breed: $breed
            coat_color: $coat_color
            created_by: $created_by
            deceased: $deceased
            eye_color: $eye_color
            feature_priority: $feature_priority
            gender: $gender
            headline: $headline
            height: $height
            length: $length
            lost: $lost
            medical_conditions: $medical_conditions
            medications: $medications
            microchipped: $microchipped
            missing: $missing
            name: $name
        }) {
            id
        }
    }
`;


// update pet
export const UPDATE_PET = gql`
    mutation (
        $id: ID!
        $activated: Boolean!
        $allergies: String!
        $animal_id: String!
        $autotracking: Boolean!
        $birthdate: String!
        $breed: String!
        $coat_color: String!
        $deceased: Boolean!
        $deleted: Boolean!
        $eye_color: String!
        $feature_priority: String!
        $gender: String!
        $headline: String!
        $height: String!
        $length: String!
        $lost: Boolean!
        $medical_conditions: String!
        $medications: String!
        $microchipped: Boolean!
        $missing: Boolean!
        $name: String!
        $qr_base64: String!
        $qr_id: String!
        $sex_health: String!
        $specie: String!
        $time_zome: String!
        $weight: String!
    ) {
        update_pet_by_pk(pk_columns: {id: $id}, _set: {
            activated: $activated
            allergies: $allergies
            animal_id: $animal_id
            autotracking: $autotracking
            birthdate: $birthdate
            breed: $breed
            coat_color: $coat_color
            deceased: $deceased
            deleted: $deleted
            eye_color: $eye_color
            feature_priority: $feature_priority
            gender: $gender
            headline: $headline
            height: $height
            length: $length
            lost: $lost
            medical_conditions: $medical_conditions
            medications: $medications
            microchipped: $microchipped
            missing: $missing
            name: $name
            qr_base64: $qr_base64
            qr_id: $qr_id
            sex_health: $sex_health
            specie: $specie
            time_zome: $time_zome
            weight: $weight
        }) {
            id
        }
    }
`;


// add accessory
export const ADD_ACCESSORY = gql`
    mutation (
        $id: uuid
        $type: String!
    ) {
        insert_accessories(objects: {
            id: $id
            type: $type
            status: "Generated"
            })
        {
            returning {
                id
                type
            }
        }
    }
`;


// update Status
// export const UPDATE_STATUS = gql`
//     mutation Update_accessories($where: accessories_bool_exp!, $set: accessories_set_input, $inc: accessories_inc_input) {
//         update_accessories(where: $where, _set: $set, _inc: $inc) {
//             affected_rows
//         }
//     }
// `;

export const UPDATE_STATUS = gql`
    mutation (
        $id: uuid!
        $status: String!
    ) {
    update_accessories_by_pk (pk_columns: {id: $id}, _set: {status: $status}) {
        id
        status
    }
    }
`;

// update accessory with pet
export const SET_ACCESSORY_WITH_PET = gql`
    mutation (
        $id: uuid!
        $pet_id: Int!
        $name: String!
        $status: String!
    ) {
        update_accessories_by_pk(pk_columns: {id: $id}, _set: {pet_id: $pet_id, name: $name, status: $status}) {
            id
        }
    }
`;


// add product
export const ADD_PRODUCT = gql`
    mutation (
        $barcode: String
        $barcode_type: String
        $ingredients: String
        $name: String
        $nutrient_ash: String
        $nutrient_fat: float8
        $nutrient_fiber: float8
        $nutrient_moisture: float8
        $nutrient_protein: float8
        $product_tags: String
        $serving_size: String
        $specie: String
        $serving_calories: float8
        $type: String
        $manufacturer: String
        $serving_unit: String
        $admin_created: Boolean
        $description: String
        $serving_total: float8
        $created_by: Int!
    ) {
        insert_pet_product_one (object: {
            barcode: $barcode
            barcode_type: $barcode_type
            ingredients: $ingredients
            name: $name
            nutrient_ash: $nutrient_ash
            nutrient_fat: $nutrient_fat
            nutrient_fiber: $nutrient_fiber
            nutrient_moisture: $nutrient_moisture
            nutrient_protein: $nutrient_protein
            product_tags: $product_tags
            serving_size: $serving_size
            specie: $specie
            serving_calories: $serving_calories
            type: $type
            manufacturer: $manufacturer
            serving_unit: $serving_unit
            admin_created: $admin_created
            description: $description
            serving_total: $serving_total
            created_by: $created_by
        }) {
            id
        }
    }
`;


// EDIT product
// export const EDIT_PRODUCT = gql`
//     mutation Update_petMutation_root($where: pet_product_bool_exp!, $set: pet_product_set_input
//     # , $inc: pet_product_inc_input
//     ) {
//     update_pet_product(where: $where, _set: $set
//     # , _inc: $inc
//     ) {
//       affected_rows
//     }
//     }
// `;



export const EDIT_PRODUCT = gql`
    mutation (
        $id: Int!
        $barcode: String
        $barcode_type: String
        $ingredients: String
        $name: String
        $nutrient_ash: String
        $nutrient_fat: float8
        $nutrient_fiber: float8
        $nutrient_moisture: float8
        $nutrient_protein: float8
        $product_tags: String
        $serving_size: String
        $specie: String
        $serving_calories: float8
        $type: String!
        $manufacturer: String
        $serving_unit: String
        $description: String
        $serving_total: float8
    ) {
        update_pet_product_by_pk (pk_columns: {id: $id}, _set: {
            barcode: $barcode
            barcode_type: $barcode_type
            ingredients: $ingredients
            name: $name
            nutrient_ash: $nutrient_ash
            nutrient_fat: $nutrient_fat
            nutrient_fiber: $nutrient_fiber
            nutrient_moisture: $nutrient_moisture
            nutrient_protein: $nutrient_protein
            product_tags: $product_tags
            serving_size: $serving_size
            specie: $specie
            serving_calories: $serving_calories
            type: $type
            manufacturer: $manufacturer
            serving_unit: $serving_unit
            description: $description
            serving_total: $serving_total
        }) {
            id
        }
    }
`;




// delete product
// export const DELETE_PRODUCT = gql`
//     mutation ($id: Int!) {
//         delete_pet_product_by_pk(id: $id) {
//             id
//         }
//     }
// `;
export const DELETE_PRODUCT = gql`
    mutation (
        $id: Int!
    ) {
        update_pet_product_by_pk (pk_columns: {id: $id}, _set: {deleted: true}) {
            id
        }
    }
`;


// delete user
// export const DELETE_USER = gql`
//     mutation ($id: Int!) {
//         delete_user_by_pk(id: $id) {
//             id
//         }
//     }
// `;

// delete user
export const DELETE_USER = gql`
    mutation (
        $id: Int!
    ) {
        update_user_by_pk (pk_columns: {id: $id}, _set: {deleted: true}) {
            id
        }
    }
`;

// edit shipping info
export const EDIT_SHIPPING_INFO = gql`
    mutation (
        $id: Int!
        $shipping_date: String
        $shipping_company: String
        $shipping_tracking_no: String
        $status_id: Int!
    ) {
        update_orders_by_pk (pk_columns: {id: $id}, _set: {
            shipping_date: $shipping_date
            shipping_company: $shipping_company
            shipping_tracking_no: $shipping_tracking_no
            status_id: $status_id
        }) {
            ship_to_country
        }
    }
`;

// update order status
// export const UPDATE_ORDER_STATUS = gql`
//     mutation (
//         $id: Int!
//         $name: String!
//     ) {
//         update_order_status_by_pk (pk_columns: {id: $id}, _set: {name: $name}) {
//             id
//         }
//     }
// `;


// add quick exercise
export const ADD_QUICK_EXERCISE = gql`
    mutation (
        $distance: Int!
        $duration: Int!
        $exercise_calories: float8!
        $name: String!
        $type: String!
        $serving_quantity: float8
        $serving_unit: String
        $created_by: Int!
        $calories: float8
    ) {
        insert_quick_exercise_one (object: {
            distance: $distance
            duration: $duration
            exercise_calories: $exercise_calories
            name: $name
            type: $type
            serving_quantity: $serving_quantity
            serving_unit: $serving_unit
            created_by: $created_by
            calories: $calories
        }) {
            id
        }
    }
`;

// update quick exercise
export const UPDATE_QUICK_EXERCISE = gql`
    mutation (
        $id: Int!
        $distance: Int!
        $duration: Int!
        $exercise_calories: float8!
        $name: String!
        $serving_unit: String
        $type: String!
        $serving_quantity: float8
        $updated_by: Int!
        $calories: float8
    ) {
        update_quick_exercise_by_pk(pk_columns: {id: $id}, _set: {
            distance: $distance
            duration: $duration
            exercise_calories: $exercise_calories
            name: $name
            type: $type
            serving_quantity: $serving_quantity
            serving_unit: $serving_unit
            updated_by: $updated_by
            calories: $calories
        }) {
            id
        }
    }
`;

// delete quick exercise by id
export const DELETE_QUICK_EXERCISE = gql`
    mutation ($id: Int!) {
        delete_quick_exercise_by_pk(id: $id) {
            id
        }
    }
`;

// add user product to product catalogue
export const ADD_USERPRODUCT_TO_PRODUCTCATALOGUE = gql`
    mutation (
        $id: Int!
        $admin_created: Boolean!
    ) {
        update_pet_product_by_pk(
            pk_columns: {id: $id},
            _set: {admin_created: $admin_created}
        ) {
            admin_created
        }
    }
`;

// update user by id (for active/deactive)
export const UPDATE_USER_STATUS = gql`
    mutation (
        $id: Int!
        $active: Boolean
    ) {
        update_user_by_pk (pk_columns: {id: $id}, _set: {
            active: $active
        }) {
            active
        }
    }
`;

// update jellie app user
export const UPDATE_USER_BASIC_PREMIUM = gql`
    mutation (
        $id: Int!
        $premium: Boolean!
    ) {
        update_user_by_pk(pk_columns: { id: $id }, _set: { premium: $premium }) {
            id
        }
    }
`;

export const UPDATE_USER = gql`
    mutation ($user_id: Int $role_id: Int) {
      insert_user_role_association_one(object: {user_id: $user_id, role_id: $role_id, created_by: 3}) {
        role_id
        user_id
      }
    }
`;

// delete role association
export const DELETE_USER_ROLE_ACCOCIATION = gql`
    mutation (
        $userId: Int!
        $roleId: Int!
    ) {
        delete_user_role_association_by_pk (role_id: $roleId, user_id: $userId) {
            user {
                user_role_associations {
                    role_id
                }
            }
        }
    }
`;


// update pet status
export const UPDATE_PET_STATUS = gql`
    mutation (
        $id: Int!
        $activated: Boolean!
    ) {
        update_pet_by_pk (pk_columns: {id: $id}, _set: {activated: $activated}) {
            id
         }
    }
`;

// update premium user
export const UPDATE_PET_PREMIUM = gql`
    mutation (
        $id: Int!
        $premium: Boolean!
    ) {
        update_pet_by_pk (pk_columns: {id: $id}, _set: {premium: $premium}) {
            id
        }
    }
`;

// delete pet
export const DELETE_PET = gql`
    mutation (
        $id: Int!
        $deleted: Boolean!
        $activated: Boolean!
    ) {
        update_pet_by_pk (
            pk_columns: {id: $id},
            _set: {deleted: $deleted, activated: $activated}
        ) {
            id
        }
    }
`;

// delete pet owner
export const DELETE_PET_OWNER = gql`
    mutation (
        $userId: Int!
        $petId: Int!
    ) {
        delete_pet_owner_by_pk (owner_user_id: $userId, pet_id: $petId) {
            pet_id
        }
    }
`;

// delete pet co owner
export const DELETE_PET_CO_OWNER = gql`
    mutation (
        $userId: Int!
        $petId: Int!
    ) {
        delete_pet_co_owner_by_pk (co_owner_user_id: $userId, pet_id: $petId) {
            pet_id
        }
    }
`;

// delete pet host
export const DELETE_PET_HOST = gql`
    mutation (
        $userId: Int!
        $petId: Int!
    ) {
        delete_pet_host_by_pk (host_user_id: $userId, pet_id: $petId) {
            pet_id
        }
    }
`;

