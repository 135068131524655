import React, { Fragment, useEffect, useRef, useState } from "react";
import SideBar from "../../components/sidebar/SideBar";
import Topbar from "../../components/content/Topbar";
import classNames from "classnames";
import { Button, Alert, Card, CardBody, Col, Container, FormGroup, Form, Input, Label, Row, Table, Modal, ModalBody } from "reactstrap";
import LOGO from "../../assets/untitled folder/logo.png";
import QRCodeStyling from "qr-code-styling";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_ACCESSORY_BY_ID, GET_ALL_PET, GET_ALL_PET_FOR_ACCESS, GET_SEARCH_ALL_PET } from "../../GraphQL/queries";
import { SET_ACCESSORY_WITH_PET } from "../../GraphQL/mutation";
import Loading from '../../components/Loading';
import ReactPaginate from 'react-paginate';
import axios from 'axios';

let AccessoriesDetails = () => {

  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const [assigned, setAssigned] = useState(true);
  const [search, setSearch] = useState('');
  const [accessory, setAccessory] = useState({});
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [petLength, setPetLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [imageResponse, setImageResponse] = useState({});
  const ref = useRef(null);
  let id = useParams().id;
  console.error(id);
  // assign accessories limitation
  const [alertModel, setAlertModel] = useState(false);
  const confirmAlertModel = () => {
    setAlertModel(!alertModel);
  };

  const [sortValue, setSortValue] = useState("created_at");
  const [sort, setSort] = useState("created_at");
  const [sortBy, setSortBy] = useState("desc");

  // graphql query (get all pet)
  // const { loading: petsLoading, error: petsError, data: petsData } = useQuery(GET_ALL_PET);
  const { loading: petsLoading, error: petsError, data: petsData, refetch: fetchPetData } = useQuery(GET_ALL_PET_FOR_ACCESS, {
    variables: {
      offset: offset,
      order_by: {
        [sort.toString()]: sortBy
      }
    }
  });

  // get all pet length
  const { data: petLengthData } = useQuery(GET_ALL_PET, {
    variables: {
      where: {
        deleted: {
          _eq: false
        }
      }
    }
  });
  useEffect(() => {
    if (petLengthData) {
      setPetLength(petLengthData?.pet?.length);
    }
  }, [petLengthData]);

  // get serach all pets
  const { loading: searchLoading, error: searchError, data: searchData } = useQuery(GET_SEARCH_ALL_PET, {
    variables: {
      name: `%${search}%`,
      id: (Number.isInteger(parseInt(search))) ? search : 0
    }
  });

  if (searchError) console.error(searchError);

  // graphql query (get accessory by id)
  let { loading, error, data, refetch } = useQuery(GET_ACCESSORY_BY_ID, {
    variables: {
      id: id
    }
  });

  if (error) console.error(error);

  // graphql mutation (Assign pet)
  let [assignPet, { loading: assignLoading, error: assignError }] = useMutation(SET_ACCESSORY_WITH_PET);

  // graphql mutation (unAssign pet)
  let [unAssignPet, { loading: unAssignLoading, error: unAssignError }] = useMutation(SET_ACCESSORY_WITH_PET);


  const [pets, setPets] = useState([]);

  useEffect(() => {
    if (petsData) {
      if (search === "") {
        setPets(petsData.pet);
        const imageArray = petsData?.pet?.map(data => {
          if (data?.pet_images?.length > 0) {
            return data?.pet_images[data?.pet_images?.length - 1].filename
          }
        })
        const finalArray = imageArray.filter((data) => data !== undefined)
        axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: finalArray }).then((res) => {
          setImageResponse(res?.data?.urls)
        });
      }
    }

    if (searchData) {
      if (search !== "") {
        setPets(searchData.pet);
        const imageArray = searchData?.pet?.map(data => {
          if (data?.pet_images?.length > 0) {
            return data?.pet_images[data?.pet_images?.length - 1].filename
          }
        })
        const finalArray = imageArray.filter((data) => data !== undefined)
        axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: finalArray }).then((res) => {
          setImageResponse(res?.data?.urls)
        });
      }
    }
  }, [petsData, search, searchData]);


  // useEffect(() => {
  //   Pet();
  // }, []);

  // useEffect(() => {
  //   if (search !== "") {
  //     Pet({
  //       variables: {
  //         where: {
  //           name: {
  //             "_eq": search
  //           }
  //         }
  //       }
  //     });
  //   } else {
  //     Pet()
  //   }
  // }, [search]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false)
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    if (data) {
      setAccessory(data.accessories_by_pk);
      data?.accessories_by_pk?.pet !== null ? setAssigned(true) : setAssigned(false);
    }
  }, [data]);

  let qrData = () => {
    return `https://www.jellieid.com/${id}`
  };

  const qrCode = new QRCodeStyling({
    width: 250,
    height: 250,
    type: "png",
    dotsOptions: {
      color: "#000",
      type: "rounded"
    },
    backgroundOptions: {
      color: "#fff"
    },
    imageOptions: {
      crossOrigin: "anonymous",
    },
    cornersSquareOptions: {
      type: "extra-rounded"
    },
    cornersDotOptions: {
      color: "#f5813c",
      type: "dot"
    }
  });

  useEffect(() => {
    qrCode.append(ref.current);
  }, [accessory, id, qrData]);

  useEffect(() => {
    qrCode.update({
      data: qrData(),
      image: LOGO
    });
  }, [accessory, id, qrData]);


  async function unAssignButton(e) {
    await unAssignPet({
      variables: {
        id,
        pet_id: null,
        name: null,
        status: "Generated"
      }
    });

    if (unAssignError) console.error(unAssignError);

    setAlert(true)
    setMessage('Pet UnAssigned Successfully')
    refetch()
    fetchPetData()
  };

  async function assignPetButton(petId, petName, petTotalAccessory, limitation) {

    if (parseInt(petTotalAccessory) >= parseInt(limitation)) {
      confirmAlertModel();
      return;
    }

    await assignPet({
      variables: {
        id,
        pet_id: petId,
        name: petName,
        status: "Assigned"
      }
    });

    if (assignError) console.error(assignError);

    setAlert(true)
    setMessage('Pet Assigned Successfully');
    refetch();
    fetchPetData();
  };

  // paginations

  // Pet Details Pagination
  const petPageCount = Math.ceil(petLength / perPage);
  const handlePageClickCatalogue = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;

    // setCurrentPage(selectedPage);
    setOffset(offset);
    // onGetProductCategoryList();
  };

  // sort table
  const tableSortingId = (e) => {
    if (search === "") {
      setSort(sortValue);
      if (sortBy === "asc") {
        setSortBy("desc");
      } else if (sortBy === "desc") {
        setSortBy("asc");
      }
    }
  };

  return (
    <React.Fragment>
      <Fragment>
        <Fragment>
          <div className="alert-list">
            {alert &&
              <Alert color="info">
                {message}
              </Alert>
            }
          </div>
        </Fragment>
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Container
          fluid
          style={{ margin: "0", padding: "0" }}
          className={classNames("content", { "is-open": sidebarIsOpen })}
          toggleSidebar={toggleSidebar}
        >
          <Topbar toggleSidebar={toggleSidebar} isShowAccessoriesButton={true} />
          {
            (loading || assignLoading || unAssignLoading)
              ? <h2 className="text-muted text-center mt-5">Loading.....</h2>
              : (

                <React.Fragment>
                  <React.Fragment>
                    {
                      assigned &&
                      <React.Fragment>
                        {
                          accessory && Object.keys(accessory).length > 0 &&
                          <React.Fragment>
                            <Container fluid className="mt-3">
                              <Row>
                                <Col>
                                  <Card>
                                    <CardBody>
                                      <h5 className="text-muted">DETAILS</h5>
                                      <Row className="mt-4">
                                        <Col lg="3" className="text-center mt-2">
                                          {/* <img src={qrCode} style={{ width: "100%", height: "300px" }} alt="" /> */}
                                          <div ref={ref}></div>
                                        </Col>
                                        <Col lg="9">
                                          <Row className="mt-4">
                                            <Col lg="4">
                                              <span className="text-orange" style={{ fontSize: "18px" }}>URL</span>
                                              <h6 className="jellie_id mt-2 text_scrollable">
                                                <div style={{ width: "230px", overflow: "scroll", whiteSpace: "nowrap" }}>{qrData()}</div>
                                              </h6>
                                            </Col>
                                            <Col lg="4">
                                              <span className="text-orange ml-3" style={{ fontSize: "18px" }}>Type</span>
                                              <h6 className="jellie_id ml-3 mt-2">{accessory.type}</h6>
                                            </Col>
                                            <Col lg="4">
                                              <span className="text-orange" style={{ fontSize: "18px" }}>Status</span>
                                              <h6 className="jellie_id mt-2">
                                                {
                                                  accessory?.status === null || accessory?.status === "" ? `-` : accessory?.status
                                                }
                                              </h6>
                                            </Col>
                                          </Row>
                                          <Row className="mt-3">
                                            <Col>
                                              <fieldset className="scheduler-border" style={{ border: "1px solid rgb(240, 222, 211)" }}>
                                                <legend className="scheduler-border assign_profile_title text-muted">Assigned Pet Profile</legend>
                                                <div className="control-group">
                                                  <Row>
                                                    <Col lg="3" md="6">
                                                      <Label className="text-orange ml-3" style={{ fontSize: "18px" }}>Created On</Label>
                                                      <h6 className="jellie_id ml-3">
                                                        {
                                                          new Date(accessory?.pet?.created_at).toLocaleDateString()
                                                        }
                                                      </h6>
                                                    </Col>
                                                    <Col lg="3" md="6">
                                                      <Label className="text-orange" style={{ fontSize: "18px" }}>Name</Label>
                                                      <h6 className="jellie_id"
                                                      >{accessory?.pet?.name}</h6>
                                                    </Col>
                                                    <Col lg="3" md="6">
                                                      <Label className="text-orange" style={{ fontSize: "18px" }}>Specie</Label>
                                                      <h6 className="jellie_id"
                                                      >{accessory?.pet?.specie}</h6>
                                                    </Col>
                                                    <Col lg="3" md="6">
                                                      <Label className="text-orange" style={{ fontSize: "18px" }}>Gender</Label>
                                                      <h6 className="jellie_id"
                                                      >{accessory?.pet?.gender}</h6>
                                                    </Col>
                                                  </Row>
                                                </div>
                                                <div>
                                                  <Button className="mt-3 ml-3 mb-3 bg-btn-orange" onClick={unAssignButton}>UNASSIGN</Button>
                                                </div>
                                              </fieldset>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </Container>
                          </React.Fragment>
                        }
                        {
                          Object.keys(accessory).length < 1 && !loading &&
                          <React.Fragment>
                            <h4 className="text-center mt-5" style={{ color: "#f5813c" }}>Something Went Wrong..</h4>
                          </React.Fragment>
                        }
                      </React.Fragment>
                    }

                    {
                      !assigned &&
                      <React.Fragment>
                        {
                          accessory && Object.keys(accessory).length > 0 &&
                          <React.Fragment><Container fluid className="mt-3">
                            <Row>
                              <Col>
                                <Card>
                                  <CardBody>
                                    <h5 className="text-muted">DETAILS</h5>
                                    <Row className="mt-4">
                                      <Col lg="4" className="text-center">
                                        {/* <img src={LOGO} style={{ width: "100%", height: "300px" }} alt="" /> */}
                                        <div ref={ref}></div>
                                      </Col>
                                      <Col lg="8">
                                        <Row className="mt-4">
                                          <Col lg="4">
                                            <span className="text-orange" style={{ fontSize: "18px" }}>URL</span>
                                            <h6 className="mt-2 jellie_id">
                                              <div style={{ width: "230px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{qrData()}</div>
                                            </h6>
                                          </Col>
                                          <Col lg="4">
                                            <span className="ml-3 text-orange" style={{ fontSize: "18px" }}>Type</span>
                                            <h6 className="ml-3 mt-2 jellie_id">{accessory?.type}</h6>
                                          </Col>
                                          <Col lg="4">
                                            <span className="text-orange" style={{ fontSize: "18px" }}>Status</span>
                                            <h6 className="mt-2 jellie_id">
                                              {
                                                accessory?.status === null || accessory?.status === "" ? `-` : accessory?.status
                                              }
                                            </h6>
                                          </Col>
                                        </Row>
                                        <Row className="mt-3">
                                          <Col>
                                            <fieldset className="scheduler-border pb-5 pt-3" style={{ border: "1px solid rgb(240, 222, 211)" }}>
                                              <legend className="scheduler-border assign_profile_title text-muted">Assigned Pet Profile</legend>
                                              <div className="control-group">
                                                <span className="not_assigned" style={{ fontWeight: "500", fontSize: "18px" }}>Not Assigned</span>
                                                {/* <Button className="mt-2 mb-3 ml-3 bg-btn-orange">ASSIGN NOW</Button> */}
                                              </div>
                                            </fieldset>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                                <Card className="mt-4">
                                  <Row>
                                    <Col md="12">
                                      <div className="">
                                        <div className="search__header__input">
                                          <Form>
                                            <Input
                                              id="name"
                                              name="search"
                                              placeholder="ID or Name"
                                              type="search"
                                              value={search}
                                              onChange={(e) => setSearch(e.target.value)}
                                            />
                                          </Form>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Card>
                                <Row>
                                  <Col>
                                    <Table borderless responsive className="mt-4 table-text-center">
                                      <thead className="table_head">
                                        <tr>
                                          <th>Image</th>
                                          <th><span className="sort-by" onClick={(e) => {
                                            setSortValue("id");
                                            tableSortingId();
                                          }}>Pet ID</span></th>
                                          <th><span className="sort-by" onClick={(e) => {
                                            setSortValue("name");
                                            tableSortingId();
                                          }}>Name</span></th>
                                          <th># IDs</th>
                                          <th style={{ width: "100px" }}>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody className="table__body">
                                        {
                                          pets && pets.length > 0 &&
                                          <React.Fragment>
                                            {
                                              pets.map((pet) => {
                                                return (
                                                  <tr key={pet?.id}>
                                                    <td style={{ verticalAlign: "middle" }}>
                                                      <img
                                                        src={pet?.pet_images?.length > 0 && imageResponse[pet?.pet_images[pet?.pet_images?.length - 1]?.filename]}
                                                        width="60"
                                                        height="65"
                                                        alt=""
                                                      />
                                                    </td>
                                                    <td style={{ verticalAlign: "middle" }}>{pet?.id}</td>
                                                    <td style={{ verticalAlign: "middle" }}>{pet?.name}</td>
                                                    <td style={{ verticalAlign: "middle" }}>
                                                      {pet?.accessories?.length}
                                                    </td>
                                                    <td style={{ verticalAlign: "middle" }}>
                                                      <Button
                                                        className="action_btn" onClick={e => {
                                                          assignPetButton(pet?.id, pet?.name, pet?.accessories?.length, pet?.pet_owners?.[0]?.owner_user?.user_limitation?.no_accessories);
                                                        }}
                                                      >
                                                        Assign
                                                      </Button>
                                                    </td>
                                                  </tr>
                                                )
                                              })
                                            }
                                          </React.Fragment>
                                        }
                                        {
                                          Object.keys(accessory).length < 1 && !petsLoading &&
                                          <React.Fragment>
                                            <tr>
                                              <td colSpan="5" style={{ verticalAlign: "middle" }}>
                                                <h4 className="text-center" style={{ color: "#f5813c" }}>Pet Not Found...</h4>
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        }

                                      </tbody>
                                    </Table>
                                  </Col>
                                </Row>
                                {/* pagination */}
                                {
                                  search === "" &&
                                  <React.Fragment>
                                    <div>
                                      <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        // breakLabel={"..."}
                                        // breakClassName={"break-me"}
                                        pageCount={petPageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClickCatalogue}
                                        containerClassName={"pagination_access"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                      />
                                    </div>
                                  </React.Fragment>
                                }
                              </Col>
                            </Row>
                          </Container></React.Fragment>
                        }
                        {
                          Object.keys(accessory).length < 1 && !loading &&
                          <React.Fragment>
                            <h4 className="text-center mt-5" style={{ color: "#f5813c" }}>Something Went Wrong..</h4>
                          </React.Fragment>
                        }
                      </React.Fragment>
                    }
                  </React.Fragment>
                  {/* limitation modal for accessories assigning */}
                  <React.Fragment>
                    <Modal isOpen={alertModel} toggle={confirmAlertModel} className="modal-dialog-centered modal-md delete_model">
                      <ModalBody>
                        <div className="text-center mt-3 text-orange">
                          <h3>The limit for assigning accessories for this pet has been reached.</h3>
                        </div>
                        <div className="pt-2 pl-2 pr-3" style={{ marginTop: "25px", display: "flex", justifyContent: "space-around" }}>
                          <Button color="secondary" onClick={() => {
                            confirmAlertModel()
                          }}>OK</Button>
                        </div>
                      </ModalBody>
                    </Modal>
                  </React.Fragment>
                </React.Fragment>

              )
          }
        </Container>
      </Fragment>
    </React.Fragment>
  )
};

export default AccessoriesDetails;