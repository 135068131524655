import React from "react";
import { Route, Redirect } from "react-router-dom";
import { history } from '../App'

let PublicRoutes = ({ component: Component, ...rest }) => {
  const requestedURL = rest.location.state
  // const isAuthenticated = useSelector(state => state.auth.token)
  const isAuthenticated = localStorage.getItem('Authorization')
  return (
    <Route
      {...rest}
      render={props => (isAuthenticated ? <Redirect to={requestedURL ? history.goBack() : '/dashboard'} /> : <Component {...props} />)}
    />
  )
};

export default PublicRoutes
