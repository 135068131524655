import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from "reactstrap";
import Topbar from "../../components/content/Topbar";
import SideBar from "../../components/sidebar/SideBar";
import classNames from "classnames";
import "./profile.css";
import { useMutation, useQuery } from "@apollo/client";
import { ALL_USERS } from "../../GraphQL/queries";
import { UPDATE_ADMIN_NAME, UPDATE_LOGIN_USER_PROFILE_IMAGE } from "../../GraphQL/mutation";
import { useHistory } from "react-router-dom";
import UPLOAD from '../../assets/onHover.png';
import axios from "axios";

const Profile = () => {
  const history = useHistory();

  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const [userInfo, setUserInfo] = useState({});
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [role, setRole] = useState("");
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [userProfileImage, setUserProfileImage] = useState("");
  // const [image, setImage] = useState(null);
  // const [hover, setHover] = useState(false);
  const inputFile = useRef(null)

  const [editProfileModal, setEditProfileModal] = useState(false);
  const confirmEditModal = () => {
    setEditProfileModal(!editProfileModal);
  };


  // find login user
  const { loading, error, data, refetch } = useQuery(ALL_USERS);
  if (error) console.error(error);

  // update login user profile
  const [updateImage, { error: updateImageError, loading: updateImageLoading }] = useMutation(UPDATE_LOGIN_USER_PROFILE_IMAGE);
  if (updateImageError) console.error(updateImageError);

  useEffect(() => {
    if (localStorage.getItem('data')) {
      if (data) {
        const token = JSON.parse(localStorage.getItem('data'));
        const userEmail = token.email
        const Info = data?.user?.filter(user => user.email === userEmail)[0]
        setFirstname(Info?.first_name);
        setLastname(Info?.last_name);
        setUserInfo(Info);
        const userData = Info.user_role_associations?.length > 0 && Info.user_role_associations.map(role => {
          if (role.role_id === 3) {
            setRole('Super')
          } else if (role.role_id === 2) {
            setRole('Admin')
          }
        })
      }
    }
  }, [data]);

  useEffect(async () => {
    if (data) {
      if (userInfo?.profile_images?.length > 0) {
        let fileName = userInfo?.profile_images[userInfo?.profile_images?.length - 1].filename;
        let imageResponse = await axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: [fileName] });
        setUserProfileImage(Object.values(imageResponse.data.urls)[0]);
      }
    }
  }, [userInfo]);

  const changeProfile = async (imageUrl) => {
    // inputFile.current.click()

    if (imageUrl !== null) {

      const bodyData = await {
        fileName: imageUrl.name,
        feature: 'User',
        featureId: userInfo?.id,
      };

      let response = await axios.post(process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL, bodyData);

      const data = new FormData();

      data.append(`Policy`, response?.data?.fields?.Policy);
      data.append(
        `X-Amz-Algorithm`,
        response?.data?.fields['X-Amz-Algorithm']
      );
      data.append(
        `X-Amz-Credential`,
        response?.data?.fields?.['X-Amz-Credential']
      );
      data.append(`X-Amz-Date`, response?.data?.fields?.['X-Amz-Date']);
      data.append(
        `X-Amz-Signature`,
        response?.data?.fields?.['X-Amz-Signature']
      );
      data.append(`bucket`, response?.data?.fields?.bucket);
      data.append(`key`, response?.data?.fields?.key);
      data.append('file', imageUrl);


      if (imageUrl !== null) {
        let responseOne = await axios.post(response?.data?.url, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });


        // call mutation for insert filename
        if (responseOne?.status >= 200 || responseOne?.status <= 299) {
          await updateImage({
            variables: {
              src: "",
              created_by: userInfo?.id,
              userId: userInfo?.id,
              type: "PROFILE",
              filename: response?.data?.fields?.key
            }
          });
        }

        refetch();
        setAlert(true);
        setMessage("Profile Image Updated");

      }


    } else {
      setAlert(true);
      setMessage("Plzz select an Image..");
    }
  };
  // update admin name
  const [updateProfile, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_ADMIN_NAME);
  if (updateError) console.error(updateError);

  const updateAdminName = async () => {
    try {
      await updateProfile({
        variables: {
          id: userInfo?.id,
          first_name: firstname,
          last_name: lastname
        }
      });

      setAlert(true);
      setMessage("Profile Updated");
      confirmEditModal();
      refetch();

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false)
      }, 3050)
    }
  }, [alert])
  return (
    <React.Fragment>
      <div className="alert-list">
        {alert &&
          <Alert color="info">{message}</Alert>
        }
      </div>
      <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
      <Container
        fluid
        style={{ margin: "0", padding: "0" }}
        className={classNames("content", { "is-open": sidebarIsOpen })}
        toggleSidebar={toggleSidebar}
      >
        <Topbar toggleSidebar={toggleSidebar} />
        <React.Fragment>
          {
            loading ?
              <h2 className="text-muted text-center mt-5">Loading.....</h2> :
              <React.Fragment>
                <Container fluid className="mt-3">
                  <Row>
                    <Col>
                      <Card style={{ minHeight: "80vh" }}>
                        <CardBody>
                          <h2 className="text-muted">Profile</h2>
                          <img src={userProfileImage} width="200" height="200" className={`mt-3 rounded-circle`} alt="" />
                          <Label for="file-upload" className="custom-file-upload bg-btn-orange">
                            Update Image
                          </Label>
                          <Input type='file' id='file-upload' className="image_upload_btn" name="Change Profile Image" onChange={async (e) => {
                            await changeProfile(e.target.files[0])
                          }} />
                          <Form className="mt-4">
                            <Row>
                              <Col sm="6">
                                <FormGroup>
                                  <Label className="text-muted">Firstname</Label>
                                  <Input type="text" defaultValue={userInfo?.first_name} onChange={(e) => setFirstname(e.target.value)} />
                                </FormGroup>
                              </Col>
                              <Col sm="6">
                                <FormGroup>
                                  <Label className="text-muted">Lastname</Label>
                                  <Input type="text" defaultValue={userInfo?.last_name} onChange={(e) => setLastname(e.target.value)} />
                                </FormGroup>
                              </Col>
                              <Col sm="6">
                                <Label className="text-muted">Email</Label>
                                <Input type="text" disabled value={userInfo?.email} />
                              </Col>
                              <Col sm="6">
                                <Label className="text-muted">Role</Label>
                                <Input type="text" disabled value={role} />
                              </Col>
                            </Row>
                            <Button className="profile_btn mr-3" onClick={(e) => {
                              confirmEditModal();
                            }}>Update</Button>
                            <Button className="profile_btn" onClick={(e) => {
                              history.push('/dashboard');
                            }}>Back</Button>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </React.Fragment>
          }
          <React.Fragment>
            <Modal isOpen={editProfileModal} toggle={confirmEditModal} className="modal-dialog-centered delete_model modal-sm">
              <ModalBody>
                <div className="text-center mt-3 text-orange">
                  <h3>Are you sure ?</h3>
                </div>
                <div className="pt-3 pl-2 pr-3" style={{ marginTop: "25px", display: "flex", justifyContent: "space-around" }}>
                  <Button className="bg-btn-orange modal_send_invite" style={{ border: "none", outline: "none" }} onClick={() => {
                    updateAdminName();
                  }}>UPDATE</Button>
                  <Button color="secondary" className="modal_cancel" onClick={() => {
                    confirmEditModal();
                  }}>CANCEL</Button>
                </div>
              </ModalBody>
            </Modal>
          </React.Fragment>
        </React.Fragment>
      </Container>
    </React.Fragment>
  )
};

export default Profile;