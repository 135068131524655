import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import classNames from "classnames";
import SideBar from "../../components/sidebar/SideBar";
import Topbar from "../../components/content/Topbar";
import { useQuery } from "@apollo/client";
import { GET_ORDER } from "../../GraphQL/queries";
import { useParams } from "react-router";
import Pendant from "../../assets/pendant.png";
import Sticker from "../../assets/sticker.png";

let OrderDetails = (props) => {

  // const { orderId } = useParams();
  const { match } = props;

  const orderId = match?.params.id;

  const [order, setOrder] = useState({});

  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  // graphql query (get order)
  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: {
      id: orderId
    }
  });

  if (error) console.error(error);

  useEffect(() => {
    if (data) {
      setOrder(data.orders_by_pk)
    };
  }, [data]);

  let options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' };

  return (
    <React.Fragment>
      <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
      <Container
        fluid
        style={{ margin: "0", padding: "0" }}
        className={classNames("content", { "is-open": sidebarIsOpen })}
        toggleSidebar={toggleSidebar}
      >
        <Topbar toggleSidebar={toggleSidebar} />
        <React.Fragment>
          {
            loading ?
              <h2 className="text-center text-muted mt-5">Loading.....</h2> :
              <React.Fragment>
                {
                  order && Object.keys(order).length > 0 ?
                    <React.Fragment>
                      <Container fluid className="mt-3">
                        <Row>
                          <Col>
                            <Card>
                              <CardBody className="order_details">
                                <h3 className="text-muted mt-2">ORDER DETAIL</h3>
                                <Row className="mt-3">
                                  <Col lg="3" md="6" className="text-center">
                                    {
                                      order?.type === "Sticker" ? <img src={Sticker} width="250" alt="" /> : <img src={Pendant} width="150" alt="" />
                                    }
                                  </Col>
                                  <Col md="9">
                                    <h3>{order.type}</h3>
                                    <Row className="mt-3">
                                      <Col md="4" className="mb-2">
                                        <Label>Order ID</Label>
                                        <h6>{order.id}</h6>
                                      </Col>
                                      <Col md="4" className="mb-2">
                                        <Label>Qty</Label>
                                        <h6>{order?.quantity}</h6>
                                        {/* <Label>Ship Date</Label>
                                        <h6>{order.shipping_date !== null ? new Date(order?.shipping_date).toLocaleString("en-US", options) : `-`}</h6> */}
                                      </Col>
                                      <Col md="4" className="mb-2">
                                        <Label>Order Placed</Label>
                                        <h6>{new Date(order.created_at).toLocaleString("en-US", options)}</h6>
                                        {/* <Label>Ship Co.</Label>
                                        <h6>{order.shipping_company ? order.shipping_company : `-`}</h6> */}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="4" className="mb-2">
                                        {/* <Label>Qty</Label>
                                        <h6>{order?.quantity}</h6> */}
                                        <Label>Ship Date</Label>
                                        <h6>{order.shipping_date !== null ? new Date(order?.shipping_date).toLocaleString("en-US", options) : `-`}</h6>
                                      </Col>
                                      <Col md="8" className="mb-2">
                                        <Label>Ship To</Label>
                                        <h6>
                                          {
                                            `${order?.ship_to_street1} ${order?.ship_to_street2}, ${order?.ship_to_city}, ${order?.ship_to_state}, ${order?.ship_to_zip}`
                                          }
                                        </h6>
                                      </Col>
                                      <Col md="4" className="mb-2">
                                        <Label>Ship Co.</Label>
                                        <h6>{order.shipping_company ? order.shipping_company : `-`}</h6>
                                        {/* <Label>Tracking #</Label>
                                        <h6>{order.shipping_tracking_no ? order.shipping_tracking_no : `-`}</h6> */}
                                      </Col>
                                      <Col md="8" className="mb-2">
                                        {/* <Label>Order Placed</Label>
                                        <h6>{new Date(order.created_at).toLocaleString("en-US", options)}</h6> */}
                                        <Label>Tracking #</Label>
                                        <h6>{order.shipping_tracking_no ? order.shipping_tracking_no : `-`}</h6>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col>
                                    <fieldset className="scheduler-border">
                                      <legend className="scheduler-border text-muted" style={{ marginLeft: "60px" }}>Charge</legend>
                                      <div className="control-group">
                                        <Container>
                                          <Row>
                                            <Col md="3">
                                              <Label>Product</Label>
                                              <h6>${order?.charge_price.toFixed(2)}</h6>
                                            </Col>
                                            <Col md="3">
                                              <Label>Shipping</Label>
                                              <h6>${order?.charge_shipping.toFixed(2)}</h6>
                                            </Col>
                                            <Col md="3">
                                              <Label>Other</Label>
                                              <h6>${order?.charge_taxes.toFixed(2)}</h6>
                                            </Col>
                                            <Col md="3">
                                              <Label style={{ fontSize: "28px" }}>Total Amount</Label>
                                              <h6 style={{ fontSize: "25px" }}>${order && (order.charge_price + order.charge_shipping + order.charge_taxes).toFixed(2)}</h6>
                                            </Col>
                                          </Row>
                                        </Container>
                                      </div>
                                    </fieldset>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    </React.Fragment> : <h4 className="text-center mt-5" style={{ color: "#f5813c" }}>Something went wrong</h4>
                }
              </React.Fragment>
          }
        </React.Fragment>
      </Container>
    </React.Fragment>
  )
};

export default OrderDetails;