import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Alert, Button, Card, CardBody, Col, Container, Label, Modal, ModalBody, ModalFooter, Row, Table } from "reactstrap";
import Topbar from "../../components/content/Topbar";
import SideBar from "../../components/sidebar/SideBar";
import { Link, useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PET_PARENT_PROFILE, GET_PET_PROFILE } from "../../GraphQL/queries";
import { DELETE_PET, DELETE_PET_CO_OWNER, DELETE_PET_HOST, DELETE_PET_OWNER, UPDATE_PET_PREMIUM, UPDATE_PET_STATUS } from "../../GraphQL/mutation";
import axios from "axios";
import DEFAULT_PET_IMAGE from '../../assets/Icon_256w_UnSelectedPet_NoImage.png';

let PetProfile = (props) => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const [petProfile, setPetProfile] = useState({});
  const [activatedPet, setActivatedPet] = useState();
  // const [premiumUser, setPremiumUser] = useState();
  // const [petOwnerId, setPetOwnerId] = useState();
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [parentType, setParentType] = useState("");
  const [deleteParentId, setDeleteParentId] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [filename, setFilename] = useState("");
  const [petParentId, setPetParentId] = useState();
  const [petParentProfile, setPetParentProfile] = useState({});

  // delete pet modal
  const [editDeleteModal, setEditDeleteModal] = useState(false);
  const confirmDeleteModal = () => {
    setEditDeleteModal(!editDeleteModal);
  };

  // delete pet parent modal
  const [parentDeleteModal, setParentDeleteModal] = useState(false);
  const confirmParentDeleteModal = () => {
    setParentDeleteModal(!parentDeleteModal);
  };

  // delete pet inactive model
  const [alertModel, setAlertModel] = useState(false);
  const confirmAlertModel = () => {
    setAlertModel(!alertModel);
  };

  const petId = useParams().petId;
  const history = useHistory();

  // graphql query (get pet profile)
  const { loading, error, data, refetch } = useQuery(GET_PET_PROFILE, {
    variables: {
      id: petId
    }
  })
  if (error) console.error(error);

  // graphql mutation (update pet status)
  const [updatePetStatus, { loading: updateStatusLoading, error: updateStatusError }] = useMutation(UPDATE_PET_STATUS);
  if (updateStatusError) console.error(updateStatusError);

  // graphql mutation (update pet premium/basic);
  const [updatePetPremium, { loading: premiumLoading, error: premiumError }] = useMutation(UPDATE_PET_PREMIUM);
  if (premiumError) console.error(premiumError);

  // graphql mutation (delete pet)
  const [deletePet, { loading: deletePetLoading, error: deletePetError }] = useMutation(DELETE_PET);
  if (deletePetError) console.error(deletePetError);

  // delete pet owner
  const [deletePetOwner, { loading: ownerLoading, error: ownerError }] = useMutation(DELETE_PET_OWNER);
  if (ownerError) console.error(ownerError);

  // delete co-pet owner
  const [deletePetCoOwner, { loading: coOwnerLoading, error: coOwnerError }] = useMutation(DELETE_PET_CO_OWNER);
  if (coOwnerError) console.error(coOwnerError);

  // delete pet-host owner
  const [deletePetHost, { loading: hostLoading, error: hostError }] = useMutation(DELETE_PET_HOST);
  if (hostError) console.error(hostError);

  // get pet profile
  useEffect(async () => {
    if (data) {
      setPetProfile(data?.pet_by_pk);
      setPetParentId(data?.pet_by_pk?.pet_owners?.[0]?.owner_user_id);
      setActivatedPet(data?.pet_by_pk?.activated);
      // setPremiumUser(data?.pet_by_pk?.pet_owners[0]?.owner_user?.premium);
      // setPetOwnerId(data?.pet_by_pk?.pet_owners[0]?.owner_user_id);
      if (data && data?.pet_by_pk?.pet_images?.length > 0) {
        const image = data?.pet_by_pk?.pet_images[data?.pet_by_pk?.pet_images?.length - 1].filename
        setFilename(data?.pet_by_pk?.pet_images[data?.pet_by_pk?.pet_images?.length - 1].filename)
        const response = await axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: [image] })
        setImageUrl(Object.values(response?.data?.urls)[0]);
      }
    }
  }, [petId, data]);

  // get pet parent profile
  const { loading: petParentLoading, error: petParentError, data: petParentData } = useQuery(GET_PET_PARENT_PROFILE, {
    skip: !petParentId,
    variables: {
      id: petParentId && parseInt(petParentId)
    }
  });
  if (petParentError) console.error(petParentError);

  useEffect(() => {
    if (petParentData) {
      setPetParentProfile(petParentData?.user_by_pk);
    }
  }, [petParentData]);


  // update pet status
  const changePetStatus = async (e) => {

    if (!activatedPet) {
      if (petParentProfile?.pets_owned?.filter(data => !data?.pet?.deleted && data?.pet?.activated)?.length >= parseInt(petParentProfile?.user_limitation?.pets_as_parent)) {
        confirmAlertModel();
        return;
      }
    }

    await updatePetStatus({
      variables: {
        id: petId,
        activated: !activatedPet
      }
    });
    refetch();
    setAlert(true);
    setMessage("Pet Status Updated Successfully");
  };

  // update pet premium
  const changePetPremium = async (e) => {
    await updatePetPremium({
      variables: {
        id: petId,
        premium: !petProfile?.premium
      }
    });
    refetch();
    setAlert(true);
    setMessage("Pet account type updated");
  };

  // delete pet
  const deletePetBtn = async () => {
    await deletePet({
      variables: {
        id: petId,
        deleted: true,
        activated: false
      }
    });
    confirmDeleteModal();
    setAlert(true);
    setMessage("Delete Pet Successfully");
    refetch();
    await props.history.goBack();
  };

  // delete pet parent
  const deleteParentBtn = async () => {
    if (parentType === "owner") {
      await deletePetOwner({
        variables: {
          userId: deleteParentId,
          petId: petId
        }
      });
      confirmParentDeleteModal();
      setAlert(true);
      setMessage("Delete Pet Owner Successfully..");
      refetch();
    };

    if (parentType === "co-owner") {
      await deletePetCoOwner({
        variables: {
          userId: deleteParentId,
          petId: petId
        }
      });
      confirmParentDeleteModal();
      setAlert(true);
      setMessage("Delete Pet Co-owner Successfully..");
      refetch();
    };

    if (parentType === "host") {
      await deletePetHost({
        variables: {
          userId: deleteParentId,
          petId: petId
        }
      });
      confirmParentDeleteModal();
      setAlert(true);
      setMessage("Delete Pet Host Successfully..");
      refetch();
    };
  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false)
      }, 3000)
    }
  }, [alert]);

  return (
    <React.Fragment>
      <div className="alert-list">
        {alert &&
          <Alert color="info">
            {message}
          </Alert>
        }
      </div>
      <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
      <Container
        fluid
        style={{ margin: "0", padding: "0" }}
        className={classNames("content", { "is-open": sidebarIsOpen })}
        toggleSidebar={toggleSidebar}
      >
        <Topbar toggleSidebar={toggleSidebar} />

        <React.Fragment>
          {
            loading || petParentLoading ?
              <h2 className="text-center text-muted mt-5">Loading......</h2> :
              <React.Fragment>
                {
                  petProfile && Object.keys(petProfile).length > 0 &&
                  <React.Fragment>
                    <Container fluid className="mt-3">
                      <Row>
                        <Col>
                          <Card style={{ minHeight: "80vh" }}>
                            <CardBody className="user_profile">
                              <h3 className="text-muted">Details</h3>
                              <Row className="">
                                <Col lg="3" md="6" sm="12">
                                  <img src={filename ? imageUrl : DEFAULT_PET_IMAGE} className="img-fluid mt-2" alt="" />
                                </Col>
                                <Col lg="9" md="6" sm="6">
                                  <Row>
                                    <Col lg="4" className="mb-3">
                                      <Label className="user_detail_title">Name</Label>
                                      <h6>{petProfile?.name ? petProfile?.name : `-`}</h6>
                                    </Col>
                                    <Col lg="4" className="mb-3">
                                      <Label className="user_detail_title">Specie</Label>
                                      <h6>{petProfile?.specie ? petProfile.specie : `-`}</h6>
                                    </Col>
                                    <Col lg="4" className="mb-3">
                                      <Label className="user_detail_title">Breed</Label>
                                      <h6>{petProfile?.breed ? petProfile.breed : `-`}</h6>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="4" className="mb-3">
                                      <Label className="user_detail_title">Type</Label>
                                      <h6>
                                        {
                                          petProfile?.premium === true ? 'Premium' : 'Basic'
                                        }
                                      </h6>
                                    </Col>
                                    <Col lg="4" className="mb-3">
                                      <Label className="user_detail_title">Status</Label>
                                      <h6>
                                        {
                                          petProfile?.activated === true ? `Active` : 'InActive'
                                        }
                                      </h6>
                                    </Col>
                                    <Col lg="4" className="mb-3"><Label className="user_detail_title">Created On</Label>
                                      <h6>
                                        {
                                          new Date(petProfile?.created_at).toLocaleString()
                                        }
                                      </h6>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="4" className="mt-4">
                                      <label className="mt-1 mb-4 switch_pet_bas">
                                        <input type="checkbox" className="checkbox_pet_bas" id="customSwitch1" defaultChecked={!petProfile?.premium}
                                          onChange={(e) => {
                                            changePetPremium();
                                          }}
                                        />
                                        <span className="toggle-thumb_pet_bas">
                                          <span className="active">Basic</span>
                                          <span className="inactive">Premium</span>
                                        </span>
                                      </label>
                                    </Col>
                                    <Col lg="4" className="mt-4">
                                      <label className="mt-1 mb-4 switch_pet_act">
                                        <input type="checkbox" className="checkbox_pet_act" id="customSwitch1"
                                          checked={petProfile?.activated ? true : false}
                                          onChange={(e) => {
                                            changePetStatus();
                                          }}
                                        />
                                        <span className="toggle-thumb_pet_act">
                                          <span className="active">Active</span>
                                          <svg width="10" height="11" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.87488 6.378L1.34688 3.851L0.504883 4.693L3.87488 8.063L11.0949 0.842L10.2529 0L3.87488 6.378Z" fill="white" />
                                          </svg>

                                          <svg width="8" height="13" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.00004 8.50004C6.85388 8.50005 6.71504 8.43611 6.62004 8.32504L0.620036 1.32504C0.533835 1.22426 0.491201 1.09336 0.501516 0.961145C0.51183 0.82893 0.574248 0.706228 0.675036 0.620036C0.775816 0.533835 0.906713 0.491201 1.03893 0.501516C1.17114 0.51183 1.29384 0.574248 1.38004 0.675036L7.38004 7.67504C7.5069 7.82336 7.53586 8.03194 7.45421 8.20922C7.37256 8.38649 7.19521 8.50005 7.00004 8.50004Z" fill="white" />
                                            <path d="M1.00004 8.50004C0.880864 8.50005 0.765607 8.45749 0.675036 8.38004C0.574248 8.29384 0.51183 8.17114 0.501516 8.03893C0.491201 7.90671 0.533835 7.77582 0.620036 7.67504L6.62004 0.675036C6.70623 0.574248 6.82893 0.51183 6.96114 0.501516C7.09336 0.491201 7.22426 0.533835 7.32504 0.620036C7.42582 0.706228 7.48824 0.82893 7.49856 0.961145C7.50887 1.09336 7.46624 1.22426 7.38004 1.32504L1.38004 8.32504C1.28504 8.43611 1.14619 8.50005 1.00004 8.50004Z" fill="white" />
                                          </svg>
                                          <span className="inactive">Inactive</span>
                                        </span>
                                      </label>
                                    </Col>
                                    {
                                      !petProfile?.deleted &&
                                      <Col lg="4" className="mt-3">
                                        <Button className="delete_btn_pet" onClick={(e) => {
                                          confirmDeleteModal();
                                        }}>Delete</Button>
                                      </Col>
                                    }
                                  </Row>
                                </Col>
                                {/* </Col> */}
                              </Row>
                              <Row className="mt-2">
                                <Col>
                                  <fieldset className="scheduler-border user__detail__address">
                                    <legend className="scheduler-border text-muted user__detail__address__title">Biometrics</legend>
                                    <div className="control-group">
                                      <Row>
                                        <Col lg="3" md="6">
                                          <Label className="address_title">Birthdate</Label>
                                          <h6 className="address_desc">{
                                            petProfile?.birthdate ? new Date(petProfile.birthdate).toLocaleDateString() : `-`
                                          }</h6>
                                        </Col>
                                        <Col lg="3" md="6">
                                          <Label className="address_title">Gender</Label>
                                          <h6 className="address_desc">{petProfile?.gender ? petProfile.gender : `-`}</h6>
                                        </Col>
                                        <Col lg="3" md="6">
                                          <Label className="address_title">Reproductive</Label>
                                          <h6 className="address_desc">{petProfile?.sex_health ? petProfile.sex_health : `-`}</h6>
                                        </Col>
                                        <Col lg="3" md="6">
                                          <Label className="address_title">Weight</Label>
                                          <h6 className="address_desc">{petProfile?.weight ? `${petProfile.weight} lb` : `-`}</h6>
                                        </Col>
                                      </Row>
                                    </div>
                                  </fieldset>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Table borderless responsive className="text-left mt-4">
                                    <thead className="table_head">
                                      <tr>
                                        <th>User Name</th>
                                        <th>Association</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th style={{ width: "150px" }}>Action</th>
                                      </tr>
                                    </thead>

                                    <tbody className="bg-white">
                                      {
                                        petProfile?.pet_owners && petProfile?.pet_owners?.length > 0 &&
                                        petProfile?.pet_owners?.filter((p) => p?.accepted === true)?.map((data) => {
                                          return (
                                            <React.Fragment>
                                              <tr key={data?.owner_user_id} style={{ height: "80px" }}>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                  {/* {data?.owner_user?.first_name} {data?.owner_user?.last_name} */}
                                                  {
                                                    data?.owner_user?.first_name !== null &&
                                                    <span>{data?.owner_user?.first_name}</span>
                                                  }{" "}
                                                  {
                                                    data?.owner_user?.last_name !== null &&
                                                    <span>{data?.owner_user?.last_name}</span>
                                                  }
                                                  {
                                                    data?.owner_user?.first_name === null && data?.owner_user?.last_name === null && `-`
                                                  }
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                  Parent
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                  {data?.start_date ? new Date(data.start_date).toLocaleDateString() : `-`}
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                  {data?.expiry ? new Date(data.expiry).toLocaleDateString() : `-`}
                                                </td>
                                                <td className="action_icon" style={{ verticalAlign: 'middle' }}>
                                                  <Link to={`/user/profile/jellie/${data?.owner_user_id}`}>
                                                    <i className="fa fa-eye mr-2"></i>
                                                  </Link>
                                                  <span>
                                                    <i className="fa fa-trash ml-3" onClick={() => {
                                                      confirmParentDeleteModal();
                                                      setParentType("owner");
                                                      setDeleteParentId(data?.owner_user_id);
                                                    }}></i>
                                                  </span>
                                                </td>
                                              </tr>
                                            </React.Fragment>
                                          )
                                        })
                                      }
                                      {
                                        petProfile?.pet_co_owners && petProfile?.pet_co_owners?.length > 0 &&
                                        petProfile?.pet_co_owners?.filter((p) => p?.accepted === true)?.map((data) => {
                                          return (
                                            <React.Fragment>
                                              <tr key={data?.co_owner_user_id} style={{ height: "80px" }}>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                  {/* {data?.co_owned_user?.first_name} {data?.co_owned_user?.last_name} */}
                                                  {
                                                    data?.co_owned_user?.first_name !== null &&
                                                    <span>{data?.co_owned_user?.first_name}</span>
                                                  }{" "}
                                                  {
                                                    data?.co_owned_user?.last_name !== null &&
                                                    <span>{data?.co_owned_user?.last_name}</span>
                                                  }
                                                  {
                                                    data?.co_owned_user?.first_name === null && data?.co_owned_user?.last_name === null && `-`
                                                  }
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                  Co-Parent
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                  {data?.start_date ? new Date(data.start_date).toLocaleDateString() : `-`}
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                  {data?.expiry ? new Date(data.expiry).toLocaleDateString() : `-`}
                                                </td>
                                                <td className="action_icon" style={{ verticalAlign: 'middle' }}>
                                                  <Link to={`/user/profile/jellie/${data?.co_owner_user_id}`}>
                                                    <i className="fa fa-eye mr-2"></i>
                                                  </Link>
                                                  <span>
                                                    <i className="fa fa-trash ml-3" onClick={() => {
                                                      confirmParentDeleteModal();
                                                      setParentType("co-owner");
                                                      setDeleteParentId(data?.co_owner_user_id);
                                                    }}></i>
                                                  </span>
                                                </td>
                                              </tr>
                                            </React.Fragment>
                                          )
                                        })
                                      }
                                      {
                                        petProfile?.pet_hosts && petProfile?.pet_hosts?.length > 0 &&
                                        petProfile?.pet_hosts?.filter((p) => p?.accepted === true)?.map((data) => {
                                          return (
                                            <React.Fragment>
                                              <tr key={data?.host_user_id} style={{ height: "80px" }}>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                  {/* {data?.host_user?.first_name} {data?.host_user?.last_name} */}
                                                  {
                                                    data?.host_user?.first_name !== null &&
                                                    <span>{data?.host_user?.first_name}</span>
                                                  }{" "}
                                                  {
                                                    data?.host_user?.last_name !== null &&
                                                    <span>{data?.host_user?.last_name}</span>
                                                  }
                                                  {
                                                    data?.host_user?.first_name === null && data?.host_user?.last_name === null && `-`
                                                  }
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                  Host
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                  {data?.start_date ? new Date(data.start_date).toLocaleDateString() : `-`}
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                  {data?.expiry ? new Date(data?.expiry).toLocaleDateString() : `-`}
                                                </td>
                                                <td className="action_icon" style={{ verticalAlign: 'middle' }}>
                                                  <Link to={`/user/profile/jellie/${data?.host_user_id}`}>
                                                    <i className="fa fa-eye mr-2"></i>
                                                  </Link>
                                                  <span>
                                                    <i className="fa fa-trash ml-3" onClick={() => {
                                                      confirmParentDeleteModal();
                                                      setParentType("host");
                                                      setDeleteParentId(data?.host_user_id);
                                                    }}></i>
                                                  </span>
                                                </td>
                                              </tr>
                                            </React.Fragment>
                                          )
                                        })
                                      }
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                    {/* pet delete modal */}
                    <React.Fragment>
                      <Modal isOpen={editDeleteModal} toggle={confirmDeleteModal} className="modal-dialog-centered delete_model modal-sm">
                        <ModalBody>
                          <div className="text-center mt-3 text-orange">
                            <h3>Are you sure ?</h3>
                          </div>
                          <div className="pt-3 pl-2 pr-3" style={{ marginTop: "25px", display: "flex", justifyContent: "space-around" }}>
                            <Button className="bg-btn-orange modal_send_invite" style={{ border: "none", outline: "none" }} onClick={deletePetBtn}>DELETE</Button>
                            <Button color="secondary" className="modal_cancel" onClick={() => {
                              confirmDeleteModal();
                            }}>CANCEL</Button>
                          </div>
                        </ModalBody>
                      </Modal>
                    </React.Fragment>
                    {/* pet parent delete modal */}
                    <React.Fragment>
                      <Modal isOpen={parentDeleteModal} toggle={confirmParentDeleteModal} className="modal-dialog-centered delete_model modal-sm">
                        <ModalBody>
                          <div className="text-center mt-3 text-orange">
                            <h3>Are you sure ?</h3>
                          </div>
                          <div className="pt-3 pl-2 pr-3" style={{ marginTop: "25px", display: "flex", justifyContent: "space-around" }}>
                            <Button className="bg-btn-orange modal_send_invite" style={{ border: "none", outline: "none" }} onClick={deleteParentBtn}>DELETE</Button>
                            <Button color="secondary" className="modal_cancel" onClick={() => {
                              confirmParentDeleteModal();
                              setParentType("");
                              setDeleteParentId();
                            }}>CANCEL</Button>
                          </div>
                        </ModalBody>
                      </Modal>
                    </React.Fragment>
                    {/* pet inactive alert model (petParent >= limitation) */}
                    <React.Fragment>
                      <Modal isOpen={alertModel} toggle={confirmAlertModel} className="modal-dialog-centered modal-md delete_model">
                        <ModalBody>
                          <div className="text-center mt-3 text-orange">
                            <h3>At least one of the parents of this pet is already at their pet limit.</h3>
                          </div>
                          <div className="pt-2 pl-2 pr-3" style={{ marginTop: "25px", display: "flex", justifyContent: "space-around" }}>
                            <Button color="secondary" onClick={() => {
                              confirmAlertModel()
                            }}>OK</Button>
                          </div>
                        </ModalBody>
                      </Modal>
                    </React.Fragment>
                  </React.Fragment>
                }
              </React.Fragment>
          }
        </React.Fragment>

      </Container>
    </React.Fragment>
  )
};

export default PetProfile;