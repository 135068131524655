import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import moment from 'moment';
import classNames from "classnames"
import Flatpickr from "react-flatpickr";
import PrimaryGraph from "../../assets/Widgets - _Elements - Chart - Mini Chart.png";
import WarningGraph from "../../assets/Widgets - _Elements - Chart - Mini Chart-1.png";
import DangerGraph from "../../assets/Widgets - _Elements - Chart - Mini Chart-2.png";
import SecondaryGraph from "../../assets/Widgets - _Elements - Chart - Mini Chart-3.png";
import SideBar from "../../components/sidebar/SideBar"
import Topbar from "../../components/content/Topbar"
import { GET_TOTAL_ACTIVE_USER, GET_ACTIVE_USER_DATA, GET_CURRENT_ACTIVE_USERS, GET_WM_USER_DATA } from "../../GraphQL/queries";
import { useQuery } from "@apollo/client";
import './Dashboard.css'
import { Line } from 'react-chartjs-2';


let Dashboard = (props) => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  let [users, setUsers] = useState();
  const [weekMonthdata, setWeekMonthData] = useState({});
  const [currentUsers, setCurrentUsers] = useState();
  const [chartLabel, setChartLabel] = useState([]);
  const [chartActiveUser, setChartActiveUser] = useState([]);
  let setOneMonth = new Date();
  setOneMonth.setMonth(setOneMonth.getMonth() - 1);

  let [startDate, setStartDate] = useState(moment(new Date(setOneMonth), "MM/DD/YYYY").format("YYYY-MM-DD"));
  let [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [apiResGraphData, setApiResGraphData] = useState([]);
  const [globalDates, setGlobalDates] = useState([]);
  // graphql

  useEffect(() => {

    // refreshing page on load (refetch)
    setCurrentUsers();
    setWeekMonthData({});
    setUsers();
    currentUserRefetch()
    activeUsersRefecth()

    if (startDate && endDate) {
      onGetData()
      getDatesInRange()
    }
  }, []);

  // get current active users
  const fetchCurrentUser = moment(new Date()).format("YYYY-MM-DD");
  const { error: currentUserError, data: currentUserData, refetch: currentUserRefetch } = useQuery(GET_CURRENT_ACTIVE_USERS, {
    variables: {
      date: fetchCurrentUser
    }
  });
  if (currentUserError) console.error(currentUserError);

  useEffect(() => {
    if (currentUserData) {
      setCurrentUsers(currentUserData?.user_activity_aggregate?.aggregate?.count);
    }
  }, [currentUserData]);



  // get weekly and monthly active users
  const { error: wmError, data: wmData } = useQuery(GET_WM_USER_DATA);
  if (wmError) console.error(wmError);

  useEffect(() => {
    if (wmData) {
      setWeekMonthData(wmData?.user_dashboard[0]);
    }
  }, [wmData]);



  let { loading, error, data: activeUserData, refetch: activeUsersRefecth } = useQuery(GET_TOTAL_ACTIVE_USER);
  let { loading: loading2, error: error2, data: otherUserData, refetch: onGetData } = useQuery(GET_ACTIVE_USER_DATA, {
    variables: {
      gte: moment(startDate).format('YYYY-MM-DD'),
      lte: moment(endDate).format('YYYY-MM-DD')
    }
  });

  if (error) console.error(error);
  if (error2) console.error(error2);

  function clearData() {
    setApiResGraphData([])
  }

  useEffect(() => {
    if (activeUserData) {
      setUsers(activeUserData.user_aggregate.aggregate.count);
    }
  }, [activeUserData]);


  function getDatesInRange() {
    const date = new Date(new Date(startDate).getTime());
    const arr = [];
    while (date <= new Date(endDate)) {
      arr.push({
        date: moment(new Date(date)).format("YYYY-MM-DD"),
        daily_active_users: 0,
        monthly_active_users: 0,
        weekly_active_users: 0
      });
      date.setDate(date.getDate() + 1);
    }
    setGlobalDates(arr);
  }

  useEffect(() => {
    if (otherUserData) {
      clearData();
      setApiResGraphData(otherUserData?.user_dashboard);
    }
  }, [otherUserData]);

  useEffect(() => {
    if (startDate && endDate) {
      onGetData()
      getDatesInRange()
    }
  }, [startDate, endDate]);


  useEffect(() => {
    if (globalDates?.length > 0) {
      globalDates?.map((data, i) => {
        apiResGraphData?.find((o) => {
          if (o?.date === data?.date) {
            globalDates.splice(i, 1, o)
          }
        })
      })
      setChartLabel(globalDates?.map(date => moment(date?.date).format('MM-DD-YYYY')));
      setChartActiveUser(globalDates?.map(data => data?.daily_active_users));
    }
  }, [apiResGraphData, globalDates]);


  const chartData = {
    labels: chartLabel,
    datasets: [
      {
        label: 'Active Users',
        data: chartActiveUser,
        borderWidth: 1.5,
        lineTension: 0.3,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: '#F5813C',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false
        },
        ticks: {
          stepSize: 2
        }
      },
      // x: {
      //   ticks: {
      //     autoSkip: false
      //   }
      // },
    },
    hover: {
      mode: 'index',
      intersect: false
    },
    elements: {
      point: {
        radius: 0
      },
      line: {
        tension: 0
      },
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false
      },



      tooltips: {
        enabled: false,

        custom: function (tooltipModel) {
          var tooltipEl = document.getElementById("chartjs-tooltip");

          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          if (tooltipModel.body) {
            var titleLines = tooltipModel.title || [];
            var bodyLines = tooltipModel.body.map(getBody);

            var innerHtml = "<thead>";

            titleLines.forEach(function (title) {
              innerHtml += "<tr><th>" + title + "</th></tr>";
            });
            innerHtml += "</thead><tbody>";

            bodyLines.forEach(function (body, i) {
              var colors = tooltipModel.labelColors[i];
              var style = "background:" + colors.backgroundColor;
              style += "; border-color:" + colors.borderColor;
              style += "; border-width: 2px";
              var span = '<span style="' + style + '"></span>';
              innerHtml += "<tr><td>" + span + body + "</td></tr>";
              innerHtml +=
                "<tr><td>" + <span>"Some custom field here"</span> + "</td></tr>";
            });
            innerHtml += "</tbody>";

            var tableRoot = tooltipEl.querySelector("table");
            tableRoot.innerHTML = innerHtml;
          }

          var position = this._chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding =
            tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
          tooltipEl.style.pointerEvents = "none";
        }
      }




    }
  };

  function datePart(date) {
    return moment(date).utc().local().format('M/DD/YYYY');
  }


  return (
    <React.Fragment>
      <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} {...props} />
      <Container
        fluid
        style={{ margin: "0", padding: "0" }}
        className={classNames("content", { "is-open": sidebarIsOpen })}
        toggleSidebar={toggleSidebar}
      >
        <Topbar toggleSidebar={toggleSidebar} dashboardBtn={true} />
        {
          (loading || loading2) ?
            <h2 className="text-center text-muted mt-5">Loading.....</h2> :
            <React.Fragment>
              <Container fluid className="mt-3">
                <Row>
                  <Col lg="3">
                    <Card className="dashboard_count">
                      <CardBody className="dashboard_count_body" style={{ position: "relative" }}>
                        <div className="count_text">
                          <h6 className="text-muted">Active Users</h6>
                        </div>
                        <div className="count_graph">
                          <h4 style={{ color: "#47AEFF" }}>
                            {users ? users : 0}
                          </h4>
                          <img src={PrimaryGraph} alt="" />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card className="dashboard_count">
                      <CardBody className="dashboard_count_body" style={{ position: "relative" }}>
                        <div className="count_text">
                          <h6 className="text-muted">Current Active Users</h6>
                        </div>
                        <div className="count_graph">
                          <h4 style={{ color: "#FCC937" }}>
                            {
                              currentUsers ? currentUsers : 0
                            }
                          </h4>
                          <img src={WarningGraph} style={{ position: "absolute", bottom: "10px", right: "10px" }} alt="" />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card className="dashboard_count">
                      <CardBody className="dashboard_count_body" style={{ position: "relative" }}>
                        <div className="count_text">
                          <h6 className="text-muted">Current Week Active Users</h6>
                        </div>
                        <div className="count_graph">
                          <h4 style={{ color: "#FF722A" }}>
                            {
                              weekMonthdata?.weekly_active_users ? weekMonthdata.weekly_active_users : 0
                            }
                          </h4>
                          <img src={DangerGraph} style={{ position: "absolute", bottom: "10px", right: "10px" }} alt="" />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card className="dashboard_count">
                      <CardBody className="dashboard_count_body" style={{ position: "relative" }}>
                        <div className="count_text">
                          <h6 className="text-muted">Current Month Active Users</h6>
                        </div>
                        <div className="count_graph">
                          <h4 className="" style={{ color: "#9021FF" }}>
                            {
                              weekMonthdata?.monthly_active_users ? weekMonthdata.monthly_active_users : 0
                            }
                          </h4>
                          <img src={SecondaryGraph} style={{ position: "absolute", bottom: "10px", right: "10px" }} alt="" />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <div className="default__box">
                      <div className="graph__header">
                        <h4>Active Users</h4>
                        <div className="dashboard_filter">
                          <div className="to_date mr-4">
                            <lable className="mr-2">From</lable>
                            <Flatpickr
                              options={{
                                monthSelectorType: "dropdown",
                                dateFormat: "m/d/Y",
                                maxDate: (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear()
                              }}
                              value={datePart(startDate)}
                              onChange={([date]) => {
                                setGlobalDates([])
                                const d = new Date(date)
                                setStartDate(d);
                              }}
                            />
                          </div>
                          <div className="from_date mr-3">
                            <lable className="mr-2">To</lable>
                            <Flatpickr
                              options={{
                                monthSelectorType: "dropdown",
                                dateFormat: "m/d/Y",
                                enable: [
                                  {
                                    from: "12/25/2000",
                                    to: (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear(),
                                  }
                                ]
                              }}
                              value={datePart(endDate)}
                              onChange={([date]) => {
                                setGlobalDates([])
                                const d = new Date(date)
                                setEndDate(d);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <Line data={chartData}
                        height={"105px"}
                        options={options} />
                    </div>
                  </Col>
                </Row>
              </Container>
            </React.Fragment>
        }
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;
