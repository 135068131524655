import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import axios from 'axios';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { UncontrolledAlert } from 'reactstrap';
import {
  GET_PRODUCT_CATALOGUE_LIST,
  GET_USER_PRODUCT_LIST,
  GET_SEARCH_PRODUCT_CATALOGUE_LIST,
  GET_SEARCH_USER_PRODUCT_LIST,
  GET_PRODUCT_CATALOGUE_LENGTH, GET_USER_PRODUCT_LENGTH, ALL_USERS
} from '../../GraphQL/queries';
import SideBar from '../../components/sidebar/SideBar';
import Topbar from '../../components/content/Topbar';
import {
  ADD_PRODUCT,
  ADMIN_CREATED_PRODUCTS,
  DELETED_PRODUCTS_LIST,
  DELETE_PRODUCT,
  DELETE_PRODUCT_TRUE,
  EDIT_PRODUCT,
  INSERT_FILENAME_PRODUCT,
  NULL_NAME_PRODUCT,
  UNADMIN_PRODUCTS,
  UNDELETE_PRODUCT,
  UPDATE_PRODUCT_IMAGE,
} from '../../GraphQL/mutation';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  Modal,
  ModalBody,
} from 'reactstrap';
import './Product.css';
import Spinner from '../../assets/spinner.gif';
import ProductImage from '../../assets/Images/product.svg';
import ReactPaginate from 'react-paginate';
import DEFAULT_PRODUCT_IMAGE from '../../assets/Icon_256w_Product_NoImage.png';
// caloric_serving
// total_servings

let Product = (props) => {
  let [addProduct, setAddProduct] = useState(true);
  let [alert, setAlert] = useState(false);
  let [message, setMessage] = useState('');
  let [isEdit, setIsEdit] = useState(false);
  let [search, setSearch] = useState('');
  let [activeTab, setActiveTab] = useState('1');
  let [type, setType] = useState('');
  let [details, setDetails] = useState({});
  let [productCatalogueList, setProductCatalogueList] = useState([]);
  let [userProductList, setUserProductList] = useState([]);
  let [deleteProductId, setDeleteProductId] = useState();
  const [productCatalogueLength, setProductCatalogueLength] = useState();
  const [userProductLength, setUserProductLength] = useState();
  const [image, setImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [imageResponseCatalogue, setImageResponseCatalogue] = useState({});
  const [imageResponseUser, setImageResponseUser] = useState({});
  const [loginUser, setLoginUser] = useState({});
  const [showImage, setShowImage] = useState("");
  const [editImage, setEditImage] = useState("");
  const [propFileName, setPropFileName] = useState('');

  const [sortValue, setSortValue] = useState("created_at");
  const [sort, setSort] = useState("created_at");
  const [sortBy, setSortBy] = useState("desc");

  const previousProps = useRef({ details }).current;

  // delete model
  const [editModal, setEditModal] = useState(false);
  const confirmDeleteModal = () => {
    setEditModal(!editModal);
  };

  // get catalogue product length
  const { data: catalogueLengthData } = useQuery(GET_PRODUCT_CATALOGUE_LENGTH);
  useEffect(() => {
    if (catalogueLengthData) {
      setProductCatalogueLength(catalogueLengthData?.pet_product?.length);
    }
  }, [catalogueLengthData]);

  // get user product length
  const { data: userLengthData } = useQuery(GET_USER_PRODUCT_LENGTH);
  useEffect(() => {
    if (userLengthData) {
      setUserProductLength(userLengthData?.pet_product?.length);
    }
  }, [userLengthData]);

  // insert image mutation
  const [insertFile, { error: insertError }] = useMutation(INSERT_FILENAME_PRODUCT);
  if (insertError) console.error(insertError);

  // update product image
  // const [updateImage, { error: updateError }] = useMutation(UPDATE_PRODUCT_IMAGE);
  // if (updateError) console.error(updateError);

  // find login user
  const { error: loginUserError, data: loginUserData } = useQuery(ALL_USERS);
  if (loginUserError) console.error(loginUserError);
  useEffect(() => {
    if (localStorage.getItem('data')) {
      if (loginUserData) {
        const token = JSON.parse(localStorage.getItem('data'));
        const userEmail = token.email
        setLoginUser(loginUserData?.user?.filter(user => user.email === userEmail)[0]);
      }
    }
  }, [loginUserData]);

  let [submitProduct, setSubmitProduct] = useState({
    image: "",
    type: '',
    name: '',
    serving_size: '',
    nutrient_protein: null,
    nutrient_fat: null,
    nutrient_fiber: null,
    nutrient_ash: '',
    nutrient_moisture: null,
    manufacturer: '',
    // advanced type
    specie: '',
    description: '',
    serving_calories: '',
    barcode: '',
    barcode_type: '',
    ingredients: '',
    product_tags: '',
    serving_unit: '',
    serving_total: null
  });


  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  let setToggle = () => {
    setImage("");
    setShowImage("")
    setDetails({});
    setAddProduct(!addProduct);
  };

  // graphql query (product catalogue list)
  let {
    loading,
    error,
    data,
    refetch: onGetProductCategoryList,
  } = useQuery(GET_PRODUCT_CATALOGUE_LIST, {
    variables: {
      offset: offset,
      order_by: {
        [sort.toString()]: sortBy
      }
    }
  });
  let {
    loading: loading2,
    error: error2,
    data: searchData,
    refetch: fetchProductCatalogueList,
  } = useQuery(GET_SEARCH_PRODUCT_CATALOGUE_LIST, {
    variables: {
      iregex: search,
    },
  });

  if (error2) console.error(error2);

  useEffect(() => {
    if (search === "") {
      if (data) {
        setProductCatalogueList(data?.pet_product);
        const imageArray = data?.pet_product?.map(data => {
          if (data?.pet_images?.length > 0) {
            return data?.pet_images[data?.pet_images?.length - 1].filename
          }
        })
        const finalArray = imageArray.filter((data) => data !== undefined)
        axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: finalArray }).then((res) => {
          setImageResponseCatalogue(res?.data?.urls)
        });
      }
    }
    if (search !== "") {
      if (searchData) {
        setProductCatalogueList(searchData.pet_product);
        const imageArray = searchData?.pet_product?.map(data => {
          if (data?.pet_images?.length > 0) {
            return data?.pet_images[data?.pet_images?.length - 1].filename
          }
        })
        const finalArray = imageArray.filter((data) => data !== undefined)
        axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: finalArray }).then((res) => {
          setImageResponseCatalogue(res?.data?.urls)
        });
      }
    }

  }, [data, searchData, search]);

  if (error) console.error(error);

  // let convertBase64String = (imageFile) => {
  //   return new Promise((resolve, reject) => {
  //     let fileReader = new FileReader();
  //     fileReader.readAsDataURL(imageFile);
  //     fileReader.addEventListener("load", () => {
  //       if (fileReader.result) {
  //         resolve(fileReader.result);
  //       } else {
  //         reject("Error Occured");
  //       }
  //     });
  //   });
  // };

  useEffect(() => {
    if (props?.history?.location?.state?.product) {
      setSubmitProduct({
        type: props?.history?.location?.state?.product?.type,
        name: props?.history?.location?.state?.product?.name,
        serving_size:
          props?.history?.location?.state?.product?.serving_size,
        nutrient_protein:
          props?.history?.location?.state?.product?.nutrient_protein,
        nutrient_fat:
          props?.history?.location?.state?.product?.nutrient_fat,
        nutrient_fiber:
          props?.history?.location?.state?.product?.nutrient_fiber,
        nutrient_ash:
          props?.history?.location?.state?.product?.nutrient_ash,
        nutrient_moisture:
          props?.history?.location?.state?.product?.nutrient_moisture,
        manufacturer:
          props?.history?.location?.state?.product?.manufacturer,
        // advanced type
        specie: props?.history?.location?.state?.product?.specie,
        serving_calories:
          props?.history?.location?.state?.product?.serving_calories,
        barcode: props?.history?.location?.state?.product?.barcode,
        barcode_type:
          props?.history?.location?.state?.product?.barcode_type,
        ingredients:
          props?.history?.location?.state?.product?.ingredients,
        description:
          props?.history?.location?.state?.product?.description,
        product_tags:
          props?.history?.location?.state?.product?.product_tags,
        serving_unit:
          props?.history?.location?.state?.product?.serving_unit,
        serving_total: props?.history?.location?.state?.product?.serving_total,
        created_by: loginUser?.id,
        image: props?.history?.location?.state?.product?.pet_images
      });
      setShowImage(props?.history?.location?.state?.product?.pet_images[props?.history?.location?.state?.product?.pet_images?.length - 1]?.filename ? props?.history?.location?.state?.product?.pet_images[props?.history?.location?.state?.product?.pet_images?.length - 1]?.filename : '');
      setPropFileName(props?.history?.location?.state?.product?.pet_images[props?.history?.location?.state?.product?.pet_images?.length - 1]?.filename ? props?.history?.location?.state?.product?.pet_images[props?.history?.location?.state?.product?.pet_images?.length - 1]?.filename : '');
      setAddProduct(false);
    } else {
      setSubmitProduct({
        // image: '',
        type: '',
        name: '',
        serving_size: '',
        nutrient_protein: null,
        nutrient_fat: null,
        nutrient_fiber: null,
        nutrient_ash: '',
        nutrient_moisture: null,
        manufacturer: '',
        // advanced type
        specie: '',
        serving_calories: '',
        barcode: '',
        barcode_type: '',
        ingredients: '',
        description: '',
        product_tags: '',
        serving_unit: '',
        serving_total: null
      });
    }
    // setDetails({})
    onGetProductCategoryList();
    onGetUserProductList();
  }, []);

  // useEffect(() => {
  //   if (search) {

  //   }
  // }, [search])

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
  }, [alert]);

  // useEffect(() => {
  //   if (data) {
  //     setProductCatalogueList(data.pet_product);
  //   }
  // }, [data]);

  // graphql query (user product list)

  const [petName, setPetName] = useState({});
  useEffect(() => {
    sort === "userId" ? setPetName({ "created_by_user": { id: sortBy } }) : sort === "userName" ? setPetName({ "created_by_user": { first_name: sortBy } }) : setPetName({ [sort.toString()]: sortBy })
  }, [sortValue, sortBy]);

  let {
    loading: userProductLoading,
    error: userProductError,
    data: userProductData,
    refetch: onGetUserProductList,
  } = useQuery(GET_USER_PRODUCT_LIST, {
    variables: {
      offset: offset,
      order_by: petName
    }
  });
  let {
    loading: userProductLoading2,
    error: userProductError2,
    data: userSearchProductData,
    refetch: fetchUserProductList,
  } = useQuery(GET_SEARCH_USER_PRODUCT_LIST, {
    variables: {
      iregex: search,
    },
  });

  if (userProductError2) console.error(userProductError2);
  if (userProductError) console.error(userProductError);

  useEffect(() => {
    if (search === '') {
      if (userProductData) {
        setUserProductList(userProductData.pet_product);
        const imageArray = userProductData?.pet_product?.map(data => {
          if (data?.pet_images?.length > 0) {
            return data?.pet_images[data?.pet_images?.length - 1].filename
          }
        })
        const finalArray = imageArray.filter((data) => data !== undefined)
        axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: finalArray }).then((res) => {
          setImageResponseUser(res?.data?.urls)
        });
      }
    }
    if (search !== '') {
      if (userSearchProductData) {
        setUserProductList(userSearchProductData.pet_product);
        const imageArray = userSearchProductData?.pet_product?.map(data => {
          if (data?.pet_images?.length > 0) {
            return data?.pet_images[data?.pet_images?.length - 1].filename
          }
        })
        const finalArray = imageArray.filter((data) => data !== undefined)
        axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: finalArray }).then((res) => {
          setImageResponseUser(res?.data?.urls)
        });
      }
    }
  }, [userProductData, userSearchProductData, search]);

  // useEffect(() => {
  //   if (userProductData) {
  //     setUserProductList(userProductData.pet_product);
  //   }
  // }, [userProductData]);

  useEffect(() => {
    if (previousProps.details !== details) {
      if (details) {
        setSubmitProduct({
          type: details?.type,
          name: details?.name,
          serving_size: details?.serving_size,
          nutrient_protein: details?.nutrient_protein,
          nutrient_fat: details?.nutrient_fat,
          nutrient_fiber: details?.nutrient_fiber,
          nutrient_ash: details?.nutrient_ash,
          nutrient_moisture: details?.nutrient_moisture,
          manufacturer: details?.manufacturer,
          // advanced type
          specie: details?.specie,
          serving_calories: details?.serving_calories,
          barcode: details?.barcode,
          barcode_type: details?.barcode_type,
          ingredients: details?.ingredients,
          product_tags: details?.product_tags,
          serving_unit: details?.serving_unit,
          description: details?.description,
          serving_total: details?.serving_total
        });
        setType(details?.type);
      }
    }
    return () => {
      previousProps.details = details;
    };
  }, [details]);

  // useEffect(() => {
  //   if (search !== '') {
  //     if (activeTab === '1') {
  //       searchProductCategoryList({
  //         name: "Acana Light & Fit"
  //       })
  //     } else if (activeTab === '2') {
  //       searchUserProduct()
  //     }
  //   }
  // }, [search]);

  // graphql mutation (add product)
  let [saveProduct, { loading: submitting, error: submitError }] =
    useMutation(ADD_PRODUCT);
  let [editProduct, { loading: editting, error: editError }] =
    useMutation(EDIT_PRODUCT);

  useEffect(async () => {
    if (image?.length === 0) {
      return;
    }
    try {
      document.querySelector('#image_show')?.classList.add('d-none');
    } catch (error) {
      console.error(error);
    }

    // if (submitProduct?.image !== "") {
    //   document.querySelector('#image_show').style.display = 'none';
    // }
  }, [image]);
  let submitPetProduct = async (e) => {
    e.preventDefault();
    const addProduct = await saveProduct({
      variables: {
        type: submitProduct.type,
        name: submitProduct.name,
        // serving_size: submitProduct.serving_size,
        nutrient_protein: submitProduct.nutrient_protein ? Number(submitProduct.nutrient_protein) : null,
        nutrient_fat: submitProduct.nutrient_fat ? Number(submitProduct.nutrient_fat) : null,
        nutrient_fiber: submitProduct.nutrient_fiber ? Number(submitProduct.nutrient_fiber) : null,
        nutrient_ash: submitProduct.nutrient_ash,
        nutrient_moisture: submitProduct.nutrient_moisture ? Number(submitProduct.nutrient_moisture) : null,
        manufacturer: submitProduct.manufacturer,
        specie: submitProduct.specie,
        serving_calories: submitProduct.serving_calories,
        barcode: submitProduct.barcode,
        barcode_type: submitProduct.barcode_type,
        ingredients: submitProduct.ingredients,
        description: submitProduct.description,
        product_tags: submitProduct.product_tags,
        serving_unit: submitProduct.serving_unit,
        serving_total: submitProduct.serving_total ? Number(submitProduct.serving_total) : null,
        admin_created: true,
        created_by: loginUser?.id
      },
    });

    // upload image
    if (image?.name) {
      let bodyData = {
        fileName: image.name,
        feature: 'Pet',
        featureId: addProduct?.data?.insert_pet_product_one?.id,
      };

      let response = await axios.post(
        process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL,
        bodyData
      );

      let obj = {
        ...response?.data?.fields,
        file: image,
      };

      const data = new FormData();

      data.append(`Policy`, response?.data?.fields?.Policy);
      data.append(
        `X-Amz-Algorithm`,
        response?.data?.fields['X-Amz-Algorithm']
      );
      data.append(
        `X-Amz-Credential`,
        response?.data?.fields?.['X-Amz-Credential']
      );
      data.append(`X-Amz-Date`, response?.data?.fields?.['X-Amz-Date']);
      data.append(
        `X-Amz-Signature`,
        response?.data?.fields?.['X-Amz-Signature']
      );
      data.append(`bucket`, response?.data?.fields?.bucket);
      data.append(`key`, response?.data?.fields?.key);
      data.append('file', image);

      if (image !== null) {
        let responseOne = await axios.post(response?.data?.url, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });


        // call mutation for insert filename
        if (responseOne?.status >= 200 || responseOne?.status <= 299) {
          await insertFile({
            variables: {
              filename: response?.data?.fields?.key,
              userId: loginUser?.id,
              productId: addProduct?.data?.insert_pet_product_one?.id,
              type: "PRODUCT",
              src: ""
            }
          });
        }


      }


    } else {

      if (propFileName !== '') {
        await insertFile({
          variables: {
            filename: propFileName,
            userId: loginUser?.id,
            productId: addProduct?.data?.insert_pet_product_one?.id,
            type: "PRODUCT",
            src: ""
          }
        });
        setPropFileName('');
      }

    }



    if (submitError) console.error(error);

    setToggle();

    setAlert(true);
    setMessage('Product added successfully');

    onGetProductCategoryList();
    onGetUserProductList();
    setSearch('');
  };

  useEffect(async () => {
    if (showImage) {
      const response = await axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: [showImage] });
      setImage(Object.values(response?.data?.urls)[0]);
    }
    // if (image?.length !== 0) {
    //   setShowImage("");
    //   setEditImage("");
    // }
  }, [showImage, image]);


  let editPetProduct = async (e) => {
    e.preventDefault();
    const product = await editProduct({
      variables: {
        id: details?.id,
        barcode: submitProduct?.barcode,
        barcode_type: submitProduct?.barcode_type,
        ingredients: submitProduct?.ingredients,
        name: submitProduct?.name,
        nutrient_ash: submitProduct?.nutrient_ash,
        nutrient_fat: submitProduct?.nutrient_fat ? Number(submitProduct?.nutrient_fat) : null,
        nutrient_fiber: submitProduct?.nutrient_fiber ? Number(submitProduct?.nutrient_fiber) : null,
        nutrient_moisture: submitProduct?.nutrient_moisture ? Number(submitProduct?.nutrient_moisture) : null,
        nutrient_protein: submitProduct?.nutrient_protein ? Number(submitProduct?.nutrient_protein) : null,
        product_tags: submitProduct?.product_tags,
        // serving_size: String,
        specie: submitProduct?.specie,
        serving_calories: submitProduct?.serving_calories,
        type: submitProduct?.type,
        manufacturer: submitProduct?.manufacturer,
        serving_unit: submitProduct?.serving_unit,
        description: submitProduct?.description,
        serving_total: submitProduct?.serving_total ? Number(submitProduct?.serving_total) : null,
      },
    });

    // upload image
    if (image.name) {
      let bodyData = {
        fileName: image.name,
        feature: 'Pet',
        featureId: details?.id,
      };

      let response = await axios.post(
        process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL,
        bodyData
      );

      let obj = {
        ...response?.data?.fields,
        file: image,
      };

      const data = new FormData();

      data.append(`Policy`, response?.data?.fields?.Policy);
      data.append(
        `X-Amz-Algorithm`,
        response?.data?.fields['X-Amz-Algorithm']
      );
      data.append(
        `X-Amz-Credential`,
        response?.data?.fields?.['X-Amz-Credential']
      );
      data.append(`X-Amz-Date`, response?.data?.fields?.['X-Amz-Date']);
      data.append(
        `X-Amz-Signature`,
        response?.data?.fields?.['X-Amz-Signature']
      );
      data.append(`bucket`, response?.data?.fields?.bucket);
      data.append(`key`, response?.data?.fields?.key);
      data.append('file', image);

      if (image !== null) {
        let responseOne = await axios.post(response?.data?.url, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (responseOne?.status >= 200 || responseOne?.status <= 299) {
          // call mutation for insert filename
          await insertFile({
            variables: {
              filename: response?.data?.fields?.key,
              userId: loginUser?.id,
              productId: details?.id,
              type: "PRODUCT",
              src: ""
            }
          });
        }


      }


    }

    if (editError) console.error(error);

    setMessage('Product updated successfully');
    setIsEdit(false);
    setAddProduct(true);
    setAlert(true);
    // if (activeTab === '1') {
    onGetProductCategoryList();
    fetchProductCatalogueList();
    // } else {
    onGetUserProductList();
    fetchUserProductList();
    // }
    setDetails({});
    setSearch('');
    setImage(null);
  };

  let changeInput = (e) => {
    setSubmitProduct({
      ...submitProduct,
      [e.target.name]: e.target.value,
    });
  };

  // graphql mutation (delete product)
  let [
    deleteProduct,
    { loading: deleteProductLoading, error: deleteProductError },
  ] = useMutation(DELETE_PRODUCT);

  let deleteItem = async (dataId) => {
    await deleteProduct({
      variables: {
        id: deleteProductId,
      },
    });

    if (deleteProductError) console.error(deleteProductError);
    setMessage('Product deleted successfully');
    setAlert(true);

    confirmDeleteModal();
    setDeleteProductId();

    if (activeTab === '1') {
      onGetProductCategoryList();
      fetchProductCatalogueList();
    } else {
      onGetUserProductList();
      fetchUserProductList();
    }
  };

  // paginations

  // product catalogue pagination
  const cataloguePageCount = Math.ceil(productCatalogueLength / perPage);
  const handlePageClickCatalogue = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;

    // setCurrentPage(selectedPage);
    setOffset(offset);
    // onGetProductCategoryList();
  };


  // user product pagination
  const userPageCount = Math.ceil(userProductLength / perPage);
  const handlePageClickUser = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;

    // setCurrentPage(selectedPage);
    setOffset(offset);
    // onGetUserProductList();
  };


  // sort table
  const tableSortingId = (e) => {
    if (search === "") {
      setSort(sortValue);
      if (sortBy === "asc") {
        setSortBy("desc");
      } else if (sortBy === "desc") {
        setSortBy("asc");
      }
    }
  };



  const handleOndragOver = (event) => {
    event.preventDefault();
  };

  const handleOndrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let imageFile = event.dataTransfer.files[0];
    setImage(imageFile);
  };


  return (
    <React.Fragment>
      <div className='alert-list'>
        {alert && (
          <UncontrolledAlert color='info'>
            {message}
          </UncontrolledAlert>
        )}
      </div>
      <Fragment>
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Container
          fluid
          style={{ margin: '0', padding: '0' }}
          className={classNames('content', {
            'is-open': sidebarIsOpen,
          })}
          toggleSidebar={toggleSidebar}
        >
          <Topbar
            toggleSidebar={toggleSidebar}
            isShowButton={true}
            activeTab={activeTab}
            setActiveTab={(data) => {
              setActiveTab(data);
              setSearch('');
              setSort("created_at");
              setSortBy("desc");
              setSortValue("created_at");
              setOffset(0);
            }}
          />
          <section style={{ margin: '10px 12px' }}>
            {/* {loading && userProductLoading ? (
              <h2 className='text-center text-muted mt-5'>
                Loading......
              </h2>
            ) : ( */}
            <React.Fragment>
              {activeTab === '1' ? (
                <React.Fragment>
                  <Row>
                    <Col>
                      {/* <Button color="success" onClick={unDeleteUserProduct}>UNdelete</Button> */}
                      {addProduct && (
                        <React.Fragment>
                          <Row>
                            <Col md='11'>
                              <div className='search__header'>
                                <div className='search__header__text'>
                                  <span>
                                    Search
                                  </span>
                                </div>
                                <div className='search__header__input'>
                                  <Form>
                                    <Input
                                      id='name'
                                      name='name'
                                      placeholder='Name'
                                      type='search'
                                      onChange={(e) => {
                                        setSearch(e.target.value);
                                      }}
                                    />
                                  </Form>
                                </div>
                              </div>
                            </Col>
                            <Col
                              md='1'
                              className='mt-3'
                            >
                              <div className='mt-1 plus__buttom'>
                                <Input
                                  type='checkbox'
                                  style={{
                                    display:
                                      'none',
                                  }}
                                  id='setToggleBtn'
                                  onClick={() => {
                                    setToggle();
                                    setDetails({});
                                    setImage("");
                                    setShowImage("");
                                    setIsEdit(false);
                                  }}
                                />
                                {!addProduct ? (
                                  <label
                                    htmlFor='setToggleBtn'
                                    className='addProductBtn'
                                  >
                                    <i className='fa fa-minus'></i>
                                  </label>
                                ) : (
                                  <label
                                    htmlFor='setToggleBtn'
                                    className='addProductBtn'
                                  >
                                    <i className='fa fa-plus'></i>
                                  </label>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}

                      {!addProduct && (
                        <React.Fragment>
                          <Card className='mt-4'>
                            <CardHeader
                              className='top__header__body bg-white d-flex justify-content-between align-items-center px-4'
                              style={{
                                borderRadius:
                                  '8px',
                              }}
                            >
                              <h5 className='top__header__text text-muted'>
                                {
                                  isEdit ? "EDIT PRODUCT" : "ADD PRODUCT"
                                }
                              </h5>
                              <div>
                                <Input
                                  type='checkbox'
                                  style={{
                                    display:
                                      'none',
                                  }}
                                  id='setToggleBtn'
                                  onChange={() => {
                                    setToggle();
                                    setDetails(
                                      {}
                                    );
                                    setSearch(
                                      ''
                                    );
                                  }}
                                />
                                {addProduct ? (
                                  <label
                                    htmlFor='setToggleBtn'
                                    className='addProductBtn'
                                  >
                                    <i className='fa fa-plus'></i>
                                  </label>
                                ) : (
                                  <label
                                    htmlFor='setToggleBtn'
                                    className='addProductBtn'
                                  >
                                    <i className='fa fa-minus'></i>
                                  </label>
                                )}
                              </div>
                            </CardHeader>
                            <CardBody className='addProductText'>
                              <h4 className='text-orange'>
                                Basic
                              </h4>
                              <Form
                                onSubmit={
                                  isEdit
                                    ? editPetProduct
                                    : submitPetProduct
                                }
                              >
                                <Row>
                                  <Col md='4'>
                                    <FormGroup>
                                      <Label htmlFor='image'>
                                        <p
                                          style={{
                                            display:
                                              'block',
                                          }}
                                        >
                                          Image
                                        </p>
                                        <span
                                          className='upload__image'
                                          id='image_show'
                                          onDragOver={handleOndragOver}
                                          onDrop={handleOndrop}
                                        >
                                          <input
                                            id='image'
                                            type='file'
                                            onChange={(e) => {
                                              setImage(e.target.files[0]);
                                              setShowImage('')
                                              if (image?.length !== 0) {
                                                setSubmitProduct({ ...setSubmitProduct, image: "" });
                                              }
                                            }}
                                            className='file'
                                          />
                                        </span>
                                        {image?.length !==
                                          0 && (
                                            <React.Fragment>
                                              <img
                                                // src={isEdit ? image ? URL.createObjectURL(image) : editImage : URL.createObjectURL(image)}
                                                src={image ? image.name ? URL.createObjectURL(image) : image : ''}
                                                className="img-fluid" style={{ height: "180px" }}
                                                alt=''
                                              />
                                            </React.Fragment>
                                          )}
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                  <Col md='4'>
                                    <FormGroup>
                                      <Label
                                        style={{
                                          display:
                                            'block',
                                        }}
                                      >
                                        Type
                                      </Label>
                                      <Input
                                        name='type'
                                        type='select'
                                        id='type'
                                        value={
                                          submitProduct.type
                                        }
                                        onChange={(
                                          e
                                        ) => {
                                          setType(
                                            e
                                              .target
                                              .value
                                          );
                                          setSubmitProduct(
                                            {
                                              ...submitProduct,
                                              type: e
                                                .target
                                                .value,
                                            }
                                          );
                                        }}
                                      >
                                        <option value=''>
                                          Select
                                        </option>
                                        <option
                                          value='Medicine'
                                          key='Medicine'
                                        >
                                          Medicine
                                        </option>
                                        <option
                                          value='Food'
                                          key='Food'
                                        >
                                          Food
                                        </option>
                                        <option
                                          value='Treat'
                                          key='Treat'
                                        >
                                          Treat
                                        </option>
                                      </Input>
                                    </FormGroup>
                                    <FormGroup>
                                      <Label>
                                        Serving Unit
                                      </Label>
                                      <Input
                                        type='text'
                                        maxLength='5'
                                        name='serving_unit'
                                        onChange={
                                          changeInput
                                        }
                                        value={
                                          submitProduct?.serving_unit
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md='4'>
                                    <FormGroup>
                                      <Label>
                                        Name
                                      </Label>
                                      <Input
                                        type='text'
                                        name='name'
                                        onChange={
                                          changeInput
                                        }
                                        value={
                                          submitProduct?.name
                                        }
                                      />
                                    </FormGroup>

                                    {
                                      type === "Food" || type === "Treat" ?
                                        <FormGroup>
                                          <Label>
                                            Calories per serving unit
                                          </Label>
                                          <Input
                                            type='number'
                                            name='serving_calories'
                                            onChange={
                                              changeInput
                                            }
                                            value={
                                              submitProduct?.serving_calories
                                            }
                                          />
                                        </FormGroup> : null
                                    }
                                  </Col>
                                </Row>

                                {type ===
                                  'Food' || type === "Treat" ? (
                                  <React.Fragment>
                                    <h4 className='text-orange my-3'>
                                      Nutrients
                                    </h4>
                                    <Row>
                                      <Col md='4'>
                                        <FormGroup>
                                          <Label>
                                            Protein
                                            (min)
                                          </Label>
                                          <Input
                                            type='number'
                                            onChange={
                                              changeInput
                                            }
                                            name='nutrient_protein'
                                            value={
                                              submitProduct?.nutrient_protein
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <Label>
                                            Ash
                                            (est)
                                          </Label>
                                          <Input
                                            type='text'
                                            onChange={
                                              changeInput
                                            }
                                            name='nutrient_ash'
                                            value={
                                              submitProduct?.nutrient_ash
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md='4'>
                                        <FormGroup>
                                          <Label>
                                            Fat
                                            (min)
                                          </Label>
                                          <Input
                                            type='number'
                                            onChange={
                                              changeInput
                                            }
                                            name='nutrient_fat'
                                            value={
                                              submitProduct?.nutrient_fat
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <Label>
                                            Moisture
                                            max
                                          </Label>
                                          <Input
                                            type='number'
                                            onChange={
                                              changeInput
                                            }
                                            name='nutrient_moisture'
                                            value={
                                              submitProduct?.nutrient_moisture
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md='4'>
                                        <FormGroup>
                                          <Label>
                                            Fiber
                                            (max)
                                          </Label>
                                          <Input
                                            type='number'
                                            onChange={
                                              changeInput
                                            }
                                            name='nutrient_fiber'
                                            value={
                                              submitProduct?.nutrient_fiber
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                ) : null}

                                <h4 className='text-orange my-3'>
                                  Advanced
                                </h4>
                                <Row>
                                  <Col md='4'>
                                    <FormGroup>
                                      <Label>
                                        Manufacturer
                                      </Label>
                                      <Input
                                        type='text'
                                        name='manufacturer'
                                        onChange={
                                          changeInput
                                        }
                                        value={
                                          submitProduct?.manufacturer
                                        }
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Label>
                                        Total Servings
                                      </Label>
                                      <Input
                                        type='number'
                                        name='serving_total'
                                        onChange={
                                          changeInput
                                        }
                                        value={
                                          submitProduct?.serving_total
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md='4'>
                                    <FormGroup>
                                      <Label>
                                        Barcode
                                      </Label>
                                      <Input
                                        type='text'
                                        name='barcode'
                                        value={
                                          submitProduct?.barcode
                                        }
                                        onChange={
                                          changeInput
                                        }
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Label>
                                        Specie
                                      </Label>
                                      <Input
                                        type='select'
                                        value={
                                          submitProduct?.specie
                                        }
                                        name='specie'
                                        className='form-control'
                                        onChange={
                                          changeInput
                                        }
                                      >
                                        <option value="">
                                          Select
                                        </option>
                                        <option value='Dog'>
                                          Dog
                                        </option>
                                        <option value='Cat'>
                                          Cat
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col md='4'>
                                    <FormGroup>
                                      <Label>
                                        Barcode
                                        Type
                                      </Label>
                                      <Input
                                        type='select'
                                        className='form-control'
                                        name='barcode_type'
                                        onChange={
                                          changeInput
                                        }
                                        value={
                                          submitProduct?.barcode_type
                                        }
                                      >
                                        <option value=''>
                                          Select
                                        </option>
                                        <option value='EAN'>
                                          EAN
                                        </option>
                                        <option value='UPC'>
                                          UPC
                                        </option>
                                        <option value='QR'>
                                          QR
                                        </option>
                                        <option value='Other'>
                                          Other
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md='4'>
                                    <FormGroup>
                                      <Label>
                                        Ingredients
                                      </Label>
                                      <Input
                                        type='textarea'
                                        name='ingredients'
                                        onChange={
                                          changeInput
                                        }
                                        value={
                                          submitProduct?.ingredients
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md='4'>
                                    <FormGroup>
                                      <Label>
                                        Description
                                      </Label>
                                      <Input
                                        type='textarea'
                                        value={
                                          submitProduct?.description
                                        }
                                        name='description'
                                        onChange={
                                          changeInput
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md='4'>
                                    <FormGroup>
                                      <Label>
                                        Tags
                                      </Label>
                                      <Input
                                        type='textarea'
                                        name='product_tags'
                                        onChange={
                                          changeInput
                                        }
                                        value={
                                          submitProduct?.product_tags
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <div>
                                  <Button
                                    type='submit'
                                    className='bg-orange saveProductBtn'
                                  >
                                    {submitting ? (
                                      <span>
                                        Save
                                        Product...
                                      </span>
                                    ) : editting ? (
                                      <span>
                                        Save...
                                      </span>
                                    ) : (
                                      <span>
                                        {isEdit
                                          ? 'Save'
                                          : 'Save Product'}
                                      </span>
                                    )}
                                  </Button>
                                  <Button
                                    type='submit'
                                    className='bg-orange saveProductBtn ml-3'
                                    onClick={() => {
                                      setIsEdit(false);
                                      setAddProduct(true);
                                      setDetails({});
                                      setSearch('');
                                      setImage("");
                                      setShowImage("");
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Form>
                            </CardBody>
                          </Card>
                        </React.Fragment>
                      )}
                      {/* </Card> */}

                      {/* Products */}

                      {addProduct && (
                        <React.Fragment>
                          <Table
                            borderless
                            responsive
                            className='mt-3 table-text-left'
                          >
                            <thead className='table_head'>
                              <tr>
                                <th>
                                  Image
                                </th>
                                <th><span className="sort-by" onClick={(e) => {
                                  setSortValue("name");
                                  tableSortingId();
                                }}>
                                  Name</span>
                                </th>
                                <th><span className="sort-by" onClick={(e) => {
                                  setSortValue("type");
                                  tableSortingId();
                                }}>
                                  Type</span>
                                </th>
                                <th><span className="sort-by" onClick={(e) => {
                                  setSortValue("manufacturer");
                                  tableSortingId();
                                }}>
                                  Manufacturer</span>
                                </th>
                                <th
                                  style={{
                                    width: '25%',
                                  }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className='bg-white text-left'>
                              <Fragment>
                                {productCatalogueList &&
                                  productCatalogueList.length >
                                  0 &&
                                  productCatalogueList.map(
                                    (
                                      data
                                    ) => {
                                      {
                                        /* if (data?.pet_images?.length > 0) {
  const image = data?.pet_images[0]?.filename;
  axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: [image] }).then((res) => setImageData(Object.values(res?.data?.urls)[0]));
} */
                                      }
                                      return (
                                        <tr
                                          key={
                                            data.id
                                          }
                                        >
                                          <td>
                                            <img
                                              src={data.pet_images?.length > 0 ? imageResponseCatalogue[data?.pet_images[data?.pet_images?.length - 1]?.filename] : DEFAULT_PRODUCT_IMAGE}
                                              width='60'
                                              height='65'
                                              alt=''
                                            />
                                          </td>
                                          <td
                                            style={{
                                              verticalAlign:
                                                'middle',
                                              width: '100px',
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: '200px',
                                                overflow:
                                                  'hidden',
                                                textOverflow:
                                                  'ellipsis',
                                                whiteSpace:
                                                  'nowrap',
                                              }}
                                            >
                                              {
                                                data?.name
                                              }
                                            </div>
                                            {/* {data?.type} */}
                                          </td>
                                          <td
                                            style={{
                                              verticalAlign:
                                                'middle',
                                            }}
                                          >
                                            {
                                              data?.type
                                            }
                                          </td>
                                          <td
                                            style={{
                                              verticalAlign:
                                                'middle',
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: '150px',
                                                overflow:
                                                  'hidden',
                                                textOverflow:
                                                  'ellipsis',
                                                whiteSpace:
                                                  'nowrap',
                                              }}
                                            >
                                              {
                                                data?.manufacturer === null || data?.manufacturer === "" ? '-' : data.manufacturer
                                              }
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              verticalAlign:
                                                'middle',
                                            }}
                                            className='action_icon'
                                          >
                                            <Button
                                              tag={
                                                Link
                                              }
                                              to={`/productCatalogue/details/${data.id}`}
                                              className='action_btn mr-4'
                                            >
                                              Full
                                              Details
                                            </Button>
                                            <span
                                              onClick={() => {
                                                setShowImage("")
                                                setImage("")
                                                setAddProduct(false);
                                                setDetails(data);
                                                setIsEdit(true);
                                                setShowImage(data?.pet_images[data?.pet_images?.length - 1]?.filename);
                                              }}
                                            >
                                              <i className='fa fa-pen ml-3 mr-4'></i>
                                            </span>
                                            <span
                                              style={{
                                                cursor: 'pointer',
                                              }}
                                            >
                                              <i
                                                className='fa fa-trash ml-3'
                                                // onClick={deleteItem.bind(this, data.id)}
                                                onClick={() => {
                                                  confirmDeleteModal();
                                                  setDeleteProductId(
                                                    data.id
                                                  );
                                                }}
                                              ></i>
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </Fragment>
                              {/* {
                                    !loading && productCatalogueList && productCatalogueList.length < 1 &&
                                      <React.Fragment>
                                        <tr>
                                          <td colSpan="5" style={{ verticalAlign: "middle" }}>
                                            <h4 className="text-center" style={{ color: "#f5813c" }}>Product Not Found.</h4>
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                  } */}
                            </tbody>
                          </Table>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                  {/* pagination */}
                  {
                    search === "" && addProduct ?
                      <React.Fragment>
                        <div>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            // breakLabel={"..."}
                            // breakClassName={"break-me"}
                            pageCount={cataloguePageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClickCatalogue}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </React.Fragment> : null
                  }

                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* {userProductLoading ? (
                      <h2 className='text-center text-muted mt-5'>
                        Loading......
                      </h2>
                    ) : ( */}
                  <React.Fragment>
                    <React.Fragment>
                      <div>
                        <Row>
                          <Col>
                            <div className='search__header user__product__search__header'>
                              <div className='search__header__text'>
                                <span>
                                  Search
                                </span>
                              </div>
                              <div className='search__header__input'>
                                <Form>
                                  <Input
                                    id='name'
                                    name='name'
                                    placeholder='Name'
                                    type='search'
                                    value={
                                      search
                                    }
                                    onChange={(e) => {
                                      setSearch(e.target.value)
                                    }}
                                  />
                                </Form>
                              </div>
                            </div>

                            <Table
                              borderless
                              responsive
                              className='mt-3 table-text-left'
                            >
                              <thead className='table_head'>
                                <tr>
                                  <th>
                                    Image
                                  </th>
                                  <th><span className="sort-by" onClick={(e) => {
                                    setSortValue("name");
                                    tableSortingId();
                                  }}>
                                    Name</span>
                                  </th>
                                  <th><span className="sort-by" onClick={(e) => {
                                    setSortValue("type");
                                    tableSortingId();
                                  }}>
                                    Type</span>
                                  </th>
                                  <th><span className="sort-by" onClick={(e) => {
                                    setSortValue("manufacturer");
                                    tableSortingId();
                                  }}>
                                    Manufacturer</span>
                                  </th>
                                  <th><span className="sort-by" onClick={(e) => {
                                    setSortValue("userId");
                                    tableSortingId();
                                  }}>
                                    User
                                    ID</span>
                                  </th>
                                  <th><span className="sort-by" onClick={(e) => {
                                    setSortValue("userName");
                                    tableSortingId();
                                  }}>
                                    User
                                    Name</span>
                                  </th>
                                  <th className='user__product__action'>
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody className='bg-white text-left user_product'>
                                <Fragment>
                                  <React.Fragment>
                                    {userProductList &&
                                      userProductList.length >
                                      0 &&
                                      userProductList.map(
                                        (
                                          data
                                        ) => {
                                          return (
                                            <tr
                                              key={
                                                data.id
                                              }
                                            >
                                              <td>
                                                <img
                                                  src={data.pet_images?.length > 0 ? imageResponseUser[data?.pet_images[data.pet_images?.length - 1]?.filename] : DEFAULT_PRODUCT_IMAGE}
                                                  width='60'
                                                  height='65'
                                                  alt=''
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  verticalAlign:
                                                    'middle',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    width: '200px',
                                                    overflow:
                                                      'hidden',
                                                    textOverflow:
                                                      'ellipsis',
                                                    whiteSpace:
                                                      'nowrap',
                                                  }}
                                                >
                                                  {
                                                    data?.name
                                                  }
                                                </div>
                                              </td>
                                              <td
                                                style={{
                                                  verticalAlign:
                                                    'middle',
                                                }}
                                              >
                                                {
                                                  data?.type
                                                }
                                              </td>
                                              <td
                                                style={{
                                                  verticalAlign:
                                                    'middle',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    width: '150px',
                                                    overflow:
                                                      'hidden',
                                                    textOverflow:
                                                      'ellipsis',
                                                    whiteSpace:
                                                      'nowrap',
                                                  }}
                                                >
                                                  {
                                                    data?.manufacturer === null || data?.manufacturer === "" ? '-' : data.manufacturer
                                                  }
                                                </div>
                                              </td>
                                              <td
                                                style={{
                                                  verticalAlign:
                                                    'middle',
                                                }}
                                              >
                                                {data?.created_by_user ===
                                                  null
                                                  ? `-`
                                                  : data
                                                    ?.created_by_user
                                                    ?.id}
                                              </td>
                                              <td
                                                style={{
                                                  verticalAlign:
                                                    'middle',
                                                }}
                                              >
                                                {data?.created_by_user ===
                                                  null
                                                  ? `-`
                                                  : data
                                                    ?.created_by_user
                                                    ?.first_name}
                                                {data?.created_by_user ===
                                                  !null &&
                                                  data
                                                    ?.created_by_user
                                                    ?.last_name}
                                              </td>
                                              <td
                                                style={{
                                                  verticalAlign:
                                                    'middle',
                                                }}
                                                className='action_icon'
                                              >
                                                <Button
                                                  tag={
                                                    Link
                                                  }
                                                  to={`/userProduct/details/${data.id}`}
                                                  className='action_btn'
                                                >
                                                  Full
                                                  Details
                                                </Button>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </React.Fragment>
                                  {/* {
                                                !userProductLoading && userProductList && userProductList.length < 1 &&
                                                <React.Fragment>
                                                  <tr>
                                                    <td colSpan="7" style={{ verticalAlign: "middle" }}>
                                                      <h4 className="text-center" style={{ color: "#f5813c" }}>Product Not Found..</h4>
                                                    </td>
                                                  </tr>
                                                </React.Fragment>
                                              } */}
                                </Fragment>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                        {
                          search === "" &&
                          <React.Fragment>
                            <div>
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                // breakLabel={"..."}
                                // breakClassName={"break-me"}
                                pageCount={userPageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClickUser}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          </React.Fragment>
                        }
                      </div>
                    </React.Fragment>
                  </React.Fragment>
                  {/* )} */}
                </React.Fragment>
              )}
            </React.Fragment>
            {/* )} */}
          </section>
        </Container>
        <React.Fragment>
          <Modal
            isOpen={editModal}
            toggle={confirmDeleteModal}
            className='modal-dialog-centered delete_model modal-sm'
          >
            <ModalBody>
              <div className='text-center mt-3 text-orange'>
                <h3>Are you sure ?</h3>
              </div>
              <div
                className='pt-3 pl-2 pr-3'
                style={{
                  marginTop: '25px',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <Button
                  className='bg-btn-orange modal_send_invite'
                  style={{ border: 'none', outline: 'none' }}
                  onClick={() => {
                    deleteItem();
                  }}
                >
                  DELETE
                </Button>
                <Button
                  color='secondary'
                  className='modal_cancel'
                  onClick={() => {
                    confirmDeleteModal();
                    setDeleteProductId();
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </React.Fragment>
      </Fragment>
    </React.Fragment>
  );
};

export default Product;



// where: {
//   id: { _eq: details?.id },
// },
// set: {
//   type: submitProduct?.type,
//   barcode: submitProduct?.barcode,
//   barcode_type: submitProduct?.barcode_type,
//   name: submitProduct?.name,
//   nutrient_protein: Number(submitProduct?.nutrient_protein),
//   nutrient_ash: submitProduct?.nutrient_ash,
//   manufacturer: submitProduct?.manufacturer,
//   specie: submitProduct?.specie,
//   serving_calories: submitProduct?.serving_calories,
//   ingredients: submitProduct?.ingredients,
//   product_tags: submitProduct?.product_tags,
//   serving_unit: submitProduct?.serving_unit,
//   description: submitProduct?.description,
//   serving_total: Number(submitProduct?.serving_total),
//   nutrient_fat: Number(submitProduct?.nutrient_fat),
//   nutrient_fiber: Number(submitProduct?.nutrient_fiber),
//   nutrient_moisture: Number(submitProduct?.nutrient_moisture),
// },