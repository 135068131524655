import React, { Fragment, useEffect, useState, useRef } from 'react';
import SideBar from '../../components/sidebar/SideBar';
import Topbar from '../../components/content/Topbar';
import {
  Button,
  Alert,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Table,
} from 'reactstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';
import QRCodeStyling from 'qr-code-styling';
import QRCodes from 'qrcode';
import QRCode from 'qrcode.react';
import LOGO from '../../assets/Images/logo.png';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import {
  ALL_USERS,
  GET_ACCESSORY,
  GET_ACCESSORY_FILTER,
} from '../../GraphQL/queries';
import { ADD_ACCESSORY, UPDATE_STATUS } from '../../GraphQL/mutation';
import { v4 } from 'uuid';
import './Accessories.css';
import {
  Svg,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
} from '@react-pdf/renderer';
import pic from '../../assets/untitled folder/logo.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    width: '100%',
    orientation: 'portrait',
  },
  image: {
    width: '100%',
    height: '100%',
    padding: 10,
    backgroundColor: 'white',
  },
});

const qrCodeStyleBaseOptions = {
  id: 'canvas',
  width: 300,
  height: 300,
  type: 'png',
  image: LOGO,
  data: undefined,
  dotsOptions: {
    color: '#000',
    type: 'rounded',
  },
  backgroundOptions: {
    color: '#fff',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
  },
  cornersSquareOptions: {
    type: 'extra-rounded',
  },
  cornersDotOptions: {
    color: '#f5813c',
    type: 'dot',
  },
};

let Accessories = (props) => {
  const exporter = useRef(null);

  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const [newCreatedData, setNewCreateData] = useState([]);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const [editModal, setEditModal] = useState(false);
  const editToggle = () => setEditModal(!editModal);
  let [alert, setAlert] = useState(false);
  let [message, setMessage] = useState('');
  let [uuid, setUUID] = useState('');
  const [changeModal, setChangeModal] = useState(false);
  const changeStatus = () => setChangeModal(!changeModal);
  const [Type, setType] = useState('');
  const [changeType, setChangeType] = useState('');
  const [accessory, setAccessory] = useState([]);
  const [FilterData, setFilterData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [checked, setChecked] = useState(false);
  const [state, setState] = useState('');
  const [noOfGenerateIds, setnoOfGenerateIds] = useState();
  const ref = useRef(null);
  const [sortValue, setSortValue] = useState('last_scanned');
  const [sort, setSort] = useState('last_scanned');
  const [sortBy, setSortBy] = useState('desc');
  const [petName, setPetName] = useState({});
  const [userInfo, setUserInfo] = useState({});

  // find login user
  const {
    loading: loginUserLoading,
    error: loginUserError,
    data: loginUserData,
  } = useQuery(ALL_USERS);
  if (loginUserError) console.error(loginUserError);
  useEffect(() => {
    if (localStorage.getItem('data')) {
      if (loginUserData) {
        const token = JSON.parse(localStorage.getItem('data'));
        const userEmail = token.email;
        setUserInfo(
          loginUserData?.user?.filter((user) => user.email === userEmail)[0]
        );
      }
    }
  }, [loginUserData]);

  useEffect(() => {
    sort === 'pet'
      ? setPetName({ [sort.toString()]: { name: sortBy } })
      : setPetName({ [sort.toString()]: sortBy });
  }, [sortValue, sortBy]);

  // graphql query (get accessories)
  let { loading, data, refetch } = useQuery(GET_ACCESSORY, {
    variables: {
      order_by: petName,
    },
  });

  // graphql query (get accessories)
  let [
    accessories,
    { loading: filterLoding, error: filterError, data: filterData },
  ] = useLazyQuery(GET_ACCESSORY_FILTER, {
    variables: {
      where: {
        id: {
          _eq: uuid,
        },
      },
    },
  });

  //graphql mutation (add accessories)
  let [
    addAccessory,
    { loading: addAccessoryLoading, error: addAccessoryError },
  ] = useMutation(ADD_ACCESSORY);

  //graphql mutation (update status)
  let [
    updateStatus,
    { loading: updateAccessoryLoading, error: updateAccessoryError },
  ] = useMutation(UPDATE_STATUS);

  if (addAccessoryError) console.error(addAccessoryError);
  if (updateAccessoryError) console.error(updateAccessoryError);

  useEffect(() => {
    if (data && uuid === '') {
      setAccessory(data.accessories);
    }
  }, [data]);

  useEffect(() => {
    if (filterData) {
      setFilterData(filterData.accessories);
    }
  }, [filterData]);

  useEffect(() => {
    if (newCreatedData && newCreatedData?.length > 0) {
      newCreatedData?.map(async (data) => {
        if (data.type && data.id) {
          await addAccessory({
            variables: {
              id: data.id,
              type: data.type,
            },
          });
        }
        refetch();
      });
    }
  }, [newCreatedData]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
  }, [alert]);

  // useEffect(() => {
  //   if (state) {
  //     qrCodeStyle.update({
  //       data: `https://www.jellieid.com/${state}`,
  //     });
  //     qrCodeStyle.download({
  //       name: `${state}`,
  //       extension: 'png',
  //     });
  //   }
  // }, [state]);

  useEffect(() => {
    if (uuid === '') {
      refetch();
    } else {
      accessories();
    }
  }, [uuid]);
  // let generateId = async (e) => {
  //   e.preventDefault();
  //   await addAccessory({
  //     variables: {
  //       id: v4(),
  //       type: selectType
  //     }
  //   });

  //   if (addAccessoryError) console.error(addAccessoryError);

  //   alert('Accessory Added Successfully..');

  // };

  function GenerateID() {
    const id = v4().toString();
    const isCheck = accessory.find((data) => data.id === id);
    if (isCheck) {
      GenerateID();
    } else {
      return id;
    }
  }

  let array = [];
  function UUID() {
    for (let i = 0; i < noOfGenerateIds; i++) {
      // const domain = (Type === 'Pet') ? "www.jelliepet.com" : "http://www.jellieid.com"
      const generatedUUID = GenerateID();
      array.push({
        id: generatedUUID,
        type: Type,
        // domain,
        status: 'Generated',
      });
    }
    setNewCreateData(array);
  }

  async function generateIDs(e) {
    e.preventDefault();
    // check logged in user is superUser or not
    if (
      !userInfo?.user_role_associations
        ?.map((role) => role?.role_id)
        .includes(3)
    ) {
      setAlert(true);
      setMessage("You Are Not Authorize to Generated ID's");
      editToggle();
      setnoOfGenerateIds();
      setType('');
      return;
    }

    if (Type !== '') {
      await UUID();
      await editToggle();

      setnoOfGenerateIds();
      setType('');
    }
  }

  function onChangeStatus() {
    if (selected && selected?.length > 0) {
      selected.map(({ data }) => {
        updateStatus({
          variables: {
            id: data.id,
            status: changeType,
          },
        });
      });
    } else {
      setAlert(true);
      setMessage('Please Select One Accessory');
    }
    changeStatus();
  }

  function onExport() {
    exporter.current.save();
  }

  // pdf generator

  let qrData = () => {
    return `https://www.jellieid.com/021dd730-3269-4f1f-8395-b8096b869d79`;
  };

  // useEffect(() => {
  //   qrCode.append(ref.current);
  // }, [accessory]);
  const fileExt = 'png';
  // useEffect(() => {
  // const qrCodeStyle = new QRCodeStyling({
  //   id: "canvas",
  //   width: 300,
  //   height: 300,
  //   type: "png",
  //   data: `https://www.jellieid.com/${state}`,
  //   image: LOGO,
  //   dotsOptions: {
  //     color: "#000",
  //     type: "rounded"
  //   },
  //   backgroundOptions: {
  //     color: "#fff"
  //   },
  //   imageOptions: {
  //     crossOrigin: "anonymous",
  //   },
  //   cornersSquareOptions: {
  //     type: "extra-rounded"
  //   },
  //   cornersDotOptions: {
  //     color: "#f5813c",
  //     type: "dot"
  //   }
  // });
  // qrCode.append(document.getElementById("canvas"));
  // qrCode.update({
  //   data: qrData(),
  //   image: LOGO
  // });

  // const onDownloadClick = () => {
  //   qrCode.download({
  //     extension: fileExt
  //   });
  // };
  // }, []);

  let onDownloadClick = async () => {
    if (selected && selected?.length > 0) {
      for (const entry of selected) {
        const id = entry?.data?.id;

        if (id) {
          const qrCodeStyle = new QRCodeStyling({
            ...qrCodeStyleBaseOptions,
            data: `https://www.jellieid.com/${id}`,
          });

          try {
            await qrCodeStyle.download({
              name: id,
              extension: 'png',
            });

            await new Promise((resolve) => setTimeout(resolve, 250));
          } catch (e) {
            console.log(e, id);
          }
        }
      }
    }

    onExport();
  };

  // let test = () => {
  //   try {
  //     const canvas = document.getElementById("qr-gen");
  //     const pngUrl = canvas.toDataURL("image/png");
  //     console.log(pngUrl);
  //     return pngUrl
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // test();

  // const MyDoc = () => (
  //   <Document>
  //     {
  //       selected && selected.length > 0 && selected.map(({ data, imageUrl }) => {
  //         return (
  //           <Page key={data.id} size="A4" style={styles.page}>
  //             <View style={styles.section} style={styles.image}>
  //               <Text x="10" y="30">
  //                 {`http://www.jellieid.com/${data.id}`}
  //                 {/* {setState(data.id)} */}
  //               </Text>
  //               <Svg viewBox="0 0 240 240">
  //                 <div id="canvas" x="40" y="30"></div>
  //                 <Image x="40" y="80" src={imageUrl} style={{ width: '75px', height: '75px' }}>
  //                 </Image>
  //               </Svg>
  //             </View>
  //           </Page>
  //         )
  //       })
  //     }
  //   </Document>
  // );

  // sort table
  const tableSortingId = (e) => {
    if (uuid === '') {
      setSort(sortValue);
      if (sortBy === 'asc') {
        setSortBy('desc');
      } else if (sortBy === 'desc') {
        setSortBy('asc');
      }
    }
  };

  return (
    <React.Fragment>
      <div className="alert-list">
        {alert && <Alert color="info">{message}</Alert>}
      </div>
      <Fragment>
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} {...props} />

        {/* <QRCode style={{ display: "none" }}
          id="qr-gen"
          value={state}
          size={290}
          level={"H"}
          includeMargin
          imageSettings={{ src: LOGO, excavate: true }}
        /> */}
        <Container
          fluid
          style={{ margin: '0', padding: '0' }}
          className={classNames('content', { 'is-open': sidebarIsOpen })}
          toggleSidebar={toggleSidebar}
        >
          <Topbar
            toggleSidebar={toggleSidebar}
            isShowAccessoriesButton={true}
          />

          {loading ? (
            <h2 className="text-muted text-center mt-5">Loading.....</h2>
          ) : (
            <React.Fragment>
              {/* <Row>
                  <Col lg="2" className="text-right" style={{ marginTop: "30px" }}>
                    <Label style={{ fontSize: "20px" }}>Search</Label>
                  </Col>
                  <Col lg="8" style={{ marginTop: "30px" }}>
                    <FormGroup>
                      <Input type="text" value={uuid} placeholder="UUID" style={{ width: "100%" }} onChange={(e) => setUUID(e.target.value)}/>
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <div className="text-center mt-4">
                      <Input
                        type="checkbox"
                        style={{ display: "none" }}
                        id="setToggleBtn"
                      />
                      <label htmlFor="setToggleBtn" onClick={editToggle} className="addProductBtn">
                        <i className="fa fa-plus"></i>
                      </label>
                    </div>
                  </Col>
                </Row> */}
              <Row>
                <Col md="11">
                  <div className="search__header">
                    <div className="search__header__text">
                      <span>Search</span>
                    </div>
                    <div className="search__header__input">
                      <Form>
                        <Input
                          id="name"
                          name="name"
                          placeholder="UUID"
                          type="search"
                          value={uuid}
                          onChange={(e) => setUUID(e.target.value)}
                        />
                      </Form>
                    </div>
                  </div>
                </Col>
                <Col md="1" className="mt-3">
                  {userInfo?.user_role_associations
                    ?.map((role) => role?.role_id)
                    .includes(3) && (
                    <React.Fragment>
                      <div className="mt-2 plus__buttom">
                        <Input
                          type="checkbox"
                          style={{ display: 'none' }}
                          id="setToggleBtn"
                          onClick={editToggle}
                        />
                        <label htmlFor="setToggleBtn" className="addProductBtn">
                          <i className="fa fa-plus"></i>
                        </label>
                      </div>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
              <Container fluid>
                <Row>
                  <Col className="for_search mt-3 mb-2" md="12">
                    <div>
                      <span className="mr-2 text-muted">For Selected</span>
                      <Button
                        className="mr-3 bg-btn-orange"
                        onClick={() => {
                          if (selected && selected?.length > 0) {
                            changeStatus();
                          } else {
                            setAlert(true);
                            setMessage('Please Select One Accessory');
                          }
                        }}
                      >
                        Change Status
                      </Button>
                      <Button
                        className="mr-0 bg-btn-orange"
                        onClick={onDownloadClick}
                      >
                        {/* <PDFDownloadLink document={<MyDoc />} fileName="selected.pdf">
                            {({ blob, url, loading, error }) => (loading ? 'Export.' : 'Export')}
                          </PDFDownloadLink> */}
                        Export
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table
                      borderless
                      responsive
                      className="mt-3 table-text-left"
                    >
                      <thead className="table_head">
                        <tr>
                          <th style={{ verticalAlign: 'middle' }}>
                            <FormGroup check style={{ marginBottom: '30px' }}>
                              <Input
                                type="checkbox"
                                className="checkbox-round uuid_checkbox"
                                onChange={(e) => {
                                  setChecked(!checked);
                                  if (e.target.checked) {
                                    const selectedData = [];
                                    const updateList = accessory.map((data) => {
                                      // setState(data.id)
                                      return {
                                        data,
                                        // imageUrl: test()
                                      };
                                    });
                                    setSelected(updateList);
                                  }
                                  if (!e.target.checked) {
                                    // selectedData = [];
                                    setSelected([]);
                                  }
                                }}
                                style={{ borderColor: '#f5813c' }}
                              />
                            </FormGroup>
                          </th>
                          <th>
                            <span
                              className="sort-by"
                              onClick={(e) => {
                                setSortValue('id');
                                tableSortingId();
                              }}
                            >
                              UUID
                            </span>
                          </th>
                          {/* <th>Type</th> */}
                          <th>
                            <span
                              className="sort-by"
                              onClick={(e) => {
                                setSortValue('last_scanned');
                                tableSortingId();
                              }}
                            >
                              Generated
                            </span>
                          </th>
                          <th>
                            <span
                              className="sort-by"
                              onClick={(e) => {
                                setSortValue('status');
                                tableSortingId();
                              }}
                            >
                              Status
                            </span>
                          </th>
                          <th>
                            <span
                              className="sort-by"
                              onClick={(e) => {
                                setSortValue('pet');
                                tableSortingId();
                              }}
                            >
                              Pet Name
                            </span>
                          </th>
                          <th style={{ width: '180px' }}>Action</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white text-left">
                        {uuid === '' ? (
                          <Fragment>
                            {accessory &&
                              accessory.length > 0 &&
                              accessory.map((data) => {
                                return (
                                  <tr key={data.id}>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {checked ? (
                                        <FormGroup check className="mb-4">
                                          <Input
                                            type="checkbox"
                                            checked={true}
                                            className="checkbox-round checkBox"
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setSelected((prev) => [
                                                  ...prev,
                                                  { data },
                                                ]);
                                              }
                                              if (!e.target.checked) {
                                                setSelected((prev) =>
                                                  prev.filter(
                                                    (entry) =>
                                                      entry.data?.id !== data.id
                                                  )
                                                );
                                              }
                                            }}
                                          />
                                        </FormGroup>
                                      ) : (
                                        <FormGroup check className="test1 mb-4">
                                          <Input
                                            type="checkbox"
                                            className="checkbox-round checkBox"
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                // setState(data.id)
                                                // selected.push({
                                                // data,
                                                // imageUrl: test()
                                                // });
                                                setSelected((prev) => [
                                                  ...prev,
                                                  { data },
                                                ]);
                                              }
                                              if (!e.target.checked) {
                                                // let index = selected.findIndex(
                                                //   (x) => x.id === data.id
                                                // );
                                                // selected.splice(index, 1);
                                                setSelected((prev) =>
                                                  prev.filter(
                                                    (entry) =>
                                                      entry.data?.id !== data.id
                                                  )
                                                );
                                              }
                                              // setSelected([...selected]);
                                            }}
                                          />
                                        </FormGroup>
                                      )}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      <div
                                        style={{
                                          width: '400px',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        {data?.id}
                                      </div>
                                    </td>
                                    {/* <td style={{ verticalAlign: "middle" }}>{data?.type}</td> */}
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {new Date(
                                        data?.last_scanned
                                      ).toLocaleString()}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {data?.status === null ||
                                      data?.status === ''
                                        ? '-'
                                        : data?.status}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {data?.pet?.name
                                        ? data?.pet?.name
                                        : 'Not Assigned'}
                                    </td>
                                    <td
                                      style={{ verticalAlign: 'middle' }}
                                      className="action_icon"
                                    >
                                      <Button
                                        tag={Link}
                                        to={`/accessories/details/${data.id}`}
                                        className="action_btn"
                                      >
                                        Check Details
                                      </Button>
                                      {/* <span onClick={() => props.history.push(`/accessories/details/${data.id}`)}>
                                            <i className="fa fa-pen ml-3"></i>
                                          </span> */}
                                    </td>
                                  </tr>
                                );
                              })}
                            <React.Fragment>
                              {!loading &&
                                !filterLoding &&
                                accessories &&
                                accessories.length < 1 && (
                                  <React.Fragment>
                                    <tr>
                                      <td
                                        colSpan="6"
                                        style={{ verticalAlign: 'middle' }}
                                      >
                                        <h4
                                          className="text-center"
                                          style={{ color: '#f5813c' }}
                                        >
                                          Accessory Not Found..
                                        </h4>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )}
                            </React.Fragment>
                          </Fragment>
                        ) : (
                          <Fragment>
                            {FilterData &&
                              FilterData.length > 0 &&
                              FilterData.map((data) => {
                                return (
                                  <tr key={data.id}>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {checked ? (
                                        <FormGroup check className="mb-4">
                                          <Input
                                            type="checkbox"
                                            checked={true}
                                            className="checkbox-round checkBox"
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setSelected((prev) => [
                                                  ...prev,
                                                  { data },
                                                ]);
                                              }
                                              if (!e.target.checked) {
                                                // let index = selected.findIndex(
                                                //   (x) => x.id === data.id
                                                // );
                                                // selected.splice(index, 1);
                                                setSelected((prev) =>
                                                  prev.filter(
                                                    (entry) =>
                                                      entry.data?.id !== data.id
                                                  )
                                                );
                                              }
                                            }}
                                          />
                                        </FormGroup>
                                      ) : (
                                        <FormGroup check className="mb-4">
                                          <Input
                                            type="checkbox"
                                            className="checkbox-round checkBox"
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                // setState(data.id)
                                                // selected.push({
                                                //   data,
                                                //   // imageUrl: test()
                                                // });
                                                setSelected((prev) => [
                                                  ...prev,
                                                  { data },
                                                ]);
                                              }
                                              if (!e.target.checked) {
                                                // let index = selected.findIndex(
                                                //   (x) => x.id === data.id
                                                // );
                                                // selected.splice(index, 1);
                                                setSelected((prev) =>
                                                  prev.filter(
                                                    (entry) =>
                                                      entry.data?.id !== data.id
                                                  )
                                                );
                                              }
                                              // setSelected([...selected]);
                                            }}
                                          />
                                        </FormGroup>
                                      )}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      <div
                                        style={{
                                          width: '400px',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        {data?.id}
                                      </div>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {data?.type}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {data?.status === null ||
                                      data?.status === ''
                                        ? `-`
                                        : data?.status}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {data?.pet_id
                                        ? data.pet_id
                                        : `Not Assigned`}
                                    </td>
                                    <td
                                      style={{ verticalAlign: 'middle' }}
                                      className="action_icon"
                                    >
                                      <Button
                                        tag={Link}
                                        to={`/accessories/details/${data.id}`}
                                        className="action_btn"
                                      >
                                        Full Details
                                      </Button>
                                      {/* <span onClick={() => props.history.push(`/accessories/details/${data.id}`)}>
                                            <i className="fa fa-pen ml-3"></i>
                                          </span> */}
                                    </td>
                                  </tr>
                                );
                              })}
                            <React.Fragment>
                              {!loading &&
                                !filterLoding &&
                                FilterData &&
                                FilterData.length < 1 && (
                                  <React.Fragment>
                                    <tr>
                                      <td
                                        colSpan="6"
                                        style={{ verticalAlign: 'middle' }}
                                      >
                                        <h4
                                          className="text-center"
                                          style={{ color: '#f5813c' }}
                                        >
                                          Accessory Not Found..
                                        </h4>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )}
                            </React.Fragment>
                          </Fragment>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Container>
            </React.Fragment>
          )}

          <Modal
            isOpen={editModal}
            toggle={editToggle}
            className="generate_id_modal modal-dialog-centered"
          >
            <ModalBody>
              <h5 className="text-orange mb-3">GENERATE JELLIE IDs</h5>
              <Form>
                <FormGroup>
                  <Label>No. of IDs</Label>
                  <Input
                    type="number"
                    required={true}
                    value={Number(noOfGenerateIds)}
                    onChange={(e) => setnoOfGenerateIds(Number(e.target.value))}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Type</Label>
                  <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    className="form-control"
                    value={Type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="">Select Type</option>
                    {/* <option value="Tag">Tag</option> */}
                    <option value="Sticker">Sticker</option>
                    <option value="Pendant">Pendant</option>
                  </Input>
                  {/* <Input type="text" onChange={(e) => setSelectType(e.target.value)} required={true} /> */}
                </FormGroup>
                <div className="mt-4 modal_action">
                  <Button
                    className="bg-btn-orange modal_send_invite"
                    onClick={generateIDs}
                    style={{ border: 'none', outline: 'none' }}
                  >
                    GENERATE
                  </Button>{' '}
                  <Button
                    color="secondary"
                    className="modal_cancel"
                    onClick={(e) => {
                      editToggle();
                      setnoOfGenerateIds();
                      setType('');
                    }}
                  >
                    CANCEL
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={changeModal}
            toggle={changeStatus}
            className="modal-dialog-centered"
          >
            <ModalBody>
              <h5 className="text-orange my-3">Change Status</h5>
              <Form>
                <FormGroup>
                  <Label>Replace Status</Label>
                  <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    className="form-control"
                    value={changeType}
                    onChange={(e) => setChangeType(e.target.value)}
                  >
                    <option value="">Select Status</option>
                    {/* <option value="Assigned">Assigned</option> */}
                    {/* <option value="Generated">Generated</option> */}
                    <option value="Manufactured">Manufactured</option>
                  </Input>
                  {/* <Input type="text" onChange={(e) => setSelectType(e.target.value)} required={true} /> */}
                </FormGroup>
                <div className="mt-4">
                  <Button
                    className="bg-btn-orange"
                    onClick={() => onChangeStatus()}
                    style={{ border: 'none', outline: 'none' }}
                  >
                    Change Status
                  </Button>{' '}
                  <Button color="secondary" onClick={changeStatus}>
                    CANCEL
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </Container>

        <ExcelExport
          data={selected?.map((select) => select.data)}
          fileName="selectedAccessories.xlsx"
          ref={exporter}
        >
          <ExcelExportColumn field="id" title="UUID" />
          <ExcelExportColumn field="type" title="Type" />
          <ExcelExportColumn field="status" title="Status" />
          <ExcelExportColumn field="pet.name" title="Name" />
          <ExcelExportColumn field="pet.created_at" title="Created" />
        </ExcelExport>
      </Fragment>
      <img style={{ display: 'none' }} src={LOGO} />
    </React.Fragment>
  );
};

export default Accessories;
