import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button
} from "reactstrap";
import axios from "axios";
import classNames from "classnames";
import SideBar from "../../components/sidebar/SideBar";
import Topbar from "../../components/content/Topbar";
import { useParams } from "react-router";
import { GET_PRODUCT } from "../../GraphQL/queries";
import { useQuery } from "@apollo/client";
import DEFAULT_PRODUCT_IMAGE from '../../assets/Icon_256w_Product_NoImage.png';

let ProductCatalogueDetails = (props) => {
  const { match } = props
  const [imageUrl, setImageUrl] = useState("");
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const [product, setProduct] = useState({});
  const [filename, setFilename] = useState("");
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  let productId = match?.params.id;
  // graphql
  let { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: {
      id: productId
    }
  });

  if (error) console.error(error);

  useEffect(async () => {
    if (data) {
      setProduct(data.pet_product_by_pk);
      console.log('product', data.pet_product_by_pk);
      if (data && data?.pet_product_by_pk?.pet_images?.length > 0) {
        const image = data?.pet_product_by_pk?.pet_images[data?.pet_product_by_pk?.pet_images?.length - 1].filename
        setFilename(data?.pet_product_by_pk?.pet_images[data?.pet_product_by_pk?.pet_images?.length - 1].filename)
        const response = await axios.post(process.env.REACT_APP_GET_IMAGE_BASE_URL, { files: [image] })
        setImageUrl(Object.values(response?.data?.urls)[0]);
      }
    }
  }, [data]);

  return (
    <React.Fragment>
      <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
      <Container
        fluid
        style={{ margin: "0", padding: "0" }}
        className={classNames("content", { "is-open": sidebarIsOpen })}
        toggleSidebar={toggleSidebar}
      >
        <Topbar toggleSidebar={toggleSidebar} isShowButton={false} />

        {
          loading ?
            <h2 className="text-center text-muted mt-5">Loading........</h2> :
            <React.Fragment>
              <Container fluid>
                <Row>
                  <Col>
                    <Card className="mt-3 mb-5 view_product">
                      {
                        product && Object.keys(product).length > 0 ?
                          <React.Fragment>
                            <CardHeader className="bg-white text-muted view__product__header">
                              {/* <Button onClick={() => props.history.goBack()}> <i className="fa fa-arrow-left" aria-hidden="true"></i> </Button> */}
                              <h4 className="ml-2">VIEW PRODUCT</h4>
                            </CardHeader>
                            <CardBody>
                              <h3 className="product__name">{product.name}</h3>
                              <h5 className="product__cal">{
                                product?.type === "Medicine" ? `` : `+${product.serving_calories} Cal per ${product.serving_unit}`
                              }</h5>
                              <Row className="mt-4">
                                <Col
                                  md="4" sm="12"
                                  className="text-center product_detail_img"
                                >
                                  <img
                                    src={filename ? imageUrl : DEFAULT_PRODUCT_IMAGE}
                                    className="img-fluid"
                                    alt=""
                                  />
                                  {/* <div className="product_detail_img">
                                    <img src="https://5.imimg.com/data5/ES/SA/MY-2671182/pedigree-young-adult-dog-food-chicken-500x500.png" alt="" />
                                  </div> */}
                                </Col>
                                <Col md="8" className="text-muted">
                                  <div className="product__full__detail d-flex align-items-center justify-content-between">
                                    <h3>Full Details</h3>
                                  </div>
                                  <div className="p-1">
                                    <div>
                                      <div className="product_detail_row"><span className="product_detail_row_title">Manufacturer:</span> <span className="product_detail_row_description">{product.manufacturer ? product.manufacturer : `-`}</span></div>
                                      <div className="product_detail_row"><span className="product_detail_row_title">Type:</span> <span className="product_detail_row_description">{product.type ? product.type : `-`}</span></div>
                                      <div className="product_detail_row"><span className="product_detail_row_title">Specie:</span> <span className="product_detail_row_description">{product.specie ? product.specie : `-`}</span></div>
                                      <div className="product_detail_row"><span className="product_detail_row_title">Barcode:</span> <span className="product_detail_row_description">{product.barcode ? product.barcode : `-`}</span></div>
                                      {
                                        product?.type !== "Medicine" &&
                                        <React.Fragment>
                                          <div className="product_detail_row"><span className="product_detail_row_title">Protien (min):</span> <span className="product_detail_row_description">{product.nutrient_protein !== null ? product.nutrient_protein : `-`}</span></div>
                                          <div className="product_detail_row"><span className="product_detail_row_title">Fat (min): </span> <span className="product_detail_row_description">{product.nutrient_fat ? product.nutrient_fat : `-`}</span></div>
                                          <div className="product_detail_row"><span className="product_detail_row_title">Fiber (max):</span> <span className="product_detail_row_description">{product.nutrient_fiber ? product.nutrient_fiber : `-`}</span></div>
                                          <div className="product_detail_row"><span className="product_detail_row_title">Ash (est):</span> <span className="product_detail_row_description">{product.nutrient_ash ? product.nutrient_ash : `-`}</span></div>
                                          <div className="product_detail_row"><span className="product_detail_row_title">Moisture (max):</span> <span className="product_detail_row_description">{product.nutrient_moisture ? product.nutrient_moisture : `-`}</span></div>
                                        </React.Fragment>
                                      }

                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <div className="mt-3 text-muted product__ingredient">
                                <h4>Ingredients: </h4>

                                <p>{product?.type === "Medicine" ? product?.description ? product.description : '-' : product?.ingredients ? product.ingredients : '-'}</p>

                                <h4 className="mt-3">Tags: </h4>

                                <p>{product?.product_tags ? product.product_tags : '-'}</p>

                              </div>
                            </CardBody>
                          </React.Fragment> :
                          <h4 className="text-center mt-3" style={{ color: "#f5813c" }}>Something Went Wrong</h4>
                      }
                    </Card>
                  </Col>
                </Row>
              </Container>
            </React.Fragment>
        }

      </Container>
    </React.Fragment>
  );
};

export default ProductCatalogueDetails;
