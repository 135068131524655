import { gql } from "@apollo/client";


// Get all users
export const ALL_USERS = gql`
  query {
    user {
      id
      email
      first_name
      last_name
      profile_images {
        filename
      }
      user_role_associations {
        role_id
      }
    }
  }
`;


// get all users
export const GET_ALL_USERS = gql`
  query (
    $order_by: [user_order_by!]
  ) {
    user (where: {deleted: {_eq: false}}, order_by: $order_by) {
        id
        last_name
        first_name
        active
        address_line_1
        address_line_2
        auth0_id
        premium
        city
        country
        mobile
        email
        created_at
        user_role_associations {
          role_id
          user_role {
            name
          }
        }
    }
  }
`;

// (where: {email: {_iregex: $iregex}, deleted: {_eq: false}})
export const GET_SEARCH_ALL_USER = gql`
  query SearchUser ($iregex: String!) {
    user
    ( 
    where: {
_or: [
{email: {_ilike: $iregex}},
{last_name: {_ilike: $iregex}},
{first_name: {_ilike: $iregex}}
], deleted: {_eq: false}
}
  )
     {
        id
        last_name
        first_name
        active
        address_line_1
        address_line_2
        auth0_id
        city
        premium
        country
        mobile
        email
        created_at
        user_role_associations {
          role_id
          user_role {
            name
          }
        }
    }
  }
`;

export const GET_ALL_USERS_FOR_COMMUNICATION_APP_USERS = gql`
  query (
    $premium: Boolean!
  ) {
    user (where: {deleted: {_eq: false}, premium: {_eq: $premium}}) {
        id
        last_name
        first_name
        active
        address_line_1
        address_line_2
        auth0_id
        premium
        city
        country
        mobile
        email
        created_at
        user_role_associations {
          role_id
          user_role {
            name
          }
        }
    }
  }
`;


// get user details
export const GET_USER_DETAILS = gql`
    query ($id: Int!) {
        user_by_pk(id: $id){
            id
            last_name
            first_name
            premium
            profile_images {
              filename
              src
            }
            created_at
            user_role_associations {
                role_id
                user_role {
                  name
                }
              }
            email
            active
            auth0_id
            mobile
            phone
            country
            state
            city
            zip
            address_line_1
            address_line_2
            images_created(where: {
              user: {
                id: {
                  _eq: $id
                }
              }
            }) {
              src
              name
              latitude
              created_by
              created_at
              longitude
              id
              tag
            }
        }
    }
`;



// get accessory
export const GET_ACCESSORY = gql`
    query (
      $order_by: [accessories_order_by!]
    ) {
        accessories (order_by: $order_by) {
          last_scanned
       name
       pet {
         created_at
         name
         qr_id
       }
       status
       pet_id
       id
       type
       custom_id
       associated_on
       accessory_scans {
         scanned_user {
           id
           last_name
           first_name
         }
         created_at
         address
         latitude
         longitude
        }
    }
    }
`;

export const GET_ACCESSORY_FILTER = gql`
    query ($where: accessories_bool_exp) {
        accessories (where: $where) {
       name
       last_scanned
       pet {
         created_at
         name
         qr_id
       }
       pet_id
       id
       status
       type
       custom_id
       associated_on
       accessory_scans {
         scanned_user {
           id
           last_name
           first_name
         }
         created_at
         address
         latitude
         longitude
        }
    }
    }
`;

// get accessory by id
export const GET_ACCESSORY_BY_ID = gql`
  query ($id: uuid!) {
    accessories_by_pk (id: $id) {
      id
      pet_id
      type
      status
      pet {
        gender
        specie
        name
        created_at
      }
    }
  }
`;

// get people and status
export const GET_PEOPLE_AND_STATUS = gql`
    query ($id: ID!) {
        pet_by_pk(id: $id) {
      pet_hosts {
        accepted
        created_at
        expiry
        host_user_id
        host_user {
          email
          first_name
          id
          last_name
          mobile
        }
        rejected
      }
      pet_owners {
        accepted
        created_at
        expiry
        owner_user_id
        owner_user {
          email
          first_name
          id
          last_name
          mobile
        }
        rejected
    }
      pet_co_owners {
        accepted
        created_at
        expiry
        co_owner_user_id
        co_owned_user {
          email
          first_name
          id
          last_name
          mobile
        }
        rejected
      }
      }
    }
`;


// get all products
export const GET_PRUODUCTS = gql`
    query productList {
        pet_product (where: {_eq: false}) {
            id
      barcode
      barcode_type
      caloric_content
      caloric_serving
      product_id
      _product_id
      image_id
      ingredients
      manufacturer_details {
        address
        created_at
        created_by
        id
        name
      }
      nutrient_ash
      nutrient_fat
      nutrient_fiber
      name
      manufacturer_id
      nutrient_moisture
      nutrient_other
      nutrient_protein
      product_tags
      product_qr
      specie
      serving_total
      serving_size
      total_servings
      type
      validated
      manufacturer
        }
    }
`;

// get product catalogue list
export const GET_PRODUCT_CATALOGUE_LIST = gql`
  query (
    $offset: Int!
    $order_by: [pet_product_order_by!]
  ) {
    pet_product(where: {admin_created: {_eq: true}, deleted: {_eq: false}}, limit: 20, offset: $offset, order_by: $order_by) {
      id
      description
      barcode
      barcode_type
      caloric_content
      serving_unit
      product_id
      _product_id
      image_id
      ingredients
      manufacturer_details {
        address
        created_at
        created_by
        id
        name
      }
      nutrient_ash
      nutrient_fat
      nutrient_fiber
      pet_images {
        type
        filename
      }
      name
      manufacturer_id
      manufacturer_details {
        address
        created_at
        created_by
        id
        name
      }
      nutrient_moisture
      nutrient_other
      nutrient_protein
      product_tags
      product_qr
      specie
      serving_total
      serving_size
      serving_calories
      type
      validated
      manufacturer
    }
  }
`;

// get product catalogue list legth
export const GET_PRODUCT_CATALOGUE_LENGTH = gql`
query {
  pet_product(where: {admin_created: {_eq: true}, deleted: {_eq: false}}) {
    id
  }
}
`;

export const GET_SEARCH_PRODUCT_CATALOGUE_LIST = gql`
  query ($iregex: String!){
    pet_product(where: {name: {_iregex: $iregex}, admin_created: {_eq: true}, deleted: {_eq: false}}) {
      id
      barcode
      barcode_type
      caloric_content
      serving_unit
      product_id
      _product_id
      image_id
      ingredients
      manufacturer_details {
        address
        created_at
        created_by
        id
        name
      }
      nutrient_ash
      nutrient_fat
      nutrient_fiber
      pet_images {
        filename
      }
      name
      manufacturer_id
      manufacturer_details {
        address
        created_at
        created_by
        id
        name
        # system_defined
      }
      nutrient_moisture
      nutrient_other
      nutrient_protein
      product_tags
      product_qr
      specie
      serving_calories
      serving_size
      total_servings
      type
      serving_total
      validated
      manufacturer
      description
    }
  }
`;

// get user product list
export const GET_USER_PRODUCT_LIST = gql`
  query (
    $offset: Int!
    $order_by: [pet_product_order_by!]
  ) {
    pet_product(where: {admin_created: {_eq: false}, deleted: {_eq: false}}, limit: 20, offset: $offset, order_by: $order_by) {
      id
      barcode
      barcode_type
      caloric_content
      serving_unit
      product_id
      _product_id
      image_id
      ingredients
      manufacturer_details {
      address
      created_at
      created_by
        id
        name
      }
      nutrient_ash
      nutrient_fat
      nutrient_fiber
      name
      manufacturer_id
      nutrient_moisture
      nutrient_other
      nutrient_protein
      product_tags
      product_qr
      specie
      serving_total
      serving_size
      serving_calories
      type
      pet_images  {
      filename
      }
      validated
      description
      manufacturer
    	created_by_user{
        id
        first_name
        last_name
      }
    }
  }
`;

// get user product list
export const GET_USER_PRODUCT_LENGTH = gql`
  query {
    pet_product(where: {admin_created: {_eq: false}, deleted: {_eq: false}}) {
      id
    }
  }
`;

export const GET_SEARCH_USER_PRODUCT_LIST = gql`
query searchUserProduct($iregex: String!){
    pet_product(where: {admin_created: {_eq: false}, name: {_iregex: $iregex}, deleted: {_eq: false}}) {
      id
      barcode
      barcode_type
      caloric_content
      pet_images  {
      filename
      }
      serving_unit
      product_id
      _product_id
      image_id
      ingredients
      manufacturer_details {
      address
      created_at
      created_by
        id
        name
      }
      nutrient_ash
      nutrient_fat
      nutrient_fiber
      name
      manufacturer_id
      nutrient_moisture
      nutrient_other
      nutrient_protein
      product_tags
      product_qr
      specie
      serving_total
      serving_size
      serving_calories
      type
      validated
      description
      manufacturer
    	created_by_user{
        id
        first_name
        last_name
      }
    }
  }
`;

// get product
export const GET_PRODUCT = gql`
  query ($id: Int!) {
    pet_product_by_pk (id: $id) {
      serving_unit
      serving_size
      manufacturer
      type
      serving_calories
      name
      specie
      barcode
      nutrient_protein
      nutrient_fiber
      nutrient_fat
      nutrient_ash
      nutrient_moisture
      ingredients
      product_tags
      product_image
      image_id
      image_url
      pet_images {
        product_id
        filename
      }
    }
  }
`;

// get orders
export const GET_ORDERS = gql`
  query (
    $order_by: [orders_order_by!]
  ) {
    orders (order_by: $order_by) {
    charge_price
    charge_shipping
    charge_taxes
    checkout_method
    created_at
    created_by
    engraving_line_1_height
    engraving_line_1_text
    engraving_line_2_height
    engraving_line_2_space
    engraving_line_2_text
    engraving_line_3_height
    engraving_line_3_space
    engraving_line_3_text
    id
    order_status {
      name
      id
    }
    quantity
    ship_to_city
    ship_to_country
    ship_to_name
    ship_to_state
    ship_to_street1
    ship_to_street2
    ship_to_zip
    shipping_company
    shipping_tracking_no
    shipping_date
    type
    updated_at
    updated_by
  }
  }
`;

export const GET_ORDER = gql`
  query ($id: Int!) {
    orders_by_pk(id: $id) {
    charge_price
    charge_shipping
    charge_taxes
    checkout_method
    created_at
    created_by
    engraving_line_1_height
    engraving_line_1_text
    engraving_line_2_height
    engraving_line_2_space
    engraving_line_2_text
    engraving_line_3_height
    engraving_line_3_space
    engraving_line_3_text
    id
    order_status {
      name
      id
    }
    quantity
    ship_to_city
    ship_to_country
    ship_to_name
    ship_to_state
    ship_to_street1
    ship_to_street2
    ship_to_zip
    shipping_company
    shipping_tracking_no
    shipping_date
    type
    updated_at
    updated_by
    }
  }
`;

// get portal user
export const GET_PORTAL_USER = gql`
  query (
    $order_by: [user_order_by!]
  ) {
    user (where: {user_role_associations: {role_id: {_gte: 2}}, deleted: {_eq: false}}, order_by: $order_by) {
      id
      email
      first_name
      last_name
      active
      created_at
      user_role_associations {
        role_id
        user_role {
          name
        }
      }
    }
  }
`;

// (where: {email: {_iregex: $iregex},user_role_associations: {role_id: {_gte: 2}}, deleted: {_eq: false}})
export const GET_SEARCH_PORTAL_USER = gql`
  query($iregex: String!) {
    user
    ( 
    where: {
_or: [
{email: {_ilike: $iregex}},
{last_name: {_ilike: $iregex}},
{first_name: {_ilike: $iregex}}
],user_role_associations: {role_id: {_gte: 2}}, deleted: {_eq: false}
}
  )
     {
      id
      email
      first_name
      last_name
      active
      created_at
      user_role_associations {
        role_id
        user_role {
          name
        }
      }
    }
  }
`;

// get all pets
export const GET_ALL_PET = gql`
  query Pet(
    $where: pet_bool_exp
    $order_by: [pet_order_by!]
    ) {
    pet(where: $where, order_by: $order_by) {
      id
      name
      animal_id
      specie
      breed
      activated
      created_at
    }
  }
`;

// get all pets
export const GET_ALL_PET_FOR_ACCESS = gql`
  query (
    $offset: Int!
    $order_by: [pet_order_by!]
  ) {
    pet (where: {deleted: {_eq: false}}, limit: 10, offset: $offset, order_by: $order_by) {
      id
      name
      animal_id
      specie
      breed
      pet_owners {
        accepted
        start_date
        expiry
        owner_user_id
        owner_user {
          first_name
          last_name
          premium
          user_limitation {
            no_accessories
          }
        }
      }
      activated
      created_at
      accessories {
        id
      }
      pet_images (where: {type : {_eq: "PROFILE"}}) {
        filename
      }
    }
  }
`;

// (where: {name: {_iregex: $iregex}})
// get search all pets
export const GET_SEARCH_ALL_PET = gql`
  query Pet(
    $name: String
    $id: Int
  ) {
    pet
    ( 
    where: {
deleted: {_eq: false},
_or: [
{id: {_eq: $id }},
{name: {_ilike: $name}}
]
}
  )
    {
      id
      name
      animal_id
      specie
      breed
      activated
      created_at
      accessories {
        id
      }
      pet_images (where: {type : {_eq: "PROFILE"}}) {
        filename
      }
    }
  }
`;

// get a pet profile
export const GET_PET_PROFILE = gql`
  query (
    $id: Int!
  ) {
    pet_by_pk (id: $id) {
      id
      name
      specie
      breed
      deleted
      activated
      created_at
      birthdate
      gender
      premium
      weight
      sex_health
      pet_images (where: {type: {_eq: "PROFILE"}}) {
        filename
        src
        type
      }
      pet_co_owners {
        accepted
        start_date
        expiry
        co_owner_user_id
        co_owned_user {
          first_name
          last_name
        }
      }
      pet_owners {
        accepted
        start_date
        expiry
        owner_user_id
        owner_user {
          first_name
          last_name
          premium
        }
      }
      pet_hosts {
        accepted
        start_date
        expiry
        host_user_id
        host_user {
          first_name
          last_name
        }
      }
    }
  }
`;

// get pet parent profile
export const GET_PET_PARENT_PROFILE = gql`
  query ($id: Int!) {
    user_by_pk(id: $id){
      id
      first_name
      last_name
      user_limitation {
        pets_as_parent
      }
      pets_owned {
      pet {
        id
        name
        activated
        deleted
      }
    }
    }
  }
`;

// get user for communication
export const GET_USER_COMMUNICATION = gql`
  query ($role_id: Int) {
    user (where: {user_role_associations: {role_id: {_eq: $role_id}}, deleted: {_eq: false}}) {
      id
      email
      first_name
      last_name
      active
      created_at
      user_role_associations {
        role_id
        user_role {
          name
        }
      }
    }
  }
`;

export const GET_SEARCH_USER_COMMUNICATION = gql`
  query ($role_id: Int, $startTime: timestamptz, $endTime: timestamptz) {
    user (where: {deleted: {_eq: false}, user_role_associations: {role_id: {_eq: $role_id}}, created_at: { _gte: $startTime, _lte: $endTime }}) {
      id
      email
      first_name
      last_name
      active
      created_at
      user_role_associations {
        role_id
        user_role {
          name
        }
      }
    }
  }
`;

// get quick exercise
export const QUICK_EXERCISE = gql`
  query (
    $order_by: [quick_exercise_order_by!]
  ) {
    quick_exercise (order_by: $order_by) {
      created_at
      created_by
      distance
      url
      duration
      calories
      serving_unit
      exercise_calories
      exercise_type
      id
      name
      serving_quantity
      updated_by
      updated_at
      type
      specie
      created_by_user {
        first_name
        last_name
      }
      updated_by_user {
        first_name
        last_name
      }
  }
  }
`;

// get quick exercise (search)
export const QUICK_EXERCISE_SEARCH = gql`
  query ($iregex: String!) {
    quick_exercise (where: {name: {_iregex: $iregex}}) {
      created_at
      created_by
      distance
      duration
      url
      calories
      serving_unit
      exercise_calories
      exercise_type
      id
      name
      serving_quantity
      updated_by
      updated_at
      type
      specie
      created_by_user {
        first_name
        last_name
      }
      updated_by_user {
        first_name
        last_name
      }
  }
  }
`;

// get quick exercise by id
export const GET_QUICK_EXERCISE_PK = gql`
  query ($id: Int!) {
    quick_exercise_by_pk (id: $id) {
      created_at
      created_by
      distance
      duration
      energy_per_unit
      exercise_calories
      exercise_type
      id
      name
      serving_quantity
      updated_by
      type
      specie
      updated_at
      created_by_user {
        first_name
        last_name
      }
      updated_by_user {
        first_name
        last_name
      }
    }
}
`;


// get total active user (Active users)
export const GET_TOTAL_ACTIVE_USER = gql`
query MyQuery {
  user_aggregate(where: {active: {_eq: true}, deleted: {_eq: false}}) {
    aggregate {
      count
    }
  }
}
`;

// get current active users (Current active users)
export const GET_CURRENT_ACTIVE_USERS = gql`
  query (
    $date: date!
  ) {
    user_activity_aggregate(where: {date: {_eq: $date}}) {
    aggregate {
      count
    }
  }
  }
`;

// get active users data 
// export const GET_ACTIVE_USER_DATA = gql`
// query MyQuery {
//   user_dashboard {
//     date
//     daily_active_users
//     weekly_active_users
//     monthly_active_users
//   }
// }
// `;

// get user data in graph
export const GET_ACTIVE_USER_DATA = gql`
  query (
    $gte: date!
    $lte: date!
  ) {
  user_dashboard (where: {_and: [{date: {_gte: $gte}}, {date: {_lte: $lte}}]}) {
    date
    daily_active_users
    monthly_active_users
    weekly_active_users
  }
}
`;

// get weekly and monthly users (Weekly and Monthly users)
export const GET_WM_USER_DATA = gql`
  query {
    user_dashboard(limit: 1, order_by: {date: desc}) {
    monthly_active_users
    weekly_active_users
  }
}
`;
