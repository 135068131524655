import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Alert, Badge, Button, Col, Container, Row, Table, Modal, ModalBody, Form, FormGroup, Label, Input } from "reactstrap";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { GET_ALL_USERS, GET_SEARCH_ALL_USER, GET_PORTAL_USER, GET_SEARCH_PORTAL_USER, GET_ALL_PET, GET_SEARCH_ALL_PET, ALL_USERS } from "../../GraphQL/queries";
import { DELETE_USER, DELETE_USER_ROLE_ACCOCIATION, UPDATE_USER, UPDATE_USER_BASIC_PREMIUM, UPDATE_USER_STATUS } from "../../GraphQL/mutation";
import classNames from "classnames";
import SideBar from "../../components/sidebar/SideBar";
import Topbar from "../../components/content/Topbar";
import axios from "axios";
import './User.css';
import { inviteAdminTemplate, roleUpdateAdmin, roleUpdateSuper } from "./emailTemplates.js";
import Spinner from "../../assets/spinner.gif";

let User = () => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  let [alert, setAlert] = useState(false);
  let [message, setMessage] = useState('');
  let [activeTab, setActiveTab] = useState('1');
  let [selectedId, setSelectedId] = useState(0);
  let [search, setSearch] = useState('');
  const [active, setActive] = useState(true);
  const [userData, setUserData] = useState({});
  let [userId, setUserId] = useState('');
  const [allPetsData, setAllPetsData] = useState([]);
  const [sortValue, setSortValue] = useState("created_at");
  const [sort, setSort] = useState("created_at");
  const [sortBy, setSortBy] = useState("desc");
  const [defaultRoll, setDefaultRoll] = useState();
  const [user, setUser] = useState("");
  const [selectedBP, setSelectedBP] = useState("");

  const [petSortValue, setPetSortValue] = useState("created_at");
  const [petSort, setPetSort] = useState("created_at");
  const [petSortBy, setPetSortBy] = useState("desc");
  const [userInfo, setUserInfo] = useState({});

  // find login user
  const { loading: loginUserLoading, error: loginUserError, data: loginUserData } = useQuery(ALL_USERS);
  if (loginUserError) console.error(loginUserError);
  useEffect(() => {
    if (localStorage.getItem('data')) {
      if (loginUserData) {
        const token = JSON.parse(localStorage.getItem('data'));
        const userEmail = token.email
        setUserInfo(loginUserData?.user?.filter(user => user.email === userEmail)[0]);
      }
    }
  }, [loginUserData]);

  // delete model
  const [deleteUserId, setDeleteUserId] = useState();
  const [editDeleteModal, setEditDeleteModal] = useState(false);
  const confirmDeleteModal = () => {
    setEditDeleteModal(!editDeleteModal);
  };

  // modal
  const [inviteAdminModal, setInviteAdminModal] = useState(false);
  const inviteAdminToggle = () => setInviteAdminModal(!inviteAdminModal);

  const [editModal, setEditModal] = useState(false);
  const editToggle = () => {
    setEditModal(!editModal);
    // setUserData({});
  };


  // delete user role association
  const [deleteRole, { error: deleteRoleError }] = useMutation(DELETE_USER_ROLE_ACCOCIATION);

  // graphql query (get all pets).
  const { loading: petLoading, error: petError, data: petData, refetch: petRefetch } = useQuery(GET_ALL_PET, {
    variables: {
      order_by: {
        [petSort.toString()]: petSortBy
      },
      where: {
        deleted: { _eq: false }
      }
    }
  });
  if (petError) console.error(petError);

  // graphql query (get search all pet).
  const { loading: searchPetLoading, error: searchPetError, data: searchPetData } = useQuery(GET_SEARCH_ALL_PET, {
    variables: {
      name: `%${search}%`,
      id: (Number.isInteger(parseInt(search))) ? search : 0
    }
  });
  if (searchPetError) console.error(searchPetError);

  // graphql query (get all users)
  let { loading, error, data, refetch: getAllTheUser } = useQuery(GET_ALL_USERS, {
    variables: {
      order_by: {
        [sort.toString()]: sortBy
      }
    }
  });
  if (error) console.error(error);

  let [users, setUsers] = useState([]);
  useEffect(() => {
    if (data && search === '') {
      setUsers(data.user);
    }
  }, [data, search]);

  let { loading: loading2, error: error2, data: data2, refetch: refetch2 } = useQuery(GET_SEARCH_ALL_USER, {
    variables: {
      iregex: `%${search}%`
    }
  });

  if (error2) console.error(error2);

  useEffect(() => {
    if (data2) {
      if (search !== "") {
        setUsers(data2.user);
      }
    }
  }, [data2, search]);

  // graphql query (get portal user)
  let { loading: portalUserLoading, error: portalUserError, data: portalUserData, refetch: getAllThePortalUser } = useQuery(GET_PORTAL_USER, {
    variables: {
      order_by: {
        [sort.toString()]: sortBy
      }
    }
  });
  let { loading: portalUserLoading2, error: portalUserError2, data: portalSearchUserData, refetch: portalSearchUserFetch } = useQuery(GET_SEARCH_PORTAL_USER, {
    variables: {
      iregex: `%${search}%`
    }
  });

  if (portalUserError) console.error(portalUserError);

  let [portalUsers, setPortalUsers] = useState([]);

  useEffect(() => {
    if (portalUserData && search === '') {
      setPortalUsers(portalUserData.user);
    } else {
      if (search !== '') {
        setPortalUsers(portalSearchUserData?.user ? portalSearchUserData?.user : [])
      }
    }
  }, [portalUserData, portalSearchUserData]);

  // useEffect(() => {
  //   if (search !== '') {
  //     if (activeTab === '1') {
  //       user()
  //     } else if (activeTab === '2') {

  //     }
  //   }
  // }, [search]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false)
      }, 3000)
    }
  }, [alert])

  // on load refetch api
  useEffect(() => {
    petRefetch();
    getAllTheUser()
    getAllThePortalUser()
  }, []);

  // graphql mutation update active/deactive user
  const [updateUserStatus, { loading: updateUserStatusLoading, error: updateUserStatusError }] = useMutation(UPDATE_USER_STATUS);
  // graphql mutation update user basic/premium
  const [updateUserBP, { error: updateUserBPError }] = useMutation(UPDATE_USER_BASIC_PREMIUM);
  // graphql mutation (delete user)
  const [deleteUser, { loading: deleteUserLoading, error: deleteUserError }] = useMutation(DELETE_USER);
  const [updateUser, { loading: updateUserLoading, error: updateUserError }] = useMutation(UPDATE_USER);

  let userDelete = async () => {

    // check logged in user is superUser or not
    if (!userInfo?.user_role_associations?.map((role) => role?.role_id).includes(3)) {
      setAlert(true)
      setMessage("You Are Not Authorize to Delete User")
      confirmDeleteModal();
      setDeleteUserId();
      return;
    }

    await deleteUser({
      variables: {
        id: deleteUserId
      }
    });

    // if (deleteUserError) console.error(deleteUserError);
    setAlert(true)
    setMessage('User Deleted Successfully');
    // if (activeTab === '1') {
    getAllThePortalUser()
    portalSearchUserFetch()
    // } else {
    getAllTheUser()
    refetch2()
    // }
    confirmDeleteModal();
    setDeleteUserId();
  }

  let editRole = async () => {

    if (user === "portal") {

      if (selectedId !== 0) {


        // check logged in user is superUser or not
        if (!userInfo?.user_role_associations?.map((role) => role?.role_id).includes(3)) {
          editToggle();
          setAlert(true)
          setMessage("You Are Not Authorize to Change User Role")
          setUser("")
          setSelectedBP("")
          setSelectedId(0);
          setUserData({});
          return;
        }

        // delete previous role association
        userData && userData?.user_role_associations?.length > 0 && userData?.user_role_associations?.map(async (data) => {
          await deleteRole({
            variables: {
              userId: userData?.id,
              roleId: Number(data?.role_id)
            }
          });
        })

        const chageRole = await updateUser({
          variables: {
            user_id: userData?.id,
            role_id: Number(selectedId)
          }
        });

        // update role send email

        if (chageRole?.data?.insert_user_role_association_one?.role_id === Number(selectedId)) {
          let dataUrl = process.env.REACT_APP_EMAIL_BASE_URL;
          let response = await axios.post(dataUrl, {
            recipients: [userData?.email, 'email_log@jelliepets.com'],
            subject: 'Your role has been updated for jellie app',
            body: Number(selectedId) === 2 ? roleUpdateAdmin() : roleUpdateSuper()
          });
          setAlert(true);
          setMessage(response.data.success);
          console.error(error);
        }


        editToggle();

        setAlert(true)
        setMessage('User Role Updated Successfully');

      };

      await setActive(userData?.active);

      if (selectedId === 0) {
        await updateUserStatus({
          variables: {
            id: userData?.id,
            active: active
          }
        });

        if (updateUserError) console.error(updateUserError);
        if (updateUserStatusError) console.error(updateUserStatusError);

        setAlert(true);
        setMessage("User Status Updated Successfully...");

        editToggle();
        // if (updateUserError) console.error(updateUserError);

        // window.location.reload();
        // if (activeTab === '1') {
        // getAllThePortalUser()
        // } else {
        // getAllTheUser()
        // }
      }
    }

    if (user === "app") {

      if (selectedBP !== "") {
        await updateUserBP({
          variables: {
            id: userData?.id,
            premium: selectedBP === "Premium" ? true : false
          }
        });

        setAlert(true)
        setMessage('User Role Updated Successfully');
      }




      if (selectedBP === "") {
        await updateUserStatus({
          variables: {
            id: userData?.id,
            active: active
          }
        });

        setAlert(true);
        setMessage("User Status Updated Successfully...");


      }


      if (updateUserBPError) console.error(updateUserBPError);
      if (updateUserStatusError) console.error(updateUserStatusError);

      editToggle();

    }

    getAllThePortalUser()
    portalSearchUserFetch()
    getAllTheUser()
    refetch2()
    setSelectedId(0);
    setUserData({});
    setUser("")
    setSelectedBP("")

  }

  // get all pets data
  useEffect(() => {
    if (search === "") {
      if (petData) {
        setAllPetsData(petData?.pet);
      }
    } else
      if (search !== "") {
        if (searchPetData) {
          setAllPetsData(searchPetData?.pet);
        }
      }
  }, [petData, search, searchPetData]);



  // invite admin send email
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [superUser, setSuperUser] = useState(false);

  let sendEmailData = {
    recipients: [email, 'email_log@jelliepets.com'],
    subject: `You have an invitation to be an admin`,
    body: inviteAdminTemplate()
  };

  let sendMail = async (e) => {
    e.preventDefault();

    // check logged in user is superUser or not
    if (!userInfo?.user_role_associations?.map((role) => role?.role_id).includes(3)) {
      setAlert(true)
      setMessage("You Are Not Authorize to Invite User")
      setName("")
      setEmail("")
      setSuperUser(false)
      inviteAdminToggle();
      return;
    }

    let role = superUser === true ? 3 : 2

    // check email is exist or not
    const filterUser = data?.user?.find(user => user.email === email);

    if (!filterUser) {
      setAlert(true);
      setMessage('User Is Not Exist..');
      inviteAdminToggle();
      return;
    }

    // check user updated role is same or not
    const userRoles = filterUser?.user_role_associations?.map((rl) => rl?.role_id);
    const checkRole = userRoles.includes(Number(role));

    if (checkRole === true) {
      setAlert(true);
      setMessage(`User Role is already assign ${superUser ? "SuperUser" : "an Admin"}`);
      inviteAdminToggle();
      return;
    }


    await updateUser({
      variables: {
        user_id: filterUser?.id,
        role_id: Number(role)
      }
    });



    try {
      let dataUrl = process.env.REACT_APP_EMAIL_BASE_URL;
      let response = await axios.post(dataUrl, sendEmailData);
      setAlert(true);
      setMessage(response.data.success);
      setName("")
      setEmail("")
      setSuperUser(false)
      inviteAdminToggle();
      getAllThePortalUser()
    } catch (error) {
      console.error(error);
    }
  };


  // sort table
  const tableSortingId = (e) => {
    if (search === "") {
      setSort(sortValue);
      if (sortBy === "asc") {
        setSortBy("desc");
      } else if (sortBy === "desc") {
        setSortBy("asc");
      }
    }
  };


  const tableSortingPet = (e) => {
    if (search === "") {
      setPetSort(petSortValue);
      if (petSortBy === "asc") {
        setPetSortBy("desc");
      } else if (petSortBy === "desc") {
        setPetSortBy("asc");
      }
    }
  };


  // show default user role
  useEffect(() => {
    if (userData?.user_role_associations?.length > 0) {
      if (userData?.user_role_associations?.map(data => data.user_role?.name).includes("SUPER")) {
        setDefaultRoll(Number(3));
      } else if (userData?.user_role_associations?.map(data => data?.user_role?.name).includes("ADMIN")) {
        setDefaultRoll(Number(2));
      } else {
        setDefaultRoll(0);
      }
    } else {
      setDefaultRoll(0);
    }
  }, [userData]);

  return (
    <React.Fragment>
      <div className="alert-list">
        {alert &&
          <Alert color="info">
            {message}
          </Alert>
        }
      </div>
      <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
      <Container
        fluid
        style={{ margin: "0", padding: "0" }}
        className={classNames("content", { "is-open": sidebarIsOpen })}
        toggleSidebar={toggleSidebar}
      >
        <Topbar toggleSidebar={toggleSidebar} isShowUserButton={true} activeTab={activeTab} setActiveTab={(data) => {
          setActiveTab(data);
          setSearch('');
          setSort("created_at");
          setSortBy("desc");
          setSortValue("created_at");
          setPetSortValue("created_at");
          setPetSortBy("desc");
          setPetSort("created_at");
        }} />

        <React.Fragment>
          {
            portalUserLoading && loading ?
              <h2 className="text-center text-muted mt-5">Loading......</h2>
              :
              <Fragment>
                {
                  activeTab === '1' && (
                    <React.Fragment>
                      <Container fluid className="mt-3">
                        <section className="">
                          <Row>
                            <Col md="12">
                              <div className="invite__admin__buttom_container">
                                {/* <Button className="invite__admin__buttom" onClick={inviteAdminToggle}>INVITE ADMIN</Button> */}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="11">
                              <div className="search__header">
                                <div className="search__header__text">
                                  <span>
                                    Search
                                  </span>
                                </div>
                                <div className="search__header__input">
                                  <Form>
                                    <Input
                                      id="name"
                                      name="name"
                                      placeholder="Name or Email"
                                      type="search"
                                      value={search}
                                      onChange={(e) => setSearch(e.target.value)}
                                    />
                                  </Form>
                                </div>
                              </div>
                            </Col>
                            <Col md="1" className="mt-3">
                              {
                                userInfo?.user_role_associations?.map((role) => role?.role_id).includes(3) &&
                                <div className="mt-@ plus__buttom">
                                  <Input
                                    type="checkbox"
                                    style={{ display: "none" }}
                                    id="setToggleBtn"
                                  />
                                  <label htmlFor="setToggleBtn" onClick={inviteAdminToggle} className="addProductBtn">
                                    <i className="fa fa-plus"></i>
                                  </label>
                                </div>
                              }
                            </Col>
                          </Row>

                          {/* <Row>
                            <Col lg="2" className="text-right" style={{ marginTop: "33px" }}>
                              <Label style={{ fontSize: "20px" }}>Search</Label>
                            </Col>
                            <Col lg="8" style={{ marginTop: "30px" }}>
                              <FormGroup>
                                <Input type="search" placeholder="Name or Email" style={{ width: "100%" }} value={search} onChange={(e) => setSearch(e.target.value)} />
                              </FormGroup>
                            </Col>

                          </Row> */}
                        </section>
                        <section className="mt-3">
                          <Row>
                            <Col>
                              <Table responsive borderless className="text-left">
                                <thead className="table_head">
                                  <tr>
                                    <th><span className="sort-by" onClick={(e) => {
                                      setSortValue("first_name");
                                      tableSortingId();
                                    }}>Username</span></th>
                                    <th><span className="sort-by" onClick={(e) => {
                                      setSortValue("email");
                                      tableSortingId();
                                    }}>Email</span></th>
                                    <th className="text-center">Role</th>
                                    <th>Status</th>
                                    {/* <th>Type</th> */}
                                    <th><span className="sort-by" onClick={(e) => {
                                      setSortValue("created_at");
                                      tableSortingId();
                                    }}>Created On</span></th>
                                    <th>Action</th>
                                  </tr>
                                </thead>

                                <tbody className="table__body bg-white">
                                  <Fragment>
                                    {
                                      portalUsers && portalUsers.length > 0 && portalUsers.map((user) => {
                                        const userData = user.user_role_associations.map((abs, index) => {
                                          if (abs.user_role.name === 'SUPER') {
                                            return abs.user_role.name
                                          } else if (abs.user_role.name === 'ADMIN') {
                                            return abs.user_role.name
                                          }
                                        })
                                        return (
                                          <tr key={user.id}>
                                            <td>
                                              <div style={{ width: "150px", textOverflow: "ellipsis", overflow: "hidden" }}>
                                                {
                                                  user?.first_name !== null &&
                                                  <span>{user.first_name}</span>
                                                }{" "}
                                                {
                                                  user?.last_name !== null &&
                                                  <span>{user.last_name}</span>
                                                }
                                                {
                                                  user?.first_name === null && user?.last_name === null && `-`
                                                }
                                              </div>
                                            </td>
                                            <td><div style={{ width: "230px", textOverflow: "ellipsis", overflow: "hidden" }}>{user.email}</div></td>
                                            <td style={{ textAlign: "center" }}>

                                              <p className="role">
                                                {
                                                  userData.includes('SUPER') ? 'Super' : 'Admin'
                                                }
                                              </p>
                                            </td>
                                            <td><span className={`${user?.active === true ? `user_status user_active` : `user_status user_inactive`}`} pill>{
                                              `${user?.active === true ? `Active` : `Inactive`}`
                                            }</span></td>
                                            {/* <td>
                                                      <span>
                                                        {user?.user_role_associations.length < 1 ? `Basic` : user.user_role_associations.map((abs) => abs.user_role.name)}
                                                      </span>
                                                    </td> */}
                                            <td>
                                              {new Date(user?.created_at).toLocaleDateString()}
                                            </td>
                                            <td className="action_icon">
                                              <Link to={`/user/profile/portal/${user.id}`}>
                                                <i className="fa fa-eye mr-2"></i>
                                              </Link>
                                              {
                                                userInfo?.user_role_associations?.map((role) => role?.role_id).includes(3) &&
                                                <React.Fragment>
                                                  <i className="fa fa-pen ml-3 mr-2" onClick={() => {
                                                    editToggle()
                                                    setUser("portal")
                                                    setUserData(user);
                                                  }}></i>
                                                  <span>
                                                    <i className="fa fa-trash ml-3" onClick={() => {
                                                      // userDelete.bind(this, user.id)
                                                      confirmDeleteModal();
                                                      setDeleteUserId(user.id);
                                                    }}></i>
                                                  </span>
                                                </React.Fragment>
                                              }
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                    <React.Fragment>
                                      {
                                        !portalUserLoading && !portalUserLoading2 && portalUsers && portalUsers.length < 1 &&
                                        <React.Fragment>
                                          <tr>
                                            <td colSpan="6" style={{ verticalAlign: "middle" }}>
                                              <h4 className="text-center" style={{ color: "#f5813c" }}>User Not Found..</h4>
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      }
                                    </React.Fragment>
                                  </Fragment>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </section>
                      </Container>
                    </React.Fragment>
                  )}
                {activeTab === '2' && (
                  <React.Fragment>
                    <Container fluid className="mt-3">
                      <section>
                        {/* <Button className="bg-orange orange_btn" onClick={inviteAdminToggle}>INVITE ADMIN</Button> */}
                        <Row>
                          <Col md="11">
                            <div className="search__header">
                              <div className="search__header__text">
                                <span>
                                  Search
                                </span>
                              </div>
                              <div className="search__header__input">
                                <Form>
                                  <Input
                                    id="name"
                                    name="name"
                                    placeholder="Name or Email"
                                    type="search"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                  />
                                </Form>
                              </div>
                            </div>
                          </Col>
                          {/* <Col md="1" className="mt-3">
                            <div className="mt-@ plus__buttom">
                              <Input
                                type="checkbox"
                                style={{ display: "none" }}
                                id="setToggleBtn"
                              />
                              <label htmlFor="setToggleBtn" onClick={inviteAdminToggle} className="addProductBtn">
                                <i className="fa fa-plus"></i>
                              </label>
                            </div>
                          </Col> */}
                        </Row>
                      </section>
                      <section className="mt-3">
                        <Row>
                          <Col>
                            <Table borderless responsive className="text-left">
                              <thead className="table_head">
                                <tr>
                                  <th><span className="sort-by" onClick={(e) => {
                                    setSortValue("first_name");
                                    tableSortingId();
                                  }}>Username</span></th>
                                  <th><span className="sort-by" onClick={(e) => {
                                    setSortValue("email");
                                    tableSortingId();
                                  }}>Email</span></th>
                                  <th>Role</th>
                                  <th>Status</th>
                                  {/* <th>Type</th> */}
                                  <th><span className="sort-by" onClick={(e) => {
                                    setSortValue("created_at");
                                    tableSortingId();
                                  }}>Created On</span></th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              <tbody className="bg-white">
                                <Fragment>
                                  {
                                    users && users.length > 0 &&
                                    <React.Fragment>
                                      {
                                        users.map((user) => {
                                          return (
                                            <tr key={user.id} style={{ lineHeight: "40px" }}>
                                              {/* <td>{user.first_name} {user.last_name}</td> */}
                                              <td>
                                                <div style={{ width: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                                  {
                                                    user?.first_name !== null &&
                                                    <span>{user.first_name}</span>
                                                  }{" "}
                                                  {
                                                    user?.last_name !== null &&
                                                    <span>{user.last_name}</span>
                                                  }
                                                  {
                                                    user?.first_name === null && user?.last_name === null && `-`
                                                  }
                                                </div>
                                              </td>
                                              <td><div style={{ width: "230px", textOverflow: "ellipsis", overflow: "hidden" }}>{user.email}</div></td>
                                              <td>
                                                <span>
                                                  {user?.premium === true ? `Premium` : 'Basic'}
                                                </span>
                                              </td>
                                              <td><span className={`${user?.active === true ? `user_status user_active` : `user_status user_inactive`}`} pill>{
                                                `${user?.active === true ? `Active` : `Inactive`}`
                                              }</span>
                                                {/* <span className="user_status user_active">Active</span>
                                                  <span className="user_status user_pending">Pending</span>
                                                  <span className="user_status user_inactive">Inactive</span> */}
                                              </td>
                                              {/* <td>
                                                      <span>
                                                        {user?.user_role_associations.length < 1 ? `Basic` : user.user_role_associations.map((abs) => abs.user_role.name)}
                                                      </span>
                                                    </td> */}
                                              <td>
                                                {new Date(user.created_at).toLocaleDateString()}
                                              </td>
                                              <td className="action_icon">
                                                <Link to={`/user/profile/jellie/${user.id}`}>
                                                  <i className="fa fa-eye mr-2"></i>
                                                </Link>
                                                {
                                                  userInfo?.user_role_associations?.map((role) => role?.role_id).includes(3) &&
                                                  <React.Fragment>
                                                    <i className="fa fa-pen ml-3 mr-2" onClick={() => {
                                                      editToggle()
                                                      setUser("app")
                                                      setUserData(user);
                                                    }}></i>
                                                    <span>
                                                      <i className="fa fa-trash ml-3" onClick={() => {
                                                        // userDelete.bind(this, user.id);
                                                        confirmDeleteModal();
                                                        setDeleteUserId(user.id);
                                                      }}></i>
                                                    </span>
                                                  </React.Fragment>
                                                }
                                              </td>
                                            </tr>
                                          )
                                        })
                                      }
                                    </React.Fragment>
                                  }
                                </Fragment>
                                {
                                  !loading && !loading2 && users && users.length < 1 &&
                                  <tr>
                                    <td colSpan="6" style={{ verticalAlign: "middle" }}>
                                      <h4 className="text-center" style={{ color: "#f5813c" }}>User Not Found..</h4>
                                    </td>
                                  </tr>
                                }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </section>
                    </Container>
                  </React.Fragment>
                )
                }
                {
                  activeTab === '3' && (
                    <React.Fragment>
                      {
                        petLoading ?
                          <h2 className="text-center text-muted mt-5">Loading......</h2> :
                          <React.Fragment>
                            <Container fluid className="mt-3">
                              <section>
                                {/* <Button className="bg-orange orange_btn" onClick={inviteAdminToggle}>INVITE ADMIN</Button> */}
                                <Row>
                                  <Col md="11">
                                    <div className="search__header">
                                      <div className="search__header__text">
                                        <span>
                                          Search
                                        </span>
                                      </div>
                                      <div className="search__header__input">
                                        <Form>
                                          <Input
                                            id="name"
                                            name="name"
                                            placeholder="Name or Pet_id"
                                            type="search"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                          />
                                        </Form>
                                      </div>
                                    </div>
                                  </Col>
                                  {/* <Col md="1" className="mt-3">
                                    <div className="mt-@ plus__buttom">
                                      <Input
                                        type="checkbox"
                                        style={{ display: "none" }}
                                        id="setToggleBtn"
                                      />
                                      <label htmlFor="setToggleBtn" onClick={inviteAdminToggle} className="addProductBtn">
                                        <i className="fa fa-plus"></i>
                                      </label>
                                    </div>
                                  </Col> */}
                                </Row>
                              </section>
                              <section className="mt-3">
                                <Row>
                                  <Col>
                                    <Table borderless responsive className="text-left">
                                      <thead className="table_head">
                                        <tr>
                                          <th><span className="sort-by" onClick={(e) => {
                                            setPetSortValue("id");
                                            tableSortingPet();
                                          }}>Pet ID</span></th>
                                          <th><span className="sort-by" onClick={(e) => {
                                            setPetSortValue("name");
                                            tableSortingPet();
                                          }}>Name</span></th>
                                          <th><span className="sort-by" onClick={(e) => {
                                            setPetSortValue("specie");
                                            tableSortingPet();
                                          }}>Specie</span></th>
                                          <th><span className="sort-by" onClick={(e) => {
                                            setPetSortValue("breed");
                                            tableSortingPet();
                                          }}>Breed</span></th>
                                          <th>Status</th>
                                          <th><span className="sort-by" onClick={(e) => {
                                            setPetSortValue("created_at");
                                            tableSortingPet();
                                          }}>Created On</span></th>
                                          <th style={{ width: "150px" }}>
                                            <span className="ml-3">Action</span>
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody className="bg-white">
                                        {
                                          allPetsData && allPetsData?.length > 0 && allPetsData?.map((data) => {
                                            return (
                                              <React.Fragment>
                                                <tr key={data?.id}>
                                                  <td style={{ verticalAlign: 'middle' }}>{data?.id ? data.id : `-`}</td>
                                                  <td style={{ verticalAlign: 'middle' }}>{data?.name ? data.name : `-`}</td>
                                                  <td style={{ verticalAlign: 'middle' }}>{data?.specie ? data.specie : `-`}</td>
                                                  <td style={{ verticalAlign: 'middle' }}>
                                                    <div style={{ width: "180px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                                      {data?.breed ? data.breed : `-`}
                                                    </div>
                                                  </td>
                                                  <td style={{ verticalAlign: 'middle' }}>
                                                    <span className={`${data?.activated === true ? `user_status user_active` : `user_status user_inactive`}`} pill>{
                                                      `${data?.activated === true ? `Active` : `Inactive`}`
                                                    }</span>
                                                  </td>
                                                  <td style={{ verticalAlign: 'middle' }}>
                                                    {new Date(data?.created_at).toLocaleDateString()}
                                                  </td>
                                                  <td style={{ verticalAlign: 'middle' }}>
                                                    <Button
                                                      tag={Link}
                                                      to={`/pet/profile/${data?.id}`}
                                                      className="action_btn"
                                                    >
                                                      Full Details
                                                    </Button>
                                                  </td>
                                                </tr>
                                              </React.Fragment>
                                            )
                                          })
                                        }
                                        {/* {
                                          !petLoading && !searchPetLoading && allPetsData.length < 1 &&
                                          <React.Fragment>
                                            <tr>
                                              <td colSpan="7" style={{ verticalAlign: "middle" }}>
                                                <h4 className="text-center" style={{ color: "#f5813c" }}>Pet Not Found..</h4>
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        } */}
                                      </tbody>
                                    </Table>
                                  </Col>
                                </Row>
                              </section>
                            </Container>
                          </React.Fragment>
                      }
                    </React.Fragment>
                  )
                }
              </Fragment>
          }
        </React.Fragment>
        <React.Fragment>
          {/* models */}
          <Modal isOpen={inviteAdminModal} toggle={inviteAdminToggle} className="modal-dialog-centered">
            <ModalBody>
              <h5 className="text-orange modal__title">INVITE ADMIN</h5>
              <Form onSubmit={sendMail}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input type="text" required onChange={(e) => setName(e.target.value)} />
                </FormGroup>
                <FormGroup>
                  <Label>Email</Label>
                  <Input type="email" required onChange={(e) => setEmail(e.target.value)} />
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" onChange={e => {
                      setSuperUser(!superUser);
                    }} />{' '}
                    Super User
                  </Label>
                </FormGroup>
                <div className="mt-3 modal_action">
                  <Button disabled={name === "" || email === "" ? true : false} className="bg-btn-orange modal_send_invite" style={{ border: "none", outline: "none" }}>SEND INVITE</Button>{' '}
                  <Button color="secondary" className="modal_cancel" onClick={inviteAdminToggle}>CANCEL</Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
          {/* edit user model */}
          <Modal isOpen={editModal} toggle={editToggle} className="edit_modal modal-dialog-centered">
            <ModalBody className="">
              <h5 className="text-orange">EDIT</h5>
              {
                user === "portal" ?
                  <React.Fragment>
                    <Form>
                      <FormGroup>
                        <Label>Assign Role</Label>
                        <Input type="select" defaultValue={defaultRoll} onChange={(e) => setSelectedId(e.target.value)}>
                          <option value={2}>Admin</option>
                          <option value={3}>Super</option>
                        </Input>
                      </FormGroup>
                      <Label className="mt-2">Active / Deactive Account</Label>
                      <label className="mt-1 mb-4 switch">
                        <input type="checkbox" className="checkbox" id="customSwitch1"
                          defaultChecked={userData?.active ? true : false}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setActive(true);
                            } else if (!e.target.checked) {
                              setActive(false);
                            }
                          }} />
                        <span className="toggle-thumb">
                          <span className="active">Active</span>
                          <svg width="15" height="11" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.87488 6.378L1.34688 3.851L0.504883 4.693L3.87488 8.063L11.0949 0.842L10.2529 0L3.87488 6.378Z" fill="white" />
                          </svg>

                          <svg width="13" height="13" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.00004 8.50004C6.85388 8.50005 6.71504 8.43611 6.62004 8.32504L0.620036 1.32504C0.533835 1.22426 0.491201 1.09336 0.501516 0.961145C0.51183 0.82893 0.574248 0.706228 0.675036 0.620036C0.775816 0.533835 0.906713 0.491201 1.03893 0.501516C1.17114 0.51183 1.29384 0.574248 1.38004 0.675036L7.38004 7.67504C7.5069 7.82336 7.53586 8.03194 7.45421 8.20922C7.37256 8.38649 7.19521 8.50005 7.00004 8.50004Z" fill="white" />
                            <path d="M1.00004 8.50004C0.880864 8.50005 0.765607 8.45749 0.675036 8.38004C0.574248 8.29384 0.51183 8.17114 0.501516 8.03893C0.491201 7.90671 0.533835 7.77582 0.620036 7.67504L6.62004 0.675036C6.70623 0.574248 6.82893 0.51183 6.96114 0.501516C7.09336 0.491201 7.22426 0.533835 7.32504 0.620036C7.42582 0.706228 7.48824 0.82893 7.49856 0.961145C7.50887 1.09336 7.46624 1.22426 7.38004 1.32504L1.38004 8.32504C1.28504 8.43611 1.14619 8.50005 1.00004 8.50004Z" fill="white" />
                          </svg>
                          <span className="inactive">Inactive</span>
                        </span>
                      </label>
                      <div className=" modal_action">
                        <Button className="bg-btn-orange modal_send_invite" style={{ border: "none", outline: "none" }} onClick={() => editRole()}>SAVE CHANGES</Button>{' '}
                        <Button color="secondary" className="modal_cancel" onClick={(e) => {
                          editToggle();
                          setUserData({});
                        }}>CANCEL</Button>
                      </div>
                    </Form>
                  </React.Fragment> :
                  <React.Fragment>
                    <Form>
                      <FormGroup>
                        <Label>Assign Role</Label>
                        <Input type="select" defaultValue={userData?.premium === true ? "Premium" : "Basic"} onChange={(e) => setSelectedBP(e.target.value)}>
                          <option value="Basic">Basic</option>
                          <option value="Premium">Premium</option>
                        </Input>
                      </FormGroup>
                      <Label className="mt-2">Active / Deactive Account</Label>
                      <label className="mt-1 mb-4 switch">
                        <input type="checkbox" className="checkbox" id="customSwitch1"
                          defaultChecked={userData?.active ? true : false}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setActive(true);
                            } else if (!e.target.checked) {
                              setActive(false);
                            }
                          }} />
                        <span className="toggle-thumb">
                          <span className="active">Active</span>
                          <svg width="15" height="11" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.87488 6.378L1.34688 3.851L0.504883 4.693L3.87488 8.063L11.0949 0.842L10.2529 0L3.87488 6.378Z" fill="white" />
                          </svg>

                          <svg width="13" height="13" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.00004 8.50004C6.85388 8.50005 6.71504 8.43611 6.62004 8.32504L0.620036 1.32504C0.533835 1.22426 0.491201 1.09336 0.501516 0.961145C0.51183 0.82893 0.574248 0.706228 0.675036 0.620036C0.775816 0.533835 0.906713 0.491201 1.03893 0.501516C1.17114 0.51183 1.29384 0.574248 1.38004 0.675036L7.38004 7.67504C7.5069 7.82336 7.53586 8.03194 7.45421 8.20922C7.37256 8.38649 7.19521 8.50005 7.00004 8.50004Z" fill="white" />
                            <path d="M1.00004 8.50004C0.880864 8.50005 0.765607 8.45749 0.675036 8.38004C0.574248 8.29384 0.51183 8.17114 0.501516 8.03893C0.491201 7.90671 0.533835 7.77582 0.620036 7.67504L6.62004 0.675036C6.70623 0.574248 6.82893 0.51183 6.96114 0.501516C7.09336 0.491201 7.22426 0.533835 7.32504 0.620036C7.42582 0.706228 7.48824 0.82893 7.49856 0.961145C7.50887 1.09336 7.46624 1.22426 7.38004 1.32504L1.38004 8.32504C1.28504 8.43611 1.14619 8.50005 1.00004 8.50004Z" fill="white" />
                          </svg>
                          <span className="inactive">Inactive</span>
                        </span>
                      </label>
                      <div className=" modal_action">
                        <Button className="bg-btn-orange modal_send_invite" style={{ border: "none", outline: "none" }} onClick={() => editRole()}>SAVE CHANGES</Button>{' '}
                        <Button color="secondary" className="modal_cancel" onClick={(e) => {
                          editToggle();
                          setUserData({});
                        }}>CANCEL</Button>
                      </div>
                    </Form>
                  </React.Fragment>
              }

            </ModalBody>
          </Modal>
          {/* delete model */}
          <React.Fragment>
            <Modal isOpen={editDeleteModal} toggle={confirmDeleteModal} className="modal-dialog-centered delete_model modal-sm">
              <ModalBody>
                <div className="text-center mt-3 text-orange">
                  <h3>Are you sure ?</h3>
                </div>
                <div className="pt-3 pl-2 pr-3" style={{ marginTop: "25px", display: "flex", justifyContent: "space-around" }}>
                  <Button className="bg-btn-orange modal_send_invite" style={{ border: "none", outline: "none" }} onClick={() => {
                    userDelete();
                  }}>DELETE</Button>
                  <Button color="secondary" className="modal_cancel" onClick={() => {
                    confirmDeleteModal();
                    setDeleteUserId();
                  }}>CANCEL</Button>
                </div>
              </ModalBody>
            </Modal>
          </React.Fragment>
          {/* <Modal isOpen={editModal} toggle={editToggle} className="edit_modal modal-dialog-centered">
            <ModalBody>
              <h5 className="text-orange">EDIT</h5>
              <Form>
                <FormGroup>
                  <Label>Assign Role</Label>
                  <Input type="select" className="form-control" defaultValue={selectedId} onChange={(e) => setSelectedId(e.target.value)}>
                    <option value={0}>Roles</option>
                    <option value={2}>Admin</option>
                    <option value={3}>Super</option>
                  </Input>
                </FormGroup>
                <FormGroup className="mb-4">
                  <Label className="mt-2">Active / InActive Account</Label>
                  {/* <h5><Badge color="success" pill className="p-2">Active</Badge></h5> */}
          {/* <label className="mt-1 mb-4 switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch1"
                      defaultChecked={userData?.active ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setActive(true);
                        } else if (!e.target.checked) {
                          setActive(false);
                        }
                      }}
                    />
                    <label
                      className="inactive"
                      htmlFor="customSwitch1"
                    >
                      Active / InActive
                    </label>
                  </label>
                </FormGroup>
                <div className="mt-3">
                  <Button className="bg-btn-orange" style={{ border: "none", outline: "none" }} onClick={() => editRole()}>SAVE CHANGES</Button>{' '}
                  <Button color="secondary" onClick={editToggle}>CANCEL</Button>
                </div>
              </Form>
            </ModalBody>
          </Modal> */}
        </React.Fragment>
      </Container>
    </React.Fragment>
  )
};
export default User;